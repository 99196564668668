import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IReportData, IProvision, ISampling, IProvisionSampledListItem, ISampledDevelopment } from '../types';
import { ProvisionDetail } from '../../../components/ProvisionReport/ProvisionDetail/ProvisionDetail';
import { samplingCompareFunc } from '../ProvisionDetailContainer/ProvisionDetailContainer';
import { groupValueToString } from '../../../components/ProvisionReport/ProvisionListSummary/ProvisionListSummary';
import { IEasyStore } from '../../../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStoreToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
})
const connector = connect(mapStoreToProps);
type PropsFromStore = ConnectedProps<typeof connector>

interface IRouteProps {
  specie: string;
  variety: string;
  group: string;
  groupValue: string;
}

interface IProvisionSummaryContainerProps extends RouteComponentProps<IRouteProps>, PropsFromStore {
  reportData: IReportData;
}

const ProvisionSummaryContainer: React.FC<IProvisionSummaryContainerProps> = (props) => {
  const reportData = props.reportData;
  const group = decodeURIComponent(props.match.params.group);
  const groupValue = decodeURIComponent(props.match.params.groupValue);
  const specie = decodeURIComponent(props.match.params.specie);
  const variety = decodeURIComponent(props.match.params.variety);

  let provisions = reportData.provisions.filter(p => p.specie === specie && p.variety === variety);
  if (groupValue === "**EMPTY**")
    provisions = provisions.filter(p => !p[group as keyof IProvision]);
  else if (groupValue !== "**ALL**")
    provisions = provisions.filter(p => p[group as keyof IProvision] === groupValue);

  const samplings = reportData.sampling.filter(s => provisions.some(p => p.provisionId === s.receiptId));
  const keyForDevelopment = ((s: ISampling) => JSON.stringify({ calibre: s.calibre, quality: s.quality }));
  const development = samplings.sort(samplingCompareFunc).reduce<{ [key: string]: { gridOrder: number, calibre: string, quality: string, weight: number } }>((agg, next) => {
    const key = keyForDevelopment(next);
    const sampled = agg[key] = agg[key] ?? { weight: 0, calibre: next.calibre, quality: next.quality, gridOrder: next.gridOrder };
    sampled.weight = sampled.weight + next.developedWeight;
    return agg;
  }, {});
  const weight = provisions.reduce((agg, next) => agg + next.weight, 0);
  const sampled = samplings.reduce((agg, next) => agg + next.developedWeight, 0);

  return (
    <ProvisionDetail
      theme={props.theme}
      item={{
        [group as keyof IProvisionSampledListItem]: groupValueToString(groupValue),
        name: `${specie} ${variety}`,
        development: Object.keys(development).map<ISampledDevelopment>(d => {
          const dev = development[d];
          return {
            calibre: dev.calibre,
            percent: dev.weight * 100 / sampled,
            quality: dev.quality,
            weight: dev.weight
          }
        }),
        sampled: sampled,
        weight: weight
      }} />);
}

export default connector(withRouter(ProvisionSummaryContainer));
