import React from 'react';
import { FontWeights, Icon, IStyle, ITheme, mergeStyleSets, Separator, Stack } from '@fluentui/react';

const style = mergeStyleSets({
  mainTitle: {
    fontWeight: FontWeights.semibold,
    fontSize: "1.5rem"
  },
  icon: {
    fontWeight: FontWeights.light,
    fontSize: "2rem",
    // borderRight: "1px solid",
    // borderRadius: "10%",
    padding: "1rem"
  } as IStyle
})

interface IMainTitleProps {
  title: string;
  iconName?: string;
  theme: ITheme;
}

const MainTitle: React.FC<IMainTitleProps> = (props) => {
  const theme = props.theme;
  return (
    <div style={{ background: theme.palette.white }}>
      <Stack horizontal verticalAlign='center' horizontalAlign="start" tokens={{ childrenGap: 10 }} >
        {props.iconName && <Stack.Item><Icon className={style.icon} iconName={props.iconName} /></Stack.Item>}
        <Stack.Item><h2 className={style.mainTitle}>{props.title}</h2></Stack.Item>
      </Stack>
      <Separator styles={{
        root: {
          marginLeft: "10px", marginRight: "10px", padding: "0px 0px", "::before": {
            backgroundColor: theme.palette.neutralLight
          }
        }
      }} />
    </div>
  )
}

export default MainTitle;
