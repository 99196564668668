import React from 'react';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { IBreadcrumbItem, Breadcrumb } from '@fluentui/react';
import { pathForProvisionsReportRoot, pathForProvisionsReportBySpecie, provisionReportDetaiListlPath, provisionReportDetailPath, pathForProvisionsReportByVariety, pathForProvisionsSummaryReport } from '../../../shared/route';
import { IProvision } from '../types';

interface IRouteProps {
    partnerCode: string,
    year: string;
    specie?: string;
    variety?: string;
    provision?: string;
    group?: string;
    groupValue?: string;
}

interface IBreadcrumbContainerProps extends RouteComponentProps<IRouteProps> {
    provisionById: () => Map<string, IProvision>
}

const BreadcrumbContainer: React.FC<IBreadcrumbContainerProps> = (props) => {
    const { history, provisionById, location } = props;
    const { partnerCode, year, provision, variety, specie, group, groupValue } = props.match.params;

    const breadcrumbClickHandler = (navigateTo: string) => {
        history.push(navigateTo);
        window.scrollTo(0, 0);
    }

    let outputSpecie = specie;
    let outputVariety = variety;
    if (provision) {
        const provisionObj = provisionById().get(provision)
        outputSpecie = provisionObj?.specie;
        outputVariety = provisionObj?.variety;
    }
    const breadcrumbItems: IBreadcrumbItem[] = [{
        key: "root",
        text: "Specie",
        onClick: () => {
            const navigateTo = pathForProvisionsReportRoot(partnerCode, year)
            breadcrumbClickHandler(navigateTo);
        }
    }];
    if (outputSpecie) {
        breadcrumbItems.push({
            key: "spe_" + outputSpecie,
            text: outputSpecie,
            onClick: () => {
                if(!outputSpecie) throw Error('outputSpecie non impostata')
                const navigateTo = pathForProvisionsReportBySpecie(partnerCode, year, outputSpecie)
                breadcrumbClickHandler(navigateTo);
            }
        })
    }
    if (outputVariety && outputSpecie) {
        breadcrumbItems.push({
            key: "var_" + outputVariety,
            text: outputVariety,
            onClick: () => {
                const isDetailBranch = matchPath(location.pathname, { path: [provisionReportDetaiListlPath, provisionReportDetailPath] });
                if (isDetailBranch) {
                    if(!outputSpecie) throw Error('outputSpecie non impostata')
                    if(!outputVariety) throw Error('outputVariety non impostata')
                    const navigateTo = pathForProvisionsReportByVariety(partnerCode, year, outputSpecie, outputVariety);
                    breadcrumbClickHandler(navigateTo);
                } else {
                    if (!group) throw Error('variabile group non inizializzata');
                    if (!outputSpecie) throw Error('variabile outputSpecie non inizializzata');
                    if(!outputVariety) throw Error('outputVariety non impostata');
                    const navigateTo = pathForProvisionsSummaryReport(partnerCode, year, outputSpecie, outputVariety, group);
                    breadcrumbClickHandler(navigateTo);
                }
            }
        });
    }
    if (provision) {
        breadcrumbItems.push({
            key: "del_" + provision,
            text: `DDT ${provisionById().get(provision)?.transportDocNo}`
        });
    }
    if (groupValue) {
        breadcrumbItems.push({
            key: ["summary", outputSpecie, outputVariety, group, groupValue].join("_"),
            text: `Riepilogo`
        });
    }

    return <Breadcrumb items={breadcrumbItems} maxDisplayedItems={2} />
}

export default withRouter(BreadcrumbContainer);