import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IReportData, IProvisionListItemSummary, IProvision } from '../types';
import { pathForProvisionsSummaryGroupReport, pathForProvisionsSummaryReport } from '../../../shared/route';
import { ProvisionListSummary } from '../../../components/ProvisionReport/ProvisionListSummary/ProvisionListSummary';

interface IRouteProp {
  specie: string;
  variety: string;
  group: string;
}

interface IListSummaryContainerProp extends RouteComponentProps<IRouteProp> {
  reportData: IReportData;
}

const ListSummaryContainer: React.FC<IListSummaryContainerProp> = (props) => {
  const reportData = props.reportData;
  const history = props.history;
  const { group, specie, variety } = props.match.params

  const onChangeSummaryHanlder = (item: IProvisionListItemSummary) => {
    const path = pathForProvisionsSummaryGroupReport(
      reportData.partnerCode,
      reportData.yearCode,
      specie,
      variety,
      group,
      item.groupField);
    history.push(path);
  }
  const onChangeGroupHandler = (group: string) => {
    const path = pathForProvisionsSummaryReport(
      reportData.partnerCode,
      reportData.yearCode,
      specie,
      variety,
      group);
    history.push(path);
  }

  const provisions = reportData.provisions.filter(p => p.specie === specie && p.variety === variety);

  const itemSummaries = provisions.reduce<Map<string, IProvisionListItemSummary>>((aggregate, next) => {
    const all = aggregate.get("**ALL**") ?? { groupField: "**ALL**", sampled: 0, weight: 0 };
    aggregate.set("**ALL**", all);
    all.weight = all.weight + next.weight;
    const nextSampled = reportData.sampling.filter(s => s.receiptId === next.provisionId)
      .reduce((aggregate2, next2) => aggregate2 + next2.developedWeight, 0);
    all.sampled = all.sampled + nextSampled;
    const groupKey = next[group as keyof IProvision] as string ?? "**EMPTY**";
    const groupValue = aggregate.get(groupKey) ?? { groupField: groupKey, sampled: 0, weight: 0 };
    aggregate.set(groupKey, groupValue);
    groupValue.weight = groupValue.weight + next.weight;
    groupValue.sampled = groupValue.sampled + nextSampled;

    return aggregate
  }, new Map<string, IProvisionListItemSummary>());

  return (

    <ProvisionListSummary
      items={Array.from(itemSummaries.values())}
      groupOptions={[
        { key: 'work', text: 'Tp.lavorazione' },
        { key: 'type', text: 'Tp.prodotto' },
        { key: 'differential', text: 'Differenziale' },
      ]}
      group={group}
      onSelected={onChangeSummaryHanlder}
      onChangeSummary={onChangeGroupHandler} />

  );

}

export default withRouter(ListSummaryContainer);
