import { ITheme, mergeStyleSets, Image, ImageFit, Stack, Icon } from '@fluentui/react';
import React from 'react';
//import { desktop } from '../../../shared/mediaQuery';
//import withEasyHttp from '../../../hoc/HttpClientWrapper/HttpClientWrapper';
import { INewsArticle } from '../News.types';


const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    articleWrapper: {
      boxSizing: "border-box",
      width: "100%",
      paddingRight: "1rem",
      cursor: "pointer",
      border: "1px solid " + theme.palette.neutralQuaternary,
      "&:hover": {
        backgroundColor: theme.palette.neutralLight,
      }
    },
    articleImageAltIcon: {
      //background: `radial-gradient(circle,  ${theme.palette.neutralDark} 0%, ${theme.palette.neutralLight} 100%)`,
      backgroundColor: theme.palette.neutralDark,
    },
    articleImage: {
      //border: "1px solid " + theme.palette.neutralLighter,
      //flexShrink: 0,
      width: "100%",
      minHeight: 130,
      height: "100%",
      position: "relative",
      borderRight: "1px solid " + theme.palette.neutralQuaternary,
      // [desktop]: {
      //   width: 100,
      //   minHeight: 100,
      // }
    },
    articleTitle: {
      fontFamily: "Segoe UI Light",
    },
    articleSummary: {
      textAlign: "justify",
      maxHeight: "9.5em",
      overflow: "hidden",
    },
  });
}

interface INewsBoardItemPropFromStore{
  theme: ITheme
}

export interface INewsBoardItemProps extends INewsBoardItemPropFromStore {
  article: INewsArticle;
  onArticleClick?: (id: string) => void;
}

const NewsBoardItem: React.FC<INewsBoardItemProps> = ({ article, onArticleClick, theme }) => {

  const classNames = getClassNames(theme);

  const hasImage = (article.imageFileContent?.length || 0) > 0;

  return (
    <div className={classNames.articleWrapper}>
      <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }} onClick={() => { onArticleClick && onArticleClick(article.id) }} >

        <Stack.Item styles={{ root: { flexGrow: 0, flexShrink: 0, width: "30%" } }}>
          <div className={`${classNames.articleImage} ${hasImage ? "" : classNames.articleImageAltIcon}`}>

            {hasImage
              ? <Image src={article.imageFileContent} height={150} imageFit={ImageFit.cover} styles={{ root: { height: 150, top: "50%", transform: "translate(0,-50%)" } }} />
              : <Icon iconName="News" styles={{ root: { color: theme.palette.white, display: "inline-block", fontSize: 64, position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" } }} />}
          </div>
        </Stack.Item>
        <Stack.Item styles={{ root: { flexGrow: 1 } }}>
          <Stack>
            <h3 className={classNames.articleTitle}>{article.title}</h3>
            <p className={classNames.articleSummary}>{article.summary}</p>
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default NewsBoardItem;


