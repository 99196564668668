import React, { useEffect, useState } from 'react';
import Page from '../../hoc/Page/Page';
import withEasyClient from '../../hoc/HttpClientWrapper/HttpClientWrapper'
import { IWithInteractiveLoginProps } from '../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper'
import { CommandBar, omit, Panel, ShimmeredDetailsList } from '@fluentui/react';
import { columns } from './Users.columns';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import { IPaginationInfo, toODataParam } from '../../shared/Paging/paginationInfo';
import EasyForm from '../../components/Form/Form';
import { updateObject } from '../../shared/utility';
import { fixExitWhenClickOnDialog } from '../../shared/Panel/panel';
import { EditUser, IUser, IUsersResponse, UsersFormDefinition, UsersProps } from '../../types/User';
import { createFormDefinition } from './Users.form';
import LoadingModal from '../../components/UI/LoadingModal/LoadingModal';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../store/store';

const mapStateToProps = (store: IEasyStore) => {
  return {
    filter: store.context.globalSearchExpression,
    theme: store.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>


const Users: React.FC<UsersProps & IWithInteractiveLoginProps & PropsFromStore> = (props) => {

  const easyClient = props.easyClient;

  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState({});
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [usersViewState, setUsersViewState] = useState<IPaginationInfo>({
    currPage: 1,
    pageSize: 100,
    orderby: "name"
  });
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [formDefinition, setFormDefinition] = useState(createFormDefinition({}))

  const [currentEditUser, setCurrentEditUser] = useState<EditUser>({});
  const [modalLoading, setModalLoading] = useState(false);
  const filter = props.filter;
  useEffect(() => {
    if (!refresh) return;
    let url = "/api/Users?" + toODataParam(usersViewState, filter);
    setLoading(true);
    easyClient.get<IUsersResponse>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setTotalUsersCount(response.data['@odata.count']);
        setUsers(response.data.value);
      }).finally(() => setLoading(false));
  }, [easyClient, refresh, usersViewState, filter])

  const addNewUserHandler = () => {
    const newUser = {};
    setCurrentEditUser({ user: newUser, operation: "new" });
    setFormDefinition(createFormDefinition(newUser))
    setFormIsOpen(true);
  }

  const refreshViewHandler = () => {
    setRefresh({});
  }

  const changeUserPageHandler = (page: number) => {
    setUsersViewState(updateObject(usersViewState, { currPage: page }));
  }

  const closeFormHandler = () => {
    setFormIsOpen(false);
  }

  const formChangedHandler = (updatedForm: UsersFormDefinition, isValid: boolean) => {
    setFormDefinition(updatedForm);
    setSubmitEnabled(isValid);
  }

  const formSubmitHandler = (updatedForm: UsersFormDefinition, isValid: boolean) => {
    if (!currentEditUser) return;

    const newItem = updateObject(currentEditUser.user, {
      email: formDefinition.email?.value,
      name: formDefinition.name?.value,
      username: formDefinition.username?.value,
      roles: formDefinition.roles?.value,
      notificationsEnabled: formDefinition.notificationsEnabled?.value
    });
    let operation = easyClient.post;
    let apiUrl = "/api/Users";
    let headers: any = { "X-version": "1.0" };
    if (currentEditUser.operation === "edit") {
      operation = easyClient.patch;
      apiUrl = apiUrl + "('" + encodeURIComponent(newItem!.id!) + "')"
      headers = { "X-version": "1.0" };
    }
    setLoading(true);
    setModalLoading(true);
    operation<IUser>(apiUrl, omit(newItem!, ["lastChangeLog", "creationLog"]), { headers: headers })
      .then(response => {
        const newUser = response.data;
        const index = users.findIndex(user => user.id === newUser.id);
        if (index >= 0) {
          const newItems = [...users];
          newItems.splice(index, 1, newUser)
          setUsers(newItems);
        } else {
          setUsers([newUser, ...users]);
        }
        setFormIsOpen(false);
      }).finally(() => {
        setLoading(false);
        setModalLoading(false);
      });
  }

  const editUserHandler = (user: IUser) => {
    setCurrentEditUser({ user: user, operation: "edit" });
    setFormDefinition(createFormDefinition(user))
    setFormIsOpen(true);
  }

  return (
    <Page iconName="UserOptional" pageWidth="960px" title="Gestione utenti" theme={props.theme}>
      <CommandBar items={[{
        key: 'add',
        text: 'Nuovo',
        iconProps: { iconName: 'Add' },
        onClick: addNewUserHandler,
      }, {
        key: 'refresh',
        text: 'Aggiorna',
        iconProps: { iconName: 'Sync' },
        onClick: refreshViewHandler
      }]
      } />
      <ShimmeredDetailsList
        items={users}
        columns={columns}
        enableShimmer={loading}
        onItemInvoked={editUserHandler} />
      {totalUsersCount > 0 && (
        <Pagination
          theme={props.theme}
          page={usersViewState.currPage}
          totalPages={Math.ceil(totalUsersCount / usersViewState.pageSize)}
          handlePagination={changeUserPageHandler}
        />)
      }
      <Panel
        headerText={currentEditUser.operation === "new" ? "Nuovo utente" : "Modifica utente"}
        isOpen={formIsOpen}
        onDismiss={closeFormHandler}
        onOuterClick={fixExitWhenClickOnDialog}
      >
        <EasyForm
          theme={props.theme}
          formDefinition={formDefinition}
          onChange={formChangedHandler}
          onSubmit={formSubmitHandler}
          submitEnabled={submitEnabled}
        />
        {modalLoading && <LoadingModal show message="operazione in corso" />}
      </Panel>
    </Page>)
}

export default connector(withEasyClient(Users));
