import React, { useEffect, useState } from 'react';
import { ITag, Panel, ShimmeredDetailsList, CommandBar } from '@fluentui/react';
import EasyForm from '../../components/Form/Form';
import { columns, renderColumnHandler } from './DocumentGroup.columns';
import { createFormDefinition } from './DocumentGroup.form';
import { IDocumentGroup } from './DocumentGroup.types';
import withEasyClient from '../../hoc/HttpClientWrapper/HttpClientWrapper';
import { IODataList } from '../../models/odata';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import { updateObject } from '../../shared/utility';
import { IUser } from '../UserPicker/UserPicker';
import LoadingModal from '../../components/UI/LoadingModal/LoadingModal';
import Page from '../../hoc/Page/Page';
import { IPaginationInfo, toODataParam } from '../../shared/Paging/paginationInfo';
import { fixExitWhenClickOnDialog } from '../../shared/Panel/panel';
import { IWithInteractiveLoginProps } from '../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper';
import { IArea } from '../../types/Area';
import {FormDefinitions} from '../../types/Form'

const mapStateToProps = (store: IEasyStore) => {
  return {
    search: store.context.globalSearchExpression,
    theme: store.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

const DocumentGroup: React.FC<IWithInteractiveLoginProps & PropsFromStore> = (props) => {

  const [items, setItems] = useState<IDocumentGroup[]>([]);
  const [detailOpened, setDetailOpened] = useState(false);
  const [formDefinition, setFormDefinition] = useState<FormDefinitions>({});
  const [editItem, setEditItem] = useState<IDocumentGroup | null>(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [viewState, setViewState] = useState<IPaginationInfo>({
    currPage: 1,
    pageSize: 100,
    orderby: "id"
  });
  const [viewTotalCount, setViewTotalCount] = useState<number>(0);
  const [formOperation, setFormOperation] = useState("");
  const [loading, setLoading] = useState(false);
  const [refreshView, setRefreshView] = useState({});
  const [groupListLoading, setGroupListLoading] = useState(false);

  const easyClient = props.easyClient;
  const search = props.search;

  useEffect(() => {
    const apiUrl = "/api/Groups?" + toODataParam(viewState, search);
    setGroupListLoading(true);
    easyClient.get<IODataList<IDocumentGroup>>(apiUrl, { headers: { "X-version": "1.0" } })
      .then(response => {
        setViewTotalCount(response.data["@odata.count"] ?? 0);
        setItems(response.data.value)
      }).finally(() => {
        setGroupListLoading(false);
      })
  }, [viewState, search, easyClient, refreshView])

  const selectItemHandler = (item?: IDocumentGroup, index?: number | undefined, ev?: Event | undefined): void => {
    if (!item) {
      return
    }
    setFormDefinition(createFormDefinition(item, easyClient));
    setDetailOpened(true);
    setEditItem(item);
    setFormOperation("edit");
  }
  const closeDetailHandler = () => {
    setDetailOpened(false);
  }

  const formChangedHandler = (updatedForm: FormDefinitions, isValid: boolean) => {
    setFormDefinition(updatedForm);
    setSubmitEnabled(isValid);
  }

  const formSubmitHandler = (form: FormDefinitions, isValid: boolean): void => {
    if (editItem) {
      const newItem = updateObject(editItem, {
        id: formDefinition.id.value as string,
        description: formDefinition.description.value as string,
        descriptionShort: formDefinition.descriptionShort.value as string,
        userIds: (formDefinition.userIds.value as IUser[]).map(p => p.id),
        publishableDocTypeIds: (formDefinition.publishableDocTypeIds.value as ITag[]).map(tag => tag.key as string),
        receivableDocTypeIds: (formDefinition.receivableDocTypeIds.value as ITag[]).map(tag => tag.key as string),
        areaId: (formDefinition.area.value as IArea)?.id as string ?? null,
        areaName: (formDefinition.area.value as IArea)?.name ?? null
      });
      let operation = easyClient.post;
      let apiUrl = "/api/Groups";
      let headers: any = { "X-version": "1.0" }
      if (formOperation === "edit") {
        operation = easyClient.patch;
        apiUrl = apiUrl + "('" + encodeURIComponent(newItem.id!) + "')"
        headers = { "X-version": "1.0" };
      }
      setLoading(true);
      operation<IDocumentGroup>(apiUrl, newItem, { headers: headers })
        .then(response => {
          const newGroup = response.data;
          const index = items.findIndex(group => group.id === newGroup.id);
          if (index >= 0) {
            const newItems = [...items];
            newItems.splice(index, 1, newGroup)
            setItems(newItems);
          } else {
            setItems([newGroup, ...items]);
          }
          setDetailOpened(false);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  const viewPageHandler = (page: number) => {
    setViewState(prev => updateObject(prev, { currPage: page }))
  }

  const addNewGroupHandler = () => {
    const newGroup = {};
    setEditItem(newGroup);
    setFormOperation("new");
    setFormDefinition(createFormDefinition(newGroup, easyClient))
    setDetailOpened(true)
  }

  const refreshViewHandler = () => {
    setRefreshView({});
    setDetailOpened(false);
    setEditItem(null);
  }

  return (
    <Page title="Documenti - Gruppi utente" iconName="group" pageWidth="960px" theme={props.theme}>
      {loading && <LoadingModal show />}
      <CommandBar items={[{
        key: 'add',
        text: 'Nuovo',
        iconProps: { iconName: 'Add' },
        onClick: addNewGroupHandler,
      }, {
        key: 'refresh',
        text: 'Aggiorna',
        iconProps: { iconName: 'Sync' },
        onClick: refreshViewHandler
      }]
      } />
      <ShimmeredDetailsList
        items={items}
        onItemInvoked={selectItemHandler}
        columns={columns}
        onRenderItemColumn={renderColumnHandler}
        enableShimmer={groupListLoading}
      />
      {viewTotalCount > 0 && (
        <Pagination
          theme={props.theme}
          page={viewState.currPage}
          totalPages={Math.ceil(viewTotalCount / viewState.pageSize)}
          handlePagination={viewPageHandler}
        />)
      }
      <Panel
        headerText="Gruppo"
        isOpen={detailOpened}
        onDismiss={closeDetailHandler}
        onOuterClick={fixExitWhenClickOnDialog}
      >
        <EasyForm
          theme={props.theme}
          formDefinition={formDefinition}
          onChange={formChangedHandler}
          onSubmit={formSubmitHandler}
          submitEnabled={submitEnabled}
        />
      </Panel>
    </Page>)
}

export default connector(withEasyClient(DocumentGroup));
