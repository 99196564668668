import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../store/store';
import { changeThemeAction } from '../../store/actions/context';
import { ThemeName } from '../../store/types/context';
import { Icon, IStackTokens, IStyleFunctionOrObject, IToggleStyleProps, IToggleStyles, Stack, Toggle } from '@fluentui/react';

const mapStateToProp = (state: IEasyStore) => {
  return {
    theme: state.context.theme,
    fullTheme: state.context.fullTheme
  }
}

const mapDispatchToProps = {
  changeTheme: (dark: boolean) => {
    const themeName: ThemeName = dark ? "themeDark" : "themeLight";
    return changeThemeAction(themeName);
  }
}

const connector = connect(mapStateToProp, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const stackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};
const iconToken: IStackTokens = {
  padding: "1px"
}

const toggleStyles: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
  root: {
    margin: "0"
  }
}

const ThemeContainer: React.FC<PropsFromRedux> = (props) => {

  const themeChangeHandler = (_: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      props.changeTheme(checked);
    }
  }

  return (
    <div>
      <span style={{ color: props.fullTheme.palette.themePrimary }}>Tema</span>
      <Stack horizontal tokens={stackTokens}>
        <Stack.Item tokens={iconToken}><Icon iconName="Sunny" /></Stack.Item>
        <Stack.Item><Toggle styles={toggleStyles}
          offText="" onText=""
          checked={props.theme === "themeDark"}
          onChange={themeChangeHandler} /></Stack.Item>
        <Stack.Item tokens={iconToken}><Icon iconName="ClearNight" /></Stack.Item>
      </Stack>
    </div>
  )
}

export default connector(ThemeContainer);
