import { IPartialTheme } from '@fluentui/react'

const themes: Record<string, IPartialTheme> = {
  "Default": {
    palette: {
      themePrimary: '#0078d4',
      themeLighterAlt: '#eff6fc',
      themeLighter: '#deecf9',
      themeLight: '#c7e0f4',
      themeTertiary: '#71afe5',
      themeSecondary: '#71afe5',
      themeDarkAlt: '#106ebe',
      themeDark: '#005a9e',
      themeDarker: '#004578',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    }
  },
  "Red": {
    palette: {
      themePrimary: '#d40000',
      themeLighterAlt: '#fdf3f3',
      themeLighter: '#f8d0d0',
      themeLight: '#f2a9a9',
      themeTertiary: '#e55c5c',
      themeSecondary: '#40787C',
      themeDarkAlt: '#be0000',
      themeDark: '#a10000',
      themeDarker: '#770000',
      neutralLighterAlt: '#f5f8df',
      neutralLighter: '#f1f4dc',
      neutralLight: '#e7ead3',
      neutralQuaternaryAlt: '#d7dac4',
      neutralQuaternary: '#ced0bb',
      neutralTertiaryAlt: '#c6c8b4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#fcffe6',
    }
  },
  "Blue": {
    palette: {
      themePrimary: "#203c61",
      themeLighterAlt: "#f2f5f9",
      themeLighter: "#cdd8e6",
      themeLight: "#a6b8d0",
      themeTertiary: "#607ca0",
      themeSecondary: "#d69e11",
      themeDarkAlt: "#1d3657",
      themeDark: "#182e4a",
      themeDarker: "#122236",
      neutralLighterAlt: "#f8f8f8",
      neutralLighter: "#f4f4f4",
      neutralLight: "#eaeaea",
      neutralQuaternaryAlt: "#dadada",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c8c8",
      neutralTertiary: "#d1d1d1",
      neutralSecondary: "#a3a3a3",
      neutralPrimaryAlt: "#787878",
      neutralPrimary: "#666666",
      neutralDark: "#4e4e4e",
      black: "#393939",
      white: "#fff",
    }
  },
  "Green": {
    palette: {
      themePrimary: '#367d31',
      themeLighterAlt: '#f4faf4',
      themeLighter: '#d5ead3',
      themeLight: '#b3d8b0',
      themeTertiary: '#75b170',
      themeSecondary: '#CA5010',
      themeDarkAlt: '#30702c',
      themeDark: '#295f25',
      themeDarker: '#1e461b',
      neutralLighterAlt: '#f6f6f6',
      neutralLighter: '#f2f2f2',
      neutralLight: '#e8e8e8',
      neutralQuaternaryAlt: '#d8d8d8',
      neutralQuaternary: '#cecece',
      neutralTertiaryAlt: '#c6c6c6',
      neutralTertiary: '#d0cfce',
      neutralSecondary: '#a09f9e',
      neutralPrimaryAlt: '#747372',
      neutralPrimary: '#61605f',
      neutralDark: '#4a4948',
      black: '#363635',
      white: '#fcfcfc'
    }
  },
  "DarkOrange": {
    palette: {
      themePrimary: '#f58f0a',
      themeLighterAlt: '#fffaf5',
      themeLighter: '#fdedd6',
      themeLight: '#fcdcb3',
      themeTertiary: '#f9bb6a',
      themeSecondary: '#C4C4C4',
      themeDarkAlt: '#dc8109',
      themeDark: '#ba6d07',
      themeDarker: '#895005',
      neutralLighterAlt: '#464646',
      neutralLighter: '#4d4d4d',
      neutralLight: '#595959',
      neutralQuaternaryAlt: '#616161',
      neutralQuaternary: '#676767',
      neutralTertiaryAlt: '#818181',
      neutralTertiary: '#efeef5',
      neutralSecondary: '#f2f1f6',
      neutralPrimaryAlt: '#f4f4f8',
      neutralPrimary: '#e7e6f0',
      neutralDark: '#f9f9fb',
      black: '#fcfcfd',
      white: '#3d3d3d',
      red: '#e25f69',
      green: '#0dd00d'
    },
    semanticColors: {
      errorText: '#ff8389',
    }
  },
  "DarkYellow": {
    palette: {
      themePrimary: '#fce300',
      themeLighterAlt: '#fffef5',
      themeLighter: '#fffbd6',
      themeLight: '#fef7b2',
      themeTertiary: '#fdee65',
      themeSecondary: '#9b9aa1',
      themeDarkAlt: '#e3cc00',
      themeDark: '#c0ad00',
      themeDarker: '#8d7f00',
      neutralLighterAlt: '#3c3c3c',
      neutralLighter: '#3b3b3b',
      neutralLight: '#383838',
      neutralQuaternaryAlt: '#343434',
      neutralQuaternary: '#323232',
      neutralTertiaryAlt: '#303030',
      neutralTertiary: '#efeef5',
      neutralSecondary: '#f1f1f6',
      neutralPrimaryAlt: '#f4f4f8',
      neutralPrimary: '#e7e6f0',
      neutralDark: '#f9f9fb',
      black: '#fcfcfd',
      white: '#3d3d3d',
      red: '#e25f69',
      green: '#0dd00d'
    },
    semanticColors: {
      errorText: '#ff8389',
    }
  },
  "DarkAcid": {
    palette: {
      themePrimary: '#d8ff29',
      themeLighterAlt: '#fdfff6',
      themeLighter: '#f9ffdd',
      themeLight: '#f3ffbf',
      themeTertiary: '#e7ff7e',
      themeSecondary: '#dcff43',
      themeDarkAlt: '#c2e625',
      themeDark: '#a4c21f',
      themeDarker: '#798f17',
      neutralLighterAlt: '#525252',
      neutralLighter: '#595959',
      neutralLight: '#646464',
      neutralQuaternaryAlt: '#6b6b6b',
      neutralQuaternary: '#717171',
      neutralTertiaryAlt: '#898989',
      neutralTertiary: '#f3f3f3',
      neutralSecondary: '#f5f5f5',
      neutralPrimaryAlt: '#f7f7f7',
      neutralPrimary: '#ededed',
      neutralDark: '#fbfbfb',
      black: '#fdfdfd',
      white: '#4b4b4b',
      red: '#e25f69',
      green: '#0dd00d'
    },
    semanticColors: {
      errorText: '#ff8389',
    }
  }
}

export const easyThemes = {
  light: themes["Red"],
  dark: themes["DarkAcid"]
};
