import { IHttpClient } from "../../shared/easyAxios"
import { FormDefinitions } from "../../types/Form"
import { IDocumentTypeTag } from "../DocumentTypePicker/DocumentTypePicker"
import { IDocumentGroup } from "./DocumentGroup.types"

export const createFormDefinition = (docGroup: IDocumentGroup, easyClient: IHttpClient): FormDefinitions => {
  return {
    "id": {
      elementType: "input",
      elementConfig: {
        label: 'Codice',
        readOnly: !!docGroup.id
      },
      value: docGroup.id,
      validation: {
        required: true
      }
    },
    "description": {
      elementType: "input",
      elementConfig: {
        label: 'Descrizione'
      },
      value: docGroup.description ?? "",
      validation: {
        required: true
      }
    },
    "descriptionShort": {
      elementType: "input",
      elementConfig: {
        label: "Descr.breve"
      },
      value: docGroup.descriptionShort ?? "",
      validation: {
        required: true
      }
    },
    "userIds": {
      elementType: "userPicker",
      elementConfig: {
        label: "Utenti",
        easyClient: easyClient
      },
      validation: {
        required: true
      },
      value: docGroup?.userIds?.map(id => ({ id: id, email: "", name: "" })) ?? []
    },
    "publishableDocTypeIds": {
      elementType: "documentTypePicker",
      elementConfig: {
        easyClient: easyClient,
        label: "Doc.trasmissione"
      },
      validation: {
        required: false
      },
      value: docGroup?.publishableDocTypeIds?.map<IDocumentTypeTag>(id => ({ key: id, name: id, areaName: "", description: "" })) ?? []
    },
    "receivableDocTypeIds": {
      elementType: "documentTypePicker",
      elementConfig: {
        easyClient: easyClient,
        label: "Doc.ricezione"
      },
      validation: {
        required: false
      },
      value: docGroup?.receivableDocTypeIds?.map(id => ({ key: id, name: id })) ?? []
    },
    "area": {
      elementType: "areaPicker",
      elementConfig: {
        easyClient: easyClient,
        label: "Area"
      },
      validation: {
        required: false
      },
      value: docGroup.areaId ? { id: docGroup.areaId!, name: docGroup.areaName! } : undefined
    }
  }
}
