import { mergeStyleSets, IPersonaStyles, ITheme } from '@fluentui/react'

const personaWithMenuStyles = (theme: ITheme) => {

  return {
    persona: {

    } as Partial<IPersonaStyles>,
    customs: {
      initials: mergeStyleSets({
        personaBackgroundColor: theme.palette.themePrimary
      }),
    }
  }

}

export default personaWithMenuStyles
