import React from 'react';
import { ITheme, Panel } from '@fluentui/react';

import EasyForm from '../../Form/Form';
import { IAdvanceFilterFormDefinition, IAdvanceFilterFull } from '../../../types/Document';

interface IAdvanceFilterPanelProps {
  isOpen: boolean;
  panelClose: () => void;
  filters: IAdvanceFilterFull;
  filterChange: (filters: IAdvanceFilterFull) => void;
  filterSubmit: (filters: IAdvanceFilterFull) => void;
  theme: ITheme;
}

export const createFormDefinition = (state: IAdvanceFilterFull): IAdvanceFilterFormDefinition => {
  return {
    "id": {
      elementType: "input",
      elementConfig: {
        label: 'ID'
      },
      value: state.id
    },
    "fromRefDate": {
      elementType: "date",
      elementConfig: {
        label: 'Da data riferimento',
      },
      value: state.fromRefDate,
      validation: {
        required: false
      }
    },
    "toRefDate": {
      elementType: "date",
      elementConfig: {
        label: 'Fino data riferimento'
      },
      value: state.toRefDate,
      validation: {
        required: false
      }
    },
    "fromDatePublish": {
      elementType: "date",
      elementConfig: {
        label: "Da data pubblicazione"
      },
      value: state.fromDatePublish,
      validation: {
        required: false
      }
    },
    "toDatePublish": {
      elementType: "date",
      elementConfig: {
        label: "Fino a data pubblicazione"
      },
      value: state.toDatePublish,
      validation: {
        required: false
      }
    },
    "fromDateAcquired": {
      elementType: "date",
      elementConfig: {
        label: "Da data acquisizione"
      },
      value: state.fromDateAcquired,
      validation: {
        required: false
      }
    },
    "toDateAcquired": {
      elementType: "date",
      elementConfig: {
        label: "Fino a data acquisizione"
      },
      value: state.toDateAcquired,
      validation: {
        required: false
      }
    },
  }
}


const AdvanceFilterPanel: React.FC<IAdvanceFilterPanelProps> = (props) => {
  const formDef = createFormDefinition(props.filters);

  const formChangHandler = (updatedForm: IAdvanceFilterFormDefinition, isValid: boolean) => {
    const filters = convertToFilters(updatedForm);
    props.filterChange(filters)
  }

  const formSubmitHandler = (updatedForm: IAdvanceFilterFormDefinition, isValid: boolean) => {
    if (isValid) {
      const filters = convertToFilters(updatedForm);
      props.filterSubmit(filters);
    }
  }

  return (
    <Panel
      headerText="Filtri avanzati"
      isOpen={props.isOpen}
      onDismiss={props.panelClose}
      closeButtonAriaLabel="Close"
    >
      <EasyForm
        formDefinition={formDef}
        onChange={formChangHandler}
        onSubmit={formSubmitHandler}
        theme={props.theme}
        submitEnabled />
    </Panel>)
}

export default AdvanceFilterPanel;

function convertToFilters(updatedForm: IAdvanceFilterFormDefinition): IAdvanceFilterFull {
  return {
    fromDateAcquired: updatedForm.fromDateAcquired.value,
    fromDatePublish: updatedForm.fromDatePublish.value,
    fromRefDate: updatedForm.fromRefDate.value,
    id: updatedForm.id.value,
    toRefDate: updatedForm.toRefDate.value,
    toDateAcquired: updatedForm.toDateAcquired.value,
    toDatePublish: updatedForm.toDatePublish.value
  }
}

