import { ITheme } from 'office-ui-fabric-react';
import React from 'react';
import styleFactory from './Pagination.style';

interface IPage {
  isEllipses: boolean,
  value: number,
  disabled: boolean
}

export interface IPaginationProps {
  page: number;
  totalPages: number;
  maxRange?: number;
  handlePagination: (page: number) => void;
  totalItemsCount?: number;
  theme: ITheme;
}
export const PaginationComponent: React.FC<IPaginationProps> = ({
  page,
  totalPages,
  handlePagination,
  maxRange,
  totalItemsCount,
  theme
}) => {
  const style = styleFactory(theme);
  let paginationRange = maxRange || 7
  /**
   * Given the position in the sequence of pagination links [i],
   * figure out what page number corresponds to that position.
   */
  const calculatePageNumber = (i: number) => {
    let halfWay = Math.ceil(paginationRange / 2);
    if (i === paginationRange) {
      return totalPages;
    } else if (i === 1) {
      return i;
    } else if (paginationRange < totalPages) {
      if (totalPages - halfWay < page) {
        return totalPages - paginationRange + i;
      } else if (halfWay < page) {
        return page - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }

  /**
  * Returns an array of Page objects to use in the pagination controls.
  */
  const createPageArray: () => IPage[] = () => {
    // paginationRange could be a string if passed from attribute, so cast to number.
    paginationRange = +paginationRange;
    let pages: IPage[] = [];
    const halfWay = Math.ceil(paginationRange / 2);

    const isStart = page <= halfWay;
    const isEnd = totalPages - halfWay < page;
    const isMiddle = !isStart && !isEnd;

    const ellipsesNeeded = paginationRange < totalPages;
    let i = 1;

    while (i <= totalPages && i <= paginationRange) {

      const pageNumber = calculatePageNumber(i);
      const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
      const closingEllipsesNeeded = (i === paginationRange - 1 && (isMiddle || isStart));
      const isEllipses = ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded);

      pages.push({
        isEllipses: isEllipses,
        value: pageNumber,
        disabled: isEllipses || pageNumber === page
      });
      i++;
    }

    return pages;
  }

  return (
    <div className={style.pagination}>
      <div className={style.elementCounter}><span>{(totalItemsCount !== undefined && totalItemsCount >= 0) ? `Totale elementi: ${totalItemsCount}` : `${'\u00A0'}`}</span></div>
      {totalPages > 1 && (<div className={style.paginationWrapper}>
        <button
          key="prevPage"
          disabled={page === 1}
          onClick={() => handlePagination(page - 1)}
          type="button"
          className={[style.pageItem, style.sides].join(' ')}
        >
          &lt;
          </button>
        {
          createPageArray().map((p, index) => {
            return p.isEllipses
              ? (<div key={"ellipses" + index} className={style.separator}>...</div>)
              : (<button
                key={"page" + p.value}
                onClick={() => handlePagination(p.value)}
                disabled={p.disabled}
                type="button"
                className={[style.pageItem, p.value === page && [style.active]].join(' ')}>
                {p.value}
              </button>);
          })
        }
        <button
          key="lastPage"
          onClick={() => handlePagination(page + 1)}
          disabled={page >= totalPages}
          type="button"
          className={[style.pageItem, style.sides].join(' ')}
        >
          &gt;
          </button>
      </div>)}
    </div>
  );
};
export const Pagination = PaginationComponent;
