import React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';


export interface INewsArchiveCommandBarProp {
  newItemClicked?: () => void,
  editItemClicked?: () => void,
  deleteItemClicked?: () => void,
  refreshClicked?: () => void,
  showDelete?: boolean,
  showPublish?: boolean,
  showRefresh?: boolean,
  showEdit?: boolean,
};

const NewsArchiveCommandBar: React.FC<INewsArchiveCommandBarProp> = (props) => {


  const _items: ICommandBarItemProps[] = [];

  if (props.showRefresh) {
    _items.push(
      {
        key: 'refreshItems',
        text: 'Aggiorna',
        iconProps: { iconName: "Sync" },
        onClick: props.refreshClicked,
      });
  }

  if (props.showPublish) {
    _items.push(
      {
        key: 'newItem',
        text: 'Pubblica',
        iconProps: { iconName: 'Add' },
        onClick: props.newItemClicked
      });
  }

  if (props.showEdit) {
    _items.push(
      {
        key: 'editItem',
        text: 'Modifica',
        iconProps: { iconName: 'Edit' },
        onClick: props.editItemClicked
      });
  }

  if (props.showDelete) {
    _items.push(
      {
        key: 'deleteItem',
        text: 'Elimina',
        iconProps: { iconName: 'Delete' },
        //disabled: props.deleteDisabled,
        onClick: props.deleteItemClicked,
      });
  }


  return (
    <CommandBar
      items={_items}
    />
  );

}

export default NewsArchiveCommandBar;
