import React from 'react';
import { Link, Panel, PanelType, Stack } from '@fluentui/react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { INavRoute } from '../../../store/types/context';

const navigationMobile: React.FC<{ isOpen: boolean, close: () => void, menu: INavRoute[] } & RouteComponentProps> = (props) => {
  const menu = props.menu.map(m => {
    return (
      <Link key={m.key} onClick={() => {
        props.history.push(m.url)
        props.close();
      }}>{m.text}</Link>
    )
  })

  return (
    <Panel
      headerText="Menu"
      isOpen={props.isOpen}
      onDismiss={props.close}
      closeButtonAriaLabel="Close"
      type={PanelType.customNear}>
      <nav>
        <Stack styles={{ root: { fontSize: 16 } }} tokens={{ padding: "1rem", childrenGap: "0.6rem" }}>
          {menu}
        </Stack>
      </nav>
    </Panel>
  )
}

export default withRouter(navigationMobile);
