import { IProcessedStyleSet, IStyle, ITheme, mergeStyleSets } from '@fluentui/react';
import { desktop } from '../../shared/mediaQuery';

interface ICarouselStule {
  carousel: IStyle;
  items: IStyle;
  item1: IStyle;
  item2: IStyle;
  item3: IStyle;
}

const styleFactory = (theme: ITheme): IProcessedStyleSet<ICarouselStule> => {

  return mergeStyleSets<ICarouselStule>({
    carousel: {
      //display:"inline-block",
      display: "block",
      boxSizing: "border-box",
      padding: "0 0 0.2rem 0",
      margin: "0.3rem 0",
      // border:"1px solid black",
      //width: "100%",
      selectors: {
        ">div": {
          width: "100%",
          height: "20rem",
        },
        [desktop]: {
          margin: "0.5rem 0rem",
          // width: "25rem",
        }
      }
    },
    items: {
      width: "80%",
      maxWidth: "65rem",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      borderRadius: "10px",
      border: "1px solid " + theme.semanticColors.bodyText,
      backgroundSize: "cover",
      backgroundPosition: "center",
      //minHeight: 400,
    },
    item1: {
      backgroundImage: `url('${process.env.PUBLIC_URL + "/carosello_05.jpg"}')`,
    },
    item2: {
      backgroundImage: `url('${process.env.PUBLIC_URL + "/carosello_02.jpg"}')`,
    },
    item3: {
      backgroundImage: `url('${process.env.PUBLIC_URL + "/carosello_03.jpg"}')`,
    },
  });

}

export default styleFactory;
