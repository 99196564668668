import React, { useEffect } from 'react';

import easyClient from '../../shared/easyAxios';
import { setMsalApp } from '../../shared/azureLogin';
import { getLoginConfig } from '../../shared/authConfig';

import { connect, ConnectedProps } from 'react-redux';

import SplashScreen from '../../components/SplashScreen/SplashScreen';
import { ITheme, loadTheme, ProgressIndicator } from '@fluentui/react';
import { IEasyStore } from '../../store/store';
import { appSetConfig } from '../../store/actions/config';
import { setFullThemeAction } from '../../store/actions/context';

const mapStateToProps = (state: IEasyStore) => {
  return {
    easyConfig: state.config,
    themeToLoad: state.config[state.context.theme],
    theme: state.context.fullTheme
  }
}

const mapDispatchToProps = {
  onTryOverrideConfig: appSetConfig,
  setFullTheme: (theme: ITheme) => setFullThemeAction(theme)
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

const Init: React.FC<PropsFromRedux> = (props) => {

  const {
    onTryOverrideConfig,
    easyConfig,
    themeToLoad,
    setFullTheme,
    theme } = props;

  useEffect(() => {
    const themeLoaded = loadTheme(themeToLoad);
    setFullTheme(themeLoaded)
  }, [themeToLoad, setFullTheme])

  useEffect(() => {
    onTryOverrideConfig();
  }, [onTryOverrideConfig]);

  let result = (
    <SplashScreen theme={theme}>
      <ProgressIndicator label="Attendere" />
    </SplashScreen>);

const title = (easyConfig.companyData.shortName) ? "Easy Collaboration - " + easyConfig.companyData.shortName : "ec - " + easyConfig.companyData.name;

  if (!easyConfig.initializing) {
    document.title = title;
    easyClient.setBaseUrl(easyConfig.easyApiUrl);
    easyClient.setAppId(easyConfig.clientId);
    setMsalApp(getLoginConfig({
      clientId: easyConfig.clientId,
      authority: easyConfig.authority,
      redirectUri: easyConfig.redirectUri
    }));
    result = <>{props.children}</>
  }
  return result;
};

export default connector(Init);
