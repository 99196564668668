import { IStyle, IProcessedStyleSet, mergeStyleSets } from "@fluentui/react";
import { desktop } from "../../shared/mediaQuery";

interface ILinkListStyle {
    LinkListSection: IStyle;
    LinkList: IStyle;
    LinkItem: IStyle;
    Icon: IStyle;
}

const styleFactory: () => IProcessedStyleSet<ILinkListStyle> = () => {
    return mergeStyleSets<ILinkListStyle>({
        LinkListSection:{
            width: "100%",
            selectors: {
              [desktop]:{
                    // width: "30rem"
                }
            }
        },
        LinkList: {
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            margin: "auto",
            padding: "0",
            textAlign: "center"
        },
        LinkItem: {
            boxSizing: "border-box",
            width: "6.5rem",
            height: "6.5rem",
            listStyle: "none",
            display: "flex",
            flexFlow: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: "0.5rem",
            margin: "0.5rem",
            boxShadow: "0 2px 4px -0.75px rgba(0,0,0,0.5)",
            cursor: "pointer"
        },
        Icon:{
            fontSize: "2rem",
        }
    });
}

export default styleFactory;
