import { mergeStyleSets, IProcessedStyleSet, IStyle, ITheme } from '@fluentui/react'

interface IRichTextStyles {
  root: IStyle,
  richtext: IStyle,
  insertLinkDialog: IStyle,
  headerDropDown: IStyle,
  fontSizeDropDown: IStyle,
  toolbarDropDown: IStyle,
  toolbarButtonH1: IStyle,
  toolbarButtonH2: IStyle,
  toolbarButtonH3: IStyle,
  toolbarButtonH4: IStyle,
  toolbarButtonH5: IStyle,
  toolbarButtonBlockQuote: IStyle,
  toolbarDropDownTitleIcon: IStyle,
  toolbarDropDownIcon: IStyle,
  //toolbarDropDownOption: IStyle,
  toolbarSubmenuCaret: IStyle,
  toolbarSubmenuDisplayButton: IStyle,
  actions: IStyle,
  action: IStyle,
  actionsRight: IStyle,
  unlinkButton: IStyle,
}

const richTextStyle = (theme: ITheme): IProcessedStyleSet<IRichTextStyles> => {

  return mergeStyleSets<IRichTextStyles>({
    root: {
      '&.edit-mode': {
        border: "1px solid " + theme.palette.neutralQuaternary,
      },

      "& .ql-toolbar": {
        backgroundColor: theme.palette.neutralLighterAlt,
        color: theme.semanticColors.bodyText,
        //display: "none",
      },
      "& .ms-TooltipHost": {
        display: "inline-block!important",
      },

      "& .ms-Dropdown-container": {
        display: "inline-block!important",
      },
      "& .ms-Button": {
        //backgroundColor: theme.palette.neutralQuaternary + "!important",
        //color: theme.semanticColors.bodyText + "!important",

        backgroundColor: theme.semanticColors.buttonBackground + "!important",
        color: theme.semanticColors.buttonText + "!important",
      },
      "& .ms-Button:hover": {
        backgroundColor: theme.palette.neutralLight,
        //color: theme.semanticColors.bodyText,
      },

      "& .ms-Button:focus": {
        borderColor: theme.palette.themeSecondary,
        outline: 0,
        //color: theme.semanticColors.bodyText,
      },

      "& .ms-Button:active": {
        //color: theme.semanticColors.bodyText,
      },

      "& .ms-Button.is-checked": {
        backgroundColor: theme.semanticColors.primaryButtonBackground + "!important",
        color: theme.semanticColors.primaryButtonText + "!important",
      },
      "& .ms-Dropdown-title": {
        backgroundColor: theme.semanticColors.buttonBackground + "!important",
        color: theme.semanticColors.buttonText + "!important",
        border: "1px solid " + theme.palette.neutralQuaternary,
        minHeight: 34,
        paddingBottom: 0,
        paddingLeft: 9,
        paddingRight: 20,
        paddingTop: 2,
        transition: "all 0.3s",
      },
      "& .ms-Dropdown-title:hover": {
        backgroundColor: theme.palette.neutralQuaternaryAlt,
        color: theme.palette.neutralLighterAlt,
      },

      "& .ms-Dropdown-title:focus": {
        borderColor: theme.palette.themeSecondary,
        outline: 0,
        color: theme.palette.neutralLighterAlt,
      },

      "& .ms-Dropdown-title:active": {
        color: theme.palette.neutralLighterAlt,
      },

      "& .ms-Dropdown-caretDownWrapper": {
        right: 4,
        marginTop: -1,
      },
      "& .ql-snow.ql-toolbar button, & .ql-snow .ql-toolbar button": {
        // backgroundColor: theme.palette.neutralPrimary,
        // color: theme.palette.neutralLighterAlt,
        border: "1px solid " + theme.palette.neutralQuaternary,
        fontSize: 14,
        minWidth: 34,
        height: 34,
        paddingTop: 4,
        paddingLeft: 8,
        paddingRight: 8,
      },
      "& .ql-toolbar.ql-snow": {
        padding: 1,
        //height: 37,
        fontFamily: "'Segoe UI Web (West European)', Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
        //border: "none",
        borderStyle: "solid",
        borderWidth: "0 0 1px 0",
        borderColor: theme.palette.neutralQuaternary,
        marginBottom: 2,
        zIndex: 3,
        animationName: "ms-fadeIn, ms-slideUpIn10",
        animationDuration: "167ms",
        animationTimingFunction: "cubic-bezier(0.1, 0.25, 0.75, 0.9)",
        animationFillMode: "both",
        transition: "all 367ms cubic-bezier(0.1, 0.9, 0.2, 1)",
      },
      "& .ql-snow.ql-toolbar:after, & .ql-snow .ql-toolbar:after": {
        clear: "both",
        content: "",
        display: "table",
      },
      "& .ql-container.ql-snow": {
        border: "none",
        minHeight: "10em",
        maxHeight: "50em",
        resize: "vertical",
        overflowY: "auto",
        //height: "15em",
      },
      "& .ql-active .ql-editor:hover": {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.neutralTertiary,
      },
      "& .ql-active .ql-editor:focus,& .ql-active .ql-editor:active,& .ql-active .ql-editor:active:hover": {
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.themePrimary,
      },
      "& .ql-active .ql-toolbar": {
        display: "inline-flex",
        position: "absolute",
        top: -28,
        opacity: 1,
        height: 34,
        boxShadow: "0px 0px 5px 0px #c8c8c8",
      },
      "&.edit-mode .ql-snow .ql-editor, &.edit-mode .ql-editor": {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8,
      },
      "& .ql-snow .ql-editor, & .ql-editor": {
        border: "1px solid transparent",
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 68,
        fontFamily: "'Segoe UI Web (West European)', Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
        fontSize: 17,
        overflowX: "hidden",
      },
      "& .ql-snow .ql-editor h1, & .ql-editor h1": {
        fontWeight: "100 !important",
        fontSize: 32,
      },
      "& .ql-snow .ql-editor h2, & .ql-editor h2": {
        fontWeight: "100 !important",
        fontSize: 28,
      },
      "& .ql-snow .ql-editor h3, & .ql-editor h3": {
        fontSize: 24,
        fontWeight: "100 !important",
      },

      "& .ql-snow .ql-editor h4, & .ql-editor h4": {
        fontSize: 21,
        fontWeight: "100 !important",
      },

      "& .ql-snow .ql-editor h5, & .ql-editor h4": {
        fontSize: 21,
        fontWeight: "100 !important",
      },

      "& .ql-editor blockquote,& .ql-editor div,& .ql-editor h1,& .ql-editor h2,& .ql-editor h3,& .ql-editor h4,& .ql-editor h5,& .ql-editor ol,& .ql-editor p,& .ql-editor ul": {
        color: theme.semanticColors.bodyText,
        lineHeight: "130%",
        margin: "0 0 16px",
        wordWrap: "break-word",
      },

      "& .ql-editor a": {
        color: theme.semanticColors.link + "!important",
      },

      "& .ql-editor blockquote": {
        borderBottomColor: theme.palette.neutralTertiaryAlt,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderLeftStyle: "none",
        borderLeftWidth: 0,
        borderLeftColor: "transparent",
        borderRightStyle: "none",
        borderRightWidth: 0,
        borderRightColor: "transparent",
        borderTopColor: theme.palette.neutralTertiaryAlt,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        color: theme.palette.neutralSecondaryAlt,
        fontSize: 18,
        fontStyle: "italic",
        fontWeight: 100,
        lineHeight: 20,
        marginBottom: 18,
        paddingBottom: 20,
        marginTop: 18,
        marginRight: 0,
        marginLeft: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        textAlign: "center",
      },
      "& .ql-editor .ql-size-small": {
        fontSize: 12,
      },
      "& .ql-editor .ql-size-medium": {
        fontSize: 14,
      },
      "& .ql-editor .ql-size-mediumplus": {
        fontSize: 15,
      },
      "& .ql-editor .ql-size-large": {
        fontSize: 17,
      },
      "& .ql-editor .ql-size-xlarge": {
        fontSize: 21,
      },
      "& .ql-editor .ql-size-xlargeplus": {
        fontSize: 24,
      },
      "& .ql-editor .ql-size-xxlarge": {
        fontSize: 28,
      },
      "& .ql-editor .ql-size-xxxlarge": {
        fontSize: 32,
      },
      "& .ql-editor .ql-size-xxlargeplus": {
        fontSize: 36,
      },
      "& .ql-editor .ql-size-super": {
        fontSize: 42,
      },

      "& .ql-active .ql-editor": {
        // backgroundColor: red,
        color: "inherit",
      },

      "& .ql-tooltip": {
        display: "none !important",
      },

      "& .ql-editor.ql-blank::before": {
        fontStyle: "normal",
        color: theme.palette.neutralTertiary,
        fontSize: 17,
        fontWeight: 300,
        lineHeight: "130%",
      },

      // "div#DropDownStyles-list, div#DropDownAlign-list, div#DropDownLists-list": {
      //   selectors: {
      "& div .ms-Dropdown-item": {
        backgroundColor: theme.palette.neutralPrimary + "!important",
        color: theme.palette.neutralLighterAlt + "!important",
        transition: "all 0.3s",
      },

      "& div .ms-Dropdown-item:hover": {
        backgroundColor: theme.palette.neutralSecondaryAlt + "!important",
        color: theme.palette.neutralLighterAlt + "!important",
        cursor: "pointer",
      },

      "& div .ms-Dropdown-item.is-selected,& .ms-Dropdown-item.is-selected:hover": {
        backgroundColor: theme.palette.themePrimary + "!important",
        color: theme.palette.neutralLighterAlt + "!important",
      }
      //   }
      // }
    },
    richtext: {
      color: "inherit",
    },
    insertLinkDialog: {
      selectors: {
        '& .ms-Dialog-main': {
          maxWidth: 540,
          width: 540,
        },
      }
    },
    actions: {
      position: 'relative',
      width: '100%',
      minHeight: 24,
      lineHeight: 24,
      marginTop: 20,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      fontSize: 0,
    },
    actionsRight: {
      textAlign: 'right',
      marginRight: -4,
      fontSize: 0,
    },
    action: {
      marginTop: 0,
      marginRight: 4,
      marginBottom: 0,
      marginLeft: 4,
    },
    unlinkButton: {
      marginTop: 4,
      fontSize: 14,
      float: 'left',
    },
    headerDropDown: {
      width: 160,
    },
    fontSizeDropDown: {
      width: 80,
    },
    toolbarDropDown: {
      height: 34,
      fontSize: 16,
    },
    toolbarDropDownTitleIcon: {
      marginTop: -1,
      marginRight: 0,
    },
    toolbarDropDownIcon: {
      marginTop: -2,
      marginRight: 0,
      marginLeft: -4,
      fontSize: 16,
    },
    toolbarButtonH1: {
      fontSize: 23,
      fontWeight: 100,
    },
    toolbarButtonH2: {
      fontSize: 21,
      fontWeight: 100,
    },
    toolbarButtonH3: {
      fontSize: 18,
      fontWeight: "100!important",
    },
    toolbarButtonH4: {
      fontSize: 16,
      fontWeight: "100!important",
    },
    toolbarButtonH5: {
      fontSize: 15,
      fontWeight: "100!important",
    },
    toolbarButtonBlockQuote: {
      fontSize: 14,
      fontStyle: "italic",
    },
    toolbarSubmenuCaret: {
      display: "inline-block",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 8,
      speak: "none",
      fontFamily: "FabricMDL2Icons-6",
      top: "50%",
      color: theme.semanticColors.bodyText,
    },
    toolbarSubmenuDisplayButton: {
      width: "100%",
      fontSize: 16,
    },
  });
}

export default richTextStyle;
