import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IReportData, ISampledDevelopment, IProvision, ISampling } from '../types';
import { ProvisionDetail } from '../../../components/ProvisionReport/ProvisionDetail/ProvisionDetail';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../../store/store';

const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
})
const connector = connect(mapStateToProps);
type PropsFromStore = ConnectedProps<typeof connector>
interface IRouteProps {
  provision: string;
}

interface IProvisionDetailContainer extends RouteComponentProps<IRouteProps>, PropsFromStore {
  reportData: IReportData;
  provisionById: () => Map<string, IProvision>;
}

export const samplingCompareFunc: (a: ISampling, b: ISampling) => number = (a, b) => a.gridOrder < b.gridOrder ? -1 : a.gridOrder > b.gridOrder ? 1 : 0

const ProvisionDetailContainer: React.FC<IProvisionDetailContainer> = (props) => {
  const { reportData, provisionById } = props;
  const { provision } = props.match.params;

  const provisionObj = provisionById().get(provision);
  if (!provisionObj) throw Error("ProvisionObj Null");
  const samplings = reportData.sampling.filter(s => s.receiptId === provision);
  return (
    <ProvisionDetail
      theme={props.theme}
      item={{
        name: `${provisionObj.specie} ${provisionObj.variety}`,
        bem: provisionObj.bem,
        ddt: provisionObj.transportDocNo,
        weight: provisionObj.weight,
        date: provisionObj.bemDate,
        parcel: provisionObj.parcels,
        work: provisionObj.work,
        type: provisionObj.type,
        differential: provisionObj.differential,
        quality: provisionObj.quality,
        sampledQuality: samplings.length ? samplings[0]?.sampleQuality : "",
        sampledDefects: samplings.length ? samplings[0]?.defects : "",
        sampled: samplings.reduce((total, next) => total + next.developedWeight, 0),
        development: [...samplings].sort(samplingCompareFunc).map<ISampledDevelopment>(s => ({
          calibre: s.calibre,
          percent: s.sampledPercentage,
          quality: s.quality,
          weight: s.developedWeight
        })),
        washability: samplings.length ? samplings[0].washability : ""
      }} />)
}

export default connector(withRouter(ProvisionDetailContainer));
