import React, { useEffect, useState, useCallback } from 'react';

import Header from '../../components/Navigation/Header/Header';
import Footer from '../../components/Navigation/Footer/Footer';
import NavigationTop from '../../components/Navigation/NavigationTop/NavigationTop';
import NavigationMenuMobile from '../../components/Navigation/NavigationMobile/NavigationMobile';
import Background from '../../components/Background/Background';

import { IPersonaData } from "../../components/Navigation/Header";

import stylesFactory from './Layout.styles';
import graphClient from '../../shared/graphClient';
import useLogin from '../../hooks/UseLogin';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../store/store';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as settings from '../../containers/Settings/route';
import { setSearchFilter } from '../../store/actions/context';

const mapStateToProps = (state: IEasyStore) => {
  return {
    searchFilter: state.context.globalSearchExpression,
    menu: state.context.topMenu,
    theme: state.context.fullTheme,
    companyData: state.config.companyData
  }
}

const mapDispatchToProps = {
  setSearchFilter: (searchFilter: string) => setSearchFilter(searchFilter)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromStore = ConnectedProps<typeof connector>

interface ILayoutProps extends RouteComponentProps, PropsFromStore {
}

const Layout: React.FC<ILayoutProps> = (props) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [personaData, setPersonaData] = useState({ loading: true, name: "", imageUrl: "" } as IPersonaData)
  const menu = props.menu;
  const history = props.history;
  const companyData = props.companyData;

  const { signOut } = useLogin();

  const openMobileMenuHandler = () => {
    setMobileMenuIsOpen(true);
  }

  const closeMobileMenuHandler = () => {
    setMobileMenuIsOpen(false);
  }

  const loadPersonData = useCallback(() => {
    setPersonaData({ loading: true, imageUrl: "", name: "" })
    graphClient.get("/beta/me/photo/$value", { responseType: 'arraybuffer' })
      .then(response => {
        if (response.data) {
          const imageBase64 = Buffer.from(response.data, 'binary').toString('base64');
          const imageUrl = `data:${response.headers.ContentType};base64,${imageBase64}`;
          setPersonaData({ imageUrl: imageUrl, loading: false, name: "" });
        } else {
          graphClient.get("/v1.0/me/").then(response => {
            setPersonaData({ imageUrl: "", loading: false, name: response.data.displayName });
          })
        }
      }).catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    loadPersonData();
  }, [loadPersonData]);

  const companyLogoClickHandler = () => {
    history.push("/");
  }

  const easyLogoClickHandler = () => {
    window.open("https://www.grupposistema.it/soluzioni/easycollaboration/", "_blank");
  }

  const allSettingsClickHandler = () => {
    history.push(settings.path);
  }

  const style = stylesFactory(props.theme);

  return (
    <React.Fragment>
      <Background theme={props.theme} />
      <div className={style.layout}>
        <NavigationMenuMobile isOpen={mobileMenuIsOpen} close={closeMobileMenuHandler} menu={menu} />
        <div className={style.headerLayout}>
          <Header
            theme={props.theme}
            openMobileMenu={openMobileMenuHandler}
            personaData={personaData}
            signOut={signOut}
            searchFilter={props.searchFilter}
            setSearchFilter={props.setSearchFilter}
            logoClicked={companyLogoClickHandler}
            allSettingClicked={allSettingsClickHandler} />
          <NavigationTop theme={props.theme} menus={menu} />
        </div>
        <main className={style.mainLayout}>{props.children}</main>
        <Footer logoClicked={easyLogoClickHandler} companyData={companyData} />
      </div>
    </React.Fragment >
  )
}

export default withRouter(connector(Layout));
