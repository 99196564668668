import { put } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { appSetConfigDefault, appSetConfigDone, appSetConfigStart } from "../actions/config";
import { IEasyConfig } from "../types/config";

export function* configSaga(action: any) {
  yield put(appSetConfigStart());
  try {
    const response: AxiosResponse<IEasyConfig> = yield axios.get<IEasyConfig>('/config.override.json');
    yield put(appSetConfigDone({ ...response.data }));
  } catch (e) {
    yield put(appSetConfigDefault())
  }
}
