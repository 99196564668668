import React, { useMemo } from 'react';
import { Image, ImageFit } from '@fluentui/react';

import stylesFactory from './Logo.styles';

export enum LogoType {
  Company = "Company", //default
  EasyCollaboration = "EasyCollaboration",
}

export interface ILogo {
  onClick?: () => void;
  /** Logo Type. Default: Company Logo */
  logoType?: LogoType;
}

const Logo: React.FC<ILogo> = ({ onClick, logoType }) => {

  const style = useMemo(() => {
    return stylesFactory(onClick !== undefined);
  }, [onClick]);

  const imgSrc = useMemo(() => {
    switch (logoType) {
      case LogoType.EasyCollaboration:
        return process.env.PUBLIC_URL + "/ec_150_b.png";

      default: //LogoType.Company
        return process.env.PUBLIC_URL + "/logo_company.png";
    }
  }, [logoType])
  return (
    <Image styles={style.imageStyle} src={imgSrc} onClick={onClick} alt="Easy Collaboration" imageFit={ImageFit.contain} />
  );
}

export default Logo;
