import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IReportData } from '../types';
import { ISpecieCardProps } from '../../../components/ProvisionReport/Card/Card';
import ListVariety from '../../../components/ProvisionReport/ListVariety/ListVariety';
import { pathForProvisionsReportByVariety, pathForProvisionsSummaryReport } from '../../../shared/route';
import { IEasyStore } from '../../../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
})

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;
interface IListVarietyContainer extends RouteComponentProps<{ specie: string }>, PropsFromStore {
  reportData: IReportData;
}

const ListVarietyContainer: React.FC<IListVarietyContainer> = (props) => {
  const reportData = props.reportData;
  const specie = props.match.params.specie;
  const history = props.history;

  const showProvisionSummaryHandler = (item: ISpecieCardProps & { key: string }) => {
    const path = pathForProvisionsSummaryReport(
      reportData.partnerCode,
      reportData.yearCode,
      specie,
      item.title,
      "work");
    window.scrollTo(0, 0);
    history.push(path);
  }
  const showProvisionDetailHandler = (item: ISpecieCardProps & { key: string }) => {
    const path = pathForProvisionsReportByVariety(
      reportData.partnerCode,
      reportData.yearCode,
      specie,
      item.title);
    window.scrollTo(0, 0);
    history.push(path);
  }

  const provisions = reportData.provisions
    .filter(p => p.specie === specie)
    .reduce<Map<string, { weight: number, sampled: number }>>((agg, next) => {
      !agg.has(next.variety) && agg.set(next.variety, { sampled: 0, weight: 0 });
      const current = agg.get(next.variety);
      current!.sampled = current!.sampled + reportData.sampling.filter(s => s.receiptId === next.provisionId).reduce((agg2, next2) => agg2 + next2.developedWeight, 0)
      current!.weight = current!.weight + next.weight
      return agg;
    }, new Map());

  const items = Array.from(provisions.entries()).map<ISpecieCardProps & { key: string }>(c => {
    const [key, value] = c;
    return {
      key: "variety_" + key,
      delivered: value.weight,
      sampled: value.sampled,
      title: key,
      theme: props.theme
    }
  });

  return (
    <ListVariety
      theme={props.theme}
      items={items}
      showProvisionSummary={showProvisionSummaryHandler}
      showProvisionsDetail={showProvisionDetailHandler} />)
}

export default connector(withRouter(ListVarietyContainer));
