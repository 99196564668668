import { ITheme } from 'office-ui-fabric-react';
import React from 'react';
import styleFactory from './Background.style';

interface IBackgroundProps{
  theme: ITheme
}

const Background: React.FC<IBackgroundProps> = (props) => {
    const style = styleFactory(props.theme);
    return (
        <div className={style.Background}></div>
    )
}

export default Background;
