import { generatePath } from "react-router-dom";
import { UserRole } from "../../store/types/context";
import { ListView } from "./CommandBar";

export const path = "/document-types/:viewname?";
export { default as Component } from './DocumentType';
export const roles = [UserRole.Admin]

export const pathForDocumentType = (listView: ListView = ListView.AllItems): string => {
  switch (listView) {
    case ListView.AllItems:
      return generatePath(path, { });
    case ListView.OnlyDisabled:
      return generatePath(path, { viewname: "disabled" });
    case ListView.OnlyEnabled:
      return generatePath(path, { viewname: "enabled" });
  }
}
