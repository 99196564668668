import React from 'react';
import { IBasePickerSuggestionsProps, Icon, IPickerItemProps, ISuggestionItemProps, ITag, TagItem, TagItemSuggestion, TagPicker, Text, TooltipHost } from '@fluentui/react';
import { IODataList } from '../../models/odata';
import { IHttpClient } from '../../shared/easyAxios';
import { onlyUnique } from '../../shared/utility';

export interface IDocumentGroupPickerProps {
  easyClient: IHttpClient;
  value: IDocumentGroupPickerTag[];
  valueChange: (newTag: IDocumentGroupPickerTag[]) => void;
  maxItemsSelectable?: number;
  publishableDocTypeIdFilter?: string;
  receivableDocTypeIdFilter?: string;
  disabled?: boolean;
}

export interface IDocumentGroup {
  id: string;
  description: string;
  areaName?: string;
}

export enum DocumentGroupPickerTagType {
  Group,
  Area
}

export interface IDocumentGroupPickerTag extends ITag {
  //description: string; nel caso serva aggiungere campi
  type: DocumentGroupPickerTagType,
  groups?: IDocumentGroup[],
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'Nessun gruppo trovato',
};

export const getSelectedGroupsIdFromTags = (tags?: IDocumentGroupPickerTag[]): string[] => {
  const selectedTags = tags || [];
  return selectedTags
    .flatMap(t => {
      return t.type === DocumentGroupPickerTagType.Area
        ? (t.groups || [])?.map(g => g.id)
        : [t.key as string];
    })
    .filter(onlyUnique);
}

const DocumentGroupPicker: React.FC<IDocumentGroupPickerProps> = (props) => {

  const easyClient = props.easyClient;
  const maxItemsSelectable = props.maxItemsSelectable;
  const selectedTagChangeHandler = props.valueChange;
  const selectedItems = props.value || [];
  const selectedGroups = selectedItems.filter(i => i.type === DocumentGroupPickerTagType.Group);
  const selectedAreas = selectedItems.filter(i => i.type === DocumentGroupPickerTagType.Area);

  const filterSelectedTags = (filterText?: string): PromiseLike<ITag[]> => {



    let filterClause = "";
    let strAnd = "";

    if ((props.publishableDocTypeIdFilter || "").length > 0) {
      filterClause = filterClause + `${strAnd}publishableDocTypeIds/any(dt : dt eq '${encodeURIComponent(props.publishableDocTypeIdFilter!)}')`;
      strAnd = " and ";
    }

    if ((props.receivableDocTypeIdFilter || "").length > 0) {
      filterClause = filterClause + `${strAnd}receivableDocTypeIds/any(dt : dt eq '${encodeURIComponent(props.receivableDocTypeIdFilter!)}')`;
      strAnd = " and ";
    }

    return easyClient.get<IODataList<IDocumentGroup>>(`/api/Groups?$select=id,description,areaName&$orderby=description&$filter=${filterClause}`,
      { headers: { "X-version": "1.0" } }).then(response => {
        const availGroups = response.data.value;
        const filterTextLower = filterText?.toLowerCase() || "";

        const groupsByArea = availGroups
          .filter(g => g.areaName?.length) // solo con area valorizzata
          .reduce<Record<string, IDocumentGroup[]>>((r, group) => {
            r[group.areaName!] = [...r[group.areaName!] || [], group];
            return r;
          }, {} as Record<string, IDocumentGroup[]>);

        const newOptions_Area = Object.keys(groupsByArea).sort().filter(areaName =>
          (filterTextLower.length === 0 || areaName.toLowerCase()?.indexOf(filterTextLower) >= 0) //filtro in base al testo digitato
          && !selectedAreas.some(sa => sa.key === areaName)) // escludo eventuali aree già selezionate
          .map(areaName => {
            return {
              key: areaName,
              name: areaName,
              type: DocumentGroupPickerTagType.Area,
              groups: groupsByArea[areaName]
            }
          });

        const newOptions_Groups = availGroups.filter(g =>
          (filterTextLower.length === 0 || g.description.toLowerCase().indexOf(filterTextLower) >= 0) //filtro in base al testo digitato
          && !selectedGroups.some(sg => sg.key === g.id) // escludo eventuali gruppi già selezionati
        ).map<IDocumentGroupPickerTag>(group => ({
          key: group.id,
          name: group.description,
          type: DocumentGroupPickerTagType.Group,
        }));

        return [...newOptions_Area, ...newOptions_Groups];
      }).catch(err => {
        console.error(err);
        return []
      });
  };

  const emptyResolveSuggestionHandler = () => {
    return filterSelectedTags("");
  }

  const getAreaGroupsTooltipText = (groupsDesc?: string[]) => {
    if (groupsDesc === undefined || groupsDesc.length === 0) return "";

    const maxGroupsToShow = 5;
    const residualGroupsCount = groupsDesc.length - maxGroupsToShow;
    return groupsDesc.sort().slice(0, Math.min(maxGroupsToShow, groupsDesc.length))?.join(", ") + (residualGroupsCount > 0 ? ` e altri ${residualGroupsCount}` : "");
  }

  const renderTagItemContent = (tag: IDocumentGroupPickerTag) => {
    return (
      <TooltipHost
        content={getAreaGroupsTooltipText(tag.groups?.map(g => g.description))}
      >
        {tag.type === DocumentGroupPickerTagType.Area && (<Icon styles={{ root: { verticalAlign: "bottom", marginRight: "0.5em", fontSize: 18 } }} iconName="FabricUserFolder" />)}
        <Text variant="medium">{tag.name}</Text>
        {tag.type === DocumentGroupPickerTagType.Area && (<Text variant="xSmall" styles={{ root: { marginLeft: "0.5em" } }}>({tag.groups?.length || 0})</Text>)}
      </TooltipHost>
    );
  }

  const renderSuggestionItemHandler = (tag: ITag, itemProps: ISuggestionItemProps<ITag>) => {
    const groupTag = tag as IDocumentGroupPickerTag;

    return (<TagItemSuggestion>
      {renderTagItemContent(groupTag)}
    </TagItemSuggestion>);
  }

  const renderItemHandler = (itemProps: IPickerItemProps<ITag>) => {
    const tag = itemProps.item as IDocumentGroupPickerTag;
    return (
      <TagItem {...itemProps}>
        {renderTagItemContent(tag)}
      </TagItem>
    )
  }

  const getTextFromItem = (item: ITag) => item.name;

  const changeHandler = (items?: ITag[]) => {
    if (!items)
      return;
    selectedTagChangeHandler(items as IDocumentGroupPickerTag[]);
  }

  return (
    <div>
      <TagPicker
        onEmptyResolveSuggestions={emptyResolveSuggestionHandler}
        resolveDelay={300}
        onResolveSuggestions={filterSelectedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={maxItemsSelectable}
        selectedItems={selectedItems}
        onChange={changeHandler}
        onRenderSuggestionsItem={renderSuggestionItemHandler}
        onRenderItem={renderItemHandler}
        disabled={props.disabled}
      />
    </div>
  )
}

export default DocumentGroupPicker;
