import { IStackItemStyles, IStackStyles, ITheme } from '@fluentui/react';
import { desktop } from '../../../shared/mediaQuery';

interface IFooterStyles {
  footer: IStackStyles,
  logo: IStackItemStyles,
  content: IStackItemStyles,
}

const footerStyles: (theme: ITheme) => IFooterStyles = (theme) => {

  return {
    footer: {
      root: {
        backgroundColor: theme.palette.themePrimary,
        color: theme.palette.white,
        margintop: "3rem",
        padding: "2rem",
        // height: "3.5rem",
        // padding: "0.6rem 0.4rem",
        // overflow: "hidden",
        // maxWidth: "100vw",
        selectors: {
          [desktop]: {
            height: "6rem",
            padding: "1.1rem",
          },
        }
      },
    } as IStackStyles,
    logo: {
      root: {
        maxWidth: "8rem",
        selectors: {
          [desktop]: {
            maxWidth: "10rem",
          }
        }
      }
    } as IStackItemStyles,
    content: {
      root: {
        textAlign: "center",
        margin: "0.3rem",
      }
    } as IStackItemStyles,
  };
}

/*
 gridArea: "footer",
  background: theme.palette.themePrimary,
  display: "flex",
  justifyContent: "center",
  margintop: "3rem",
  padding: "2rem",
  color: theme.palette.white
    }
*/

export default footerStyles;
