import { mergeStyleSets, IProcessedStyleSet, IStyle, ITheme } from '@fluentui/react';

interface IHamburgerStyle {
  hamburger: IStyle;
  line: IStyle;
}

const hamburgerStyle = (theme: ITheme): IProcessedStyleSet<IHamburgerStyle> => {

  return mergeStyleSets<IHamburgerStyle>({
    hamburger: {
      height: "2rem",
      width: "2rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      cursor: "pointer"
    },
    line: {
      border: `1px solid ${theme.palette.themePrimary}`
    }
  })
}

export default hamburgerStyle;
