import React, { useEffect, useState, useMemo } from 'react';

import withInteractiveAuthToken, { IWithInteractiveLoginProps } from '../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper';
import withErrorHandling from '../../hoc/HttpClient/EasyHttpClient';

import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { Spinner, Stack } from '@fluentui/react';
import BreadcrumbContainer from './BreadcrumbContainer/BreadcrumbContainer';
import ProvisionDetailContainer from './ProvisionDetailContainer/ProvisionDetailContainer';
import ProvisionSummaryContainer from './ProvisionSummaryContainer/ProvisionSummaryContainer';
import ListSummaryContainer from './ListSummaryContainer/ListSummaryContainer';
import ListProvisionContainer from './ListProvisionContainer/ListProvisionContainer';
import ListVarietyContainer from './ListVarietyContainer/ListVarietyContainer';
import ListSpecieContainer from './ListSpecieContainer/ListSpecieContainer';
import Page from '../../hoc/Page/Page';

import {
  provisionReportDetailPath,
  IProvisionReportPathProps,
  provisionReportDetaiListlPath,
  provisionSummaryPath,
  provisionsSummaryPath,
  varietyPickerPath,
  rootPath
} from '../../shared/route';
// import easyClient from '../../shared/easyAxios';
import { IReportData, IProvision } from './types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import easyClient from '../../shared/easyAxios';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
});

const connector = connect(mapStateToProps);
type PropsFromStore = ConnectedProps<typeof connector>


interface IProvisionReportProp extends RouteComponentProps<IProvisionReportPathProps>, PropsFromStore {
  easyClient: {
    get: <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
  }
}

const ProvisionReport: React.FC<IProvisionReportProp & IWithInteractiveLoginProps & PropsFromStore> = ({ match, easyClient, theme }) => {

  const { year, partnerCode } = match.params;

  const [reportData, setReportData] = useState<IReportData>({
    provisions: [],
    sampling: [],
    partnerName: "",
    partnerCode: "",
    yearCode: ""
  })
  const [loading, setLoading] = useState(true);
  const provisionById = useMemo(() => {
    let map: Map<string, IProvision>;
    return () => {
      if (!map)
        map = new Map<string, IProvision>(reportData.provisions.map((p => [p.provisionId, p])));
      return map;
    }
  }, [reportData]);

  useEffect(() => {
    setLoading(true);
    easyClient.get<IReportData>(`/api/ProvisionReports('${partnerCode}','${year}')`, {
      headers: { "X-version": "1.0" },
      transformResponse: (content: string) => {
        return JSON.parse(content, (key, value) => {
          return key === "bemDate" ? new Date(value) : value;
        })
      }
    }).then(response => {
      setReportData({
        ...response.data,
      });
    })
      .finally(() => {
        setLoading(false)
      })
  }, [partnerCode, year, easyClient])

  if (loading) {
    return <Spinner />
  }

  return (
    <Page title="Report Conferimento" iconName="PowerBILogo" pageWidth="960px" theme={theme}>
      <Stack styles={{ root: { padding: "0.75rem" } }}>
        <Stack.Item>Esercizio: {year}</Stack.Item>
        <Stack.Item>Partner: {reportData.partnerCode?.toUpperCase()} - {reportData.partnerName}</Stack.Item>
        <Stack.Item>
          <BreadcrumbContainer provisionById={provisionById} />
        </Stack.Item>
        <Stack.Item grow styles={{ root: { width: "100%" } }}>
          <Switch>
            <Route exact path={provisionReportDetailPath} component={() => <ProvisionDetailContainer reportData={reportData} provisionById={provisionById} />} />
            <Route exact path={provisionSummaryPath} component={() => <ProvisionSummaryContainer reportData={reportData} />} />
            <Route exact path={provisionsSummaryPath} component={() => <ListSummaryContainer reportData={reportData} />} />
            <Route exact path={provisionReportDetaiListlPath} component={() => <ListProvisionContainer reportData={reportData} />} />
            <Route exact path={varietyPickerPath} component={() => <ListVarietyContainer reportData={reportData} />} />
            <Route exact path={rootPath} component={() => <ListSpecieContainer reportData={reportData} />} />
            <Redirect to="/" />
          </Switch>
        </Stack.Item>
      </Stack>
    </Page>
  );
}

export default withErrorHandling(withInteractiveAuthToken(connector(ProvisionReport)), easyClient.getInstance());
