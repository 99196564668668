import React from 'react';

import { ITheme, Link, Stack } from '@fluentui/react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import stylesFactory from './NavigationTop.styles';
import { INavRoute } from '../../../store/types/context';

export interface INavigationTop {
  menus: INavRoute[];
  theme: ITheme;
}

const navigationTop: React.FC<INavigationTop & RouteComponentProps> = (props) => {
  const { menus, theme } = props;
  const history = props.history;
  const styles = stylesFactory(theme);
  const menu = menus.map(m => {
    return (
      <Link key={m.key} styles={{ root: { color: theme.palette.white, selectors: { ":hover,:hover:active,:focus": { color: theme.palette.white } } } }} onClick={() => history.push(m.url)}>{m.text}</Link>
    )
  })
  return (
    <nav>
      <Stack className={styles.navigationTop} horizontal horizontalAlign="space-around">
        {menu}
      </Stack>
    </nav>
  )
}
export default withRouter(navigationTop);
