import React from 'react'
import { IBasePickerSuggestionsProps, ITag, TagPicker } from '@fluentui/react';
import { IODataList } from '../../models/odata';
import { IHttpClient } from '../../shared/easyAxios';
import { IArea } from '../../types/Area'

interface IAreaPickerProps {
  easyClient: IHttpClient;
  value?: IArea;
  areaChanged: (newArea?: IArea | null) => void;
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'Nessuna Area',
};


const AreaPicker: React.FC<IAreaPickerProps> = (props) => {
  const filterSelectedTags = (filterText?: string): PromiseLike<ITag[]> => {
    return props.easyClient.get<IODataList<IArea>>(`/api/Areas?search=${filterText}&$top=100&$orderby=Name`, { headers: { "X-version": "1.0" } }).then(response => {
      const newOptions = response.data.value.map<ITag>(area => ({
        key: area.id,
        name: area.name
      }));
      return newOptions
    }).catch(err => {
      console.error(err);
      return []
    });
  };

  const emptyResolveSuggestionHandler = () => {
    return filterSelectedTags("");
  }

  // const renderSuggestionItemHandler = (tag: ITag) => {
  //   return (<div style={{ textAlign: "left" }}>
  //     <Text block>{tag?.name}</Text>
  //   </div>)
  // }

  const getTextFromItem = (item: ITag) => item.name;

  const changeHandler = (items?: ITag[]) => {
    if (props.areaChanged) {
      const aree = (items ?? []).map<IArea>(tag => ({
        id: tag.key as string,
        name: tag.name
      }));
      props.areaChanged(aree.length ? aree[0] : null);
    }
  }

  const selected: ITag[] = [];
  if (props.value) {
    selected.push({ key: props.value.id, name: props.value.name })
  }

  return (
    <div>
      <TagPicker
        onEmptyResolveSuggestions={emptyResolveSuggestionHandler}
        resolveDelay={300}
        onResolveSuggestions={filterSelectedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={1}
        selectedItems={selected}
        onChange={changeHandler}
      // onRenderSuggestionsItem={renderSuggestionItemHandler}
      />
    </div>
  )
}

export default AreaPicker;
