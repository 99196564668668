import { generatePath } from 'react-router';
import Document from './Document';
// const prefix = "/area/soci";

export const rootPath = "/documenti/:docTypeId?";

export const pathForDocuments = (docTypeId: string, id?: string): string => {
  const path = generatePath(rootPath, { docTypeId });
  const searchBuilder = new URLSearchParams();
  if (id) {
    searchBuilder.set("id", id);
  }

  if (Array.from(searchBuilder).length) {
    return path + "?" + searchBuilder.toString();
  }
  else {
    return path;
  }
}

export const documentRoute = { path: rootPath, component: Document }
