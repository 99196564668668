import { IStyle, IProcessedStyleSet, mergeStyleSets, DefaultEffects, ITheme } from "@fluentui/react"
import { desktop } from "../../shared/mediaQuery";

interface IPageStyle {
  page: IStyle;
}

const pageStyleFactory = (pageWidth: string, theme: ITheme): IProcessedStyleSet<IPageStyle> => {

  return mergeStyleSets<IPageStyle>({
    page: {
      maxWidth: pageWidth,
      minHeight: "100%",
      margin: "auto",
      boxShadow: DefaultEffects.elevation4,
      padding: "0 0.3rem 1rem 0.3rem",
      backgroundColor: theme.semanticColors.bodyBackground,
      color: theme.semanticColors.bodyText,

      selectors:{
        [desktop]:{
          padding: "0 0.5rem 1rem 0.5rem",
        }
      }
    }
  })
}

export default pageStyleFactory;
