import * as React from 'react';

import { IDeliveryNotice } from '../../../models/deliveryNotice';
import { CheckboxVisibility, DetailsListLayoutMode, Fabric, IColumn, MarqueeSelection, mergeStyles, SelectionMode, ShimmeredDetailsList, Selection, IObjectWithKey, Announced } from '@fluentui/react';
import { useMemo, useRef } from 'react';

const detailListContainerStyle = mergeStyles({
  maxWidth: "99%",
});

export interface IDetailsListDeliveryNoticeState {
  items: IDeliveryNotice[];
  itemsAreLoading: boolean;
  isFormDetailOpen: boolean;
  isMultiPartner: boolean;
  selectionDetails: string;
  searchFilter: string;
  onSelectionChanged: (selection: Selection) => void;
  onItemInvoked: (item?: IDeliveryNotice, index?: number, ev?: Event) => void;
}

const DeliveryNoticeDetailsList: React.FC<IDetailsListDeliveryNoticeState> = (props) => {

  const {
    items,
    itemsAreLoading,
    isFormDetailOpen,
    isMultiPartner,
    onSelectionChanged,
    onItemInvoked } = props;

  const columns: IColumn[] = [];

  // Colonne sempre presenti
  columns.push(
    {
      key: 'deliveryDate', name: 'Data consegna', fieldName: 'deliveryDate', minWidth: 70, maxWidth: 130, isResizable: true,
      onRender: (item: IDeliveryNotice) => { return (<span>{new Date(item.deliveryDate).toLocaleDateString()}</span>) }
    },
    { key: 'deliveryPeriodDesc', name: 'Periodo', fieldName: 'deliveryPeriodDesc', minWidth: 50, maxWidth: 150, isResizable: true, isCollapsible: true });

  // Colonne condizionali
  isMultiPartner && columns.push({ key: 'partnerName', name: 'Fornitore', fieldName: 'partnerName', minWidth: 90, maxWidth: 150, isResizable: true, });

  // Colonne sempre presenti
  columns.push(
    { key: 'varietyDesc', name: 'Varietà', fieldName: 'varietyDesc', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'qualityDesc', name: 'Qualità', fieldName: 'qualityDesc', minWidth: 60, maxWidth: 100, isResizable: true, isCollapsible: true },
    {
      key: 'packaging', name: 'Colli', minWidth: 100, maxWidth: 200, isResizable: true,
      onRender: (item: IDeliveryNotice) => { return (item.parcels || 0) !== 0 ? (<span>{item.parcels} &times; {item.packagingDesc}</span>) : (<span></span>) }
    },
    {
      key: 'pallet', name: 'Pedane', minWidth: 100, maxWidth: 200, isResizable: true,
      onRender: (item: IDeliveryNotice) => { return (item.pallets || 0) !== 0 ? (<span>{item.pallets} &times; {item.palletDesc}</span>) : (<span></span>) }
    },
    { key: 'notes', name: 'Note', fieldName: 'notes', minWidth: 100, maxWidth: 200, isResizable: true, isPadded: true },
  );

  const selection = useRef(useMemo(() => new Selection({
    onSelectionChanged: () => {
      onSelectionChanged(selection.current)
    },
    getKey: (item: IObjectWithKey) => {
      return (item as IDeliveryNotice).id;
    },
  }), [onSelectionChanged]));

  return (
    <Fabric>
      <Announced message={`Filtro: ${props.searchFilter}`} />
      <div className={detailListContainerStyle}>
        <MarqueeSelection selection={selection.current} isEnabled={false && !isFormDetailOpen} >
          <ShimmeredDetailsList
            items={items}
            columns={columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection.current}
            enableShimmer={itemsAreLoading}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            checkboxVisibility={CheckboxVisibility.hidden}
            onItemInvoked={onItemInvoked}
            selectionMode={SelectionMode.single}
          />
        </MarqueeSelection>
      </div>
    </Fabric >
  );
};


export default DeliveryNoticeDetailsList
