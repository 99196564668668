import React from 'react';
import { ITheme, Label, Panel, Stack, Text } from '@fluentui/react';
import { IDocument } from '../../../containers/Document/types';
import MetadataSet from './MetadataSet';

interface IMetadataPanelProps {
  isOpen: boolean;
  onClose: () => void;
  doc?: IDocument;
  theme: ITheme;
}

const MetadataPanel: React.FunctionComponent<IMetadataPanelProps> = ({ isOpen, onClose, doc, theme }) => {
  return (
    <>
      <Panel
        headerText="Attributi documento"
        isOpen={isOpen}
        onDismiss={() => onClose()}
        closeButtonAriaLabel="Close"
      >
        <Stack tokens={{ childrenGap: 15 }}>
          <div>
            <Label>Nome documento</Label>
            <Text styles={{ root: { color: theme.palette.themePrimary, display: "inline-block", lineHeight: "normal", wordBreak: "break-all" } }}>{doc?.fileNameOriginal}</Text>
          </div>
          <div>
            <Label>Attributi</Label>
            <Stack tokens={{ childrenGap: 20 }}>
              {doc?.contentMetadata && doc.contentMetadata.map((ms, index) => <MetadataSet key={`metaset_${doc.id}_${index}`} items={ms} metaNumber={index + 1} showMetaNumber={doc.contentMetadata.length > 1} theme={theme} />)}
            </Stack>
          </div>
        </Stack>
      </Panel>
    </>);
};

export default MetadataPanel;
