import React from 'react';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';


export interface IDeliveryNoticeCommandBarProp {
  onNewItem: () => void,
  onDeleteItem: () => void,
  refreshClicked: () => void,
  //disabled?: boolean,
  showCreate: boolean,
  showDelete: boolean,
  createDisabled: boolean,
  deleteDisabled: boolean,

};

const DeliveryNoticeCommandBar: React.FC<IDeliveryNoticeCommandBarProp> = (props) => {
  let commandItems: ICommandBarItemProps[] = [];

  props.showCreate === true && commandItems.push(
    {
      key: 'newItem',
      text: 'Nuovo',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      disabled: props.createDisabled,
      onClick: () => {
        props.onNewItem();
      }
    }
  );

  props.showDelete === true && commandItems.push(
    {
      key: 'deleteItem',
      text: 'Elimina',
      iconProps: { iconName: 'Delete' },
      disabled: props.deleteDisabled,
      onClick: () => {
        props.onDeleteItem();
      },
    }
  );

  commandItems.push(
    {
      key: 'refreshItems',
      text: 'Aggiorna',
      iconProps: { iconName: "Sync" },
      onClick: () => { props.refreshClicked() },
    }
  );


  return (
    <CommandBar
      items={commandItems}
      ariaLabel="Use left and right arrow keys to navigate between commands"
    />
  );

}

export default DeliveryNoticeCommandBar;
