import { IStyle, mergeStyleSets, IProcessedStyleSet, ITheme } from '@fluentui/react';
import { desktop } from '../../../shared/mediaQuery';

interface INavigationTopStyles {
  navigationTop: IStyle
}

const navigationTopStyleFactory = (theme: ITheme): IProcessedStyleSet<INavigationTopStyles> => {

  return mergeStyleSets<INavigationTopStyles>({
    navigationTop: {
      backgroundColor: theme.palette.themePrimary,
      boxSizing: "border-box",
      padding: "1.2rem 0.4rem",
      margin: "0",
      color: theme.palette.white,
      display: "none",
      selectors: {
        [desktop]: {
          display: "flex"
        }
      }
    }
  })

}

export default navigationTopStyleFactory;
