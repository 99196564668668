import React from 'react';
import {
  TextField,
  Dropdown,
  IDropdownOption,
  IDatePickerStrings,
  DayOfWeek,
  DatePicker,
  Toggle,
  Label,
  Slider,
  ITheme,
  IconButton,
  IPersonaProps
} from '@fluentui/react';
import { IAreaPickerDefinition, IBooleanDefinition, IDateDefition, IDocumentGroupPickerDefinition, IDocumentTypePickerDefinition, IFileInputDefinition, IInputDefinition, IMultilineDefinition, IMultiSelectDefinition, INumberDefinition, IOptionConfig, IRichTextDefinition, ISelectDefinition, ISliderDefinition, IUserPickerDefinition } from '../../types/Form';
import AreaPicker from '../../containers/AreaPicker/AreaPicker';
import ErrorMessage from '../../components/Form/ErrorMessage/ErrorMessage';
import UserPicker from '../../containers/UserPicker/UserPicker';
import DocumentTypePicker, { IDocumentTypeTag } from '../../containers/DocumentTypePicker/DocumentTypePicker';
import DocumentGroupPicker, { IDocumentGroupPickerTag } from '../../containers/DocumentGroupPicker/DocumentGroupPicker';
import FileDropInput from '../UI/FileDropInput/FileDropInput';
import RichText from '../UI/RichText/RichText';
import { keepInRange } from '../../shared/utility';
import { IFileInputValue } from '../../types/FileInput';
import { IArea } from '../../types/Area';

export function fileElement(definition: IFileInputDefinition, theme: ITheme, changed: (value?: IFileInputValue) => void): JSX.Element {
  return (<div>
    <Label required={definition.validation.required}
      disabled={definition.elementConfig.disabled}>
      {definition.elementConfig.label}
    </Label>
    <FileDropInput
      theme={theme}
      accepts={definition.elementConfig.accepts}
      showFileIcon={definition.elementConfig.showFileIcon}
      showImagePreview={definition.elementConfig.showImagePreview}
      previewMaxHeight={definition.elementConfig.previewMaxHeight}
      value={definition.value}
      onChange={changed}
      disabled={definition.elementConfig.disabled} />
    {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
  </div>);
}

export function documentGroupElement(definition: IDocumentGroupPickerDefinition, theme: ITheme, changed: (newTag: IDocumentGroupPickerTag[]) => void): JSX.Element {
  return (<div>
    <Label required={definition.validation.required}
      disabled={definition.elementConfig.disabled}>
      {definition.elementConfig.label}
    </Label>
    <DocumentGroupPicker
      publishableDocTypeIdFilter={definition.elementConfig.publishableDocTypeIdFilter}
      receivableDocTypeIdFilter={definition.elementConfig.receivableDocTypeIdFilter}
      value={definition.value}
      valueChange={changed}
      maxItemsSelectable={definition.elementConfig.maxItemsSelectable}
      easyClient={definition.elementConfig.easyClient}
      disabled={definition.elementConfig.disabled} />
    {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
  </div>);
}

export function documentTypeElement(definition: IDocumentTypePickerDefinition, theme: ITheme, changed: (newTag: IDocumentTypeTag[]) => void): JSX.Element {
  return (<div>
    <Label required={definition.validation.required}>
      {definition.elementConfig.label}
    </Label>
    <DocumentTypePicker
      value={definition.value}
      valueChange={changed}
      easyClient={definition.elementConfig.easyClient} />
    {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
  </div>);
}

export function userElement(definition: IUserPickerDefinition, theme: ITheme, changed: (selectedUsers: IPersonaProps[]) => void): JSX.Element {
  return (<div>
    <Label required={definition.validation.required}>
      {definition.elementConfig.label}
    </Label>
    <UserPicker
      value={definition.value}
      valueChange={changed}
      easyClient={definition.elementConfig.easyClient} />
    {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
  </div>);
}

export function areaElement(definition: IAreaPickerDefinition, theme: ITheme, changed: (newArea?: IArea | null) => void): JSX.Element {
  return (
    <div>
      <Label required={definition.validation.required}>
        {definition.elementConfig.label}
      </Label>
      <AreaPicker
        value={definition.value}
        areaChanged={changed}
        easyClient={definition.elementConfig.easyClient} />
      {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
    </div>
  );
}

export function sliderElement(definition: ISliderDefinition, changed: (newVal: number) => void): JSX.Element {
  return (
    <Slider
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      onChange={(newValue) => { changed(newValue); }}
      value={keepInRange(definition.value, definition.elementConfig.min, definition.elementConfig.max)}
      min={definition.elementConfig.min}
      max={definition.elementConfig.max}
      step={definition.elementConfig.step}
      disabled={definition.elementConfig.disabled}
      originFromZero={definition.elementConfig.min * definition.elementConfig.max < 0} />
  );
}

export function numberElement(definition: INumberDefinition, changed: (newVal?: string) => void): JSX.Element {
  return (
    <TextField
      required={definition.validation?.required ?? false}
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      placeholder={definition.elementConfig.placeholder}
      onChange={(_, newValue) => { changed(newValue); }}
      value={definition.value}
      type="number"
      min={definition.elementConfig.min}
      max={definition.elementConfig.max}
      step={definition.elementConfig.step}
      errorMessage={definition.errorMessage}
      disabled={definition.elementConfig.disabled} />
  );
}

export function toggleElement(definition: IBooleanDefinition, changed: (newVal?: boolean) => void): JSX.Element {
  return (
    <Toggle
      label={definition.elementConfig.label}
      checked={definition.value}
      onText="Si"
      offText="No"
      onChange={(_, checked) => { changed(checked); }}
      disabled={definition.elementConfig.disabled} />
  );
}

const DayPickerStrings: IDatePickerStrings = {
  months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
  goToToday: 'Oggi',
  prevMonthAriaLabel: 'Mese precedente',
  nextMonthAriaLabel: 'Mese successivo',
  prevYearAriaLabel: 'Anno precedente',
  nextYearAriaLabel: 'Anno successivo',
  closeButtonAriaLabel: 'Chiudi',
  monthPickerHeaderAriaLabel: '{0}, seleziona per cambiare anno',
  yearPickerHeaderAriaLabel: '{0}, seleziona per cambiare mese',
  invalidInputErrorMessage: 'Valore non valido',
  isRequiredErrorMessage: 'Il campo è obbligatorio',
  isOutOfBoundsErrorMessage: 'Valore non ammesso',
};
export function dateElement(definition: IDateDefition, changed: (newVal?: Date | null) => void): JSX.Element {
  return (<DatePicker
    isRequired={definition.validation?.required}
    label={definition.elementConfig.label}
    ariaLabel={definition.elementConfig.label}
    placeholder={definition.elementConfig.placeholder}
    firstDayOfWeek={DayOfWeek.Monday}
    textField={{
      styles: { suffix: { position: "absolute", right: "30px", padding: 0, background: "inherit" } },
      onRenderSuffix: () => <IconButton
        styles={{ root: { height: "30px", width: "30px" } }}
        iconProps={{ iconName: "Clear" }}
        onClick={() => changed(undefined)} />
    }}
    strings={DayPickerStrings}
    formatDate={(date?: Date) => date?.toLocaleDateString() ?? ""}
    onSelectDate={(date) => { changed(date); }}
    value={definition.value}
    disabled={definition.elementConfig.disabled}
    minDate={definition.elementConfig.minValue}
    maxDate={definition.elementConfig.maxValue} />
  );
}

export function selectElement(definition: ISelectDefinition, changed: (newVal?: string) => void) {
  const options = toDropDownOptions(definition.elementConfig.options);
  return (
    <Dropdown
      required={definition.validation?.required}
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      placeholder={definition.elementConfig.placeholder}
      options={options}
      onChange={(_, newValue) => {
        if (!newValue) {
          changed(undefined);
        } else {
          changed(newValue.key as string);
        }
      }}
      selectedKey={definition.value}
      errorMessage={definition.errorMessage}
      disabled={definition.elementConfig.disabled} />
  );
}

export function multiSelectElement(definition: IMultiSelectDefinition, changed: (newVal: string[]) => void) {
  const options = toDropDownOptions(definition.elementConfig.options);
  return (
    <Dropdown
      required={definition.validation?.required}
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      placeholder={definition.elementConfig.placeholder}
      options={options}
      onChange={(_, newValue) => {
        if (!newValue) {
          return
        }
        const copyOfValue = [...definition.value];
        if (newValue.selected) {
          copyOfValue.push(newValue.key as string);
        } else {
          const index = copyOfValue.indexOf(newValue.key as string);
          copyOfValue.splice(index, 1);
        }
        changed(copyOfValue);
      }}
      selectedKeys={definition.value}
      multiSelect
      errorMessage={definition.errorMessage}
      disabled={definition.elementConfig.disabled} />
  );
}

function toDropDownOptions(selectOptions: IOptionConfig[]) {
  return selectOptions.map<IDropdownOption>((opt: IOptionConfig) => ({
    key: opt.option,
    text: opt.text
  }));
}

export function multilineElement(definition: IMultilineDefinition, changed: (newVal?: string) => void): JSX.Element {
  return (
    <TextField
      required={definition.validation?.required}
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      placeholder={definition.elementConfig.placeholder}
      multiline
      autoAdjustHeight
      onChange={(_, newValue) => { changed(newValue); }}
      value={definition.value}
      errorMessage={definition.errorMessage}
      disabled={definition.elementConfig.disabled} />
  );
}

export function inputElement(definition: IInputDefinition, changed: (newVal?: string) => void): JSX.Element {
  return (
    <TextField
      required={definition.validation?.required}
      label={definition.elementConfig.label}
      ariaLabel={definition.elementConfig.label}
      placeholder={definition.elementConfig.placeholder}
      onChange={(_, newValue) => { changed(newValue); }}
      value={definition.value}
      errorMessage={definition.errorMessage}
      readOnly={definition.elementConfig.readOnly}
      suffix={definition.elementConfig.suffix}
      disabled={definition.elementConfig.disabled} />
  );
}

export function richTextElement(definition: IRichTextDefinition, theme: ITheme, changed: (value?: string) => void): JSX.Element {
  return (
    <div>
      <Label required={definition.validation.required}
        disabled={definition.elementConfig.disabled}>
        {definition.elementConfig.label}
      </Label>
      <RichText
        theme={theme}
        isEditMode={!definition.elementConfig.disabled}
        placeholder={definition.elementConfig.placeholder}
        value={definition.value}
        onChange={(htmlText) => { changed(htmlText); return htmlText; }}
        styleOptions={{
          showStyles: true,
          showBold: true,
          showItalic: true,
          showUnderline: true,
          showAlign: true,
          showList: true,
          showLink: true,
        }} />
      {definition.errorMessage && <ErrorMessage theme={theme} errorMessage={definition.errorMessage} />}
    </div>
  );
}
