import React from 'react'
import { AnimationClassNames, DelayedRender, IProcessedStyleSet, IStyle, ITheme, mergeStyleSets } from '@fluentui/react';

interface IAreaPickerStyles {
  errorMessage: IStyle;
}

const getStyles = (theme: ITheme): IProcessedStyleSet<IAreaPickerStyles> => {
  const { fonts, semanticColors } = theme;
  return mergeStyleSets<IAreaPickerStyles>({
    errorMessage: [
      "ms-TextField-errorMessage",
      AnimationClassNames.slideDownIn20,
      fonts.small,
      {
        color: semanticColors.errorText,
        margin: 0,
        paddingTop: 5,
        display: 'flex',
        alignItems: 'center',
      },
    ]
  })
}

interface IErrorMessageProps {
  errorMessage: string;
  theme: ITheme
}

const ErrorMessage: React.FC<IErrorMessageProps> = (props) => {

  const classNames = getStyles(props.theme);
  return (<div role="alert">
    <DelayedRender>
      <p className={classNames.errorMessage}>
        <span data-automation-id="error-message">{props.errorMessage}</span>
      </p>
    </DelayedRender>
  </div>);

}

export default ErrorMessage;
