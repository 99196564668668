import React from 'react';

import { ActionButton, ProgressIndicator } from '@fluentui/react';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import { LoginStatus } from '../../hooks/UseLogin/types';

import useLogin from '../../hooks/UseLogin';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (store: IEasyStore) => {
  return {
    theme: store.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

const Login: React.FC<PropsFromStore> = (props) => {

  const { signIn, status, userInfo } = useLogin();

  const loginHanlder = () => {
    signIn()
  }

  if (!userInfo.isAuthenticated) {
    let action = <ProgressIndicator label="Attendere" />;
    if (status === LoginStatus.inactive)
      action = (<ActionButton iconProps={{ iconName: "Unlock" }} onClick={loginHanlder}>
        Login
      </ActionButton>)
    return (<SplashScreen theme={props.theme}>
      {action}
    </SplashScreen>)
  }

  return <>{props.children}</>
}

export default connector(Login);
