import React from 'react';
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode, Icon, Text, ITheme } from '@fluentui/react';
import { IProvisionListItem } from '../../../containers/ProvisionReport/types';

interface IProvisionListProp {
  items: IProvisionListItem[];
  onSelected: (item: IProvisionListItem) => void;
  theme: ITheme;
}

const columns: IColumn[] = [
  { key: 'status', name: 'Campionato', fieldName: 'status', minWidth: 100 },
  { key: 'ddt', name: 'DDT', fieldName: 'ddt', minWidth: 100 },
  { key: 'bem', name: 'BEM', fieldName: 'bem', minWidth: 100 },
  { key: 'date', name: 'del', fieldName: 'date', minWidth: 100 },
  { key: 'parcel', name: 'Colli', fieldName: 'parcel', minWidth: 100 },
  { key: 'weight', name: 'Netto(Kg)', fieldName: 'weight', minWidth: 100 },
]

export const ProvisionList: React.FC<IProvisionListProp> = ({ items, onSelected, theme }) => {

  const renderItemColumn = (item?: IProvisionListItem, index?: number | undefined, column?: IColumn | undefined) => {
    if (!item || !column)
      return;
    const fieldContent = item[column.fieldName as keyof IProvisionListItem];

    switch (column.key) {
      case 'status':
        return (<div style={{ textAlign: "center" }}>
          {fieldContent === "sampled" ? <Text variant="xLarge" color={theme.palette.green}><Icon iconName="Accept" /></Text> : null}
        </div>)
      case "date":
        return <span>{(fieldContent as Date).toLocaleDateString()}</span>;

      default:
        return <span>{fieldContent?.toString()}</span>;
    }
  }

  return (
    <DetailsList
      items={items}
      layoutMode={DetailsListLayoutMode.fixedColumns}
      onRenderItemColumn={renderItemColumn}
      selectionMode={SelectionMode.none}
      onItemInvoked={onSelected}
      columns={columns}
    />
  )
}

