import React from 'react';
import { CommandBar, ICommandBarItemProps, IContextualMenuItem } from '@fluentui/react';


export enum ReadStatusFilterView {
  AllItems = "AllItems",
  OnlyUnread = "OnlyUnread",
  OnlyPartiallyRead = "OnlyPartiallyRead",
  OnlyFullyRead = "OnlyFullyRead",
}

const getReadStatusFilterViewText: (value: ReadStatusFilterView) => string = (value) => {
  switch (value) {
    case ReadStatusFilterView.AllItems: return "Tutti gli elementi";
    case ReadStatusFilterView.OnlyUnread: return "Non acquisiti";
    case ReadStatusFilterView.OnlyPartiallyRead: return "Parzialmente acquisiti";
    case ReadStatusFilterView.OnlyFullyRead: return "Acquisiti";
  }
};

export interface IDocumentCommandBarProp {
  newItemClicked: () => void,
  //deleteItemClicked: () => void,
  refreshClicked: () => void,
  readStatusFilterChanged: (statusFilter: ReadStatusFilterView) => void,
  disabled?: boolean,
  //canDelete?: boolean,
  filterClick: () => void;
  clearFilters: () => void;
  filtersApplied: boolean;
  currentView: ReadStatusFilterView,
  publishDisabled?: boolean,
};

const DocumentCommandBar: React.FC<IDocumentCommandBarProp> = (props) => {


  const _items: ICommandBarItemProps[] = [
    {
      key: 'refreshItems',
      text: 'Aggiorna',
      iconProps: { iconName: "Sync" },
      onClick: () => { props.refreshClicked() },
    },
    {
      key: 'newItem',
      text: 'Pubblica',
      iconProps: { iconName: 'Add' },
      disabled: props.disabled || props.publishDisabled || false,
      onClick: props.newItemClicked
    },
    // {
    //   key: 'deleteItem',
    //   text: 'Elimina',
    //   iconProps: { iconName: 'Delete' },
    //   disabled: props.disabled || !props.canDelete || false,
    //   onClick: props.deleteItemClicked,
    // }
    {
      key: "filter",
      text: props.filtersApplied ? "Cambia filtri" : "Filtra",
      iconProps: {
        iconName: props.filtersApplied ? "FilterSolid" : "Filter"
      },
      onClick: props.filterClick
    }
  ];

  if (props.filtersApplied) {
    _items.push({
      key: "clearFilters",
      iconOnly: true,
      iconProps: { iconName: "ClearFilter" },
      onClick: props.clearFilters
    });
  }

  const _farItems: ICommandBarItemProps[] = [
    {
      key: 'readStatusFilter',
      text: getReadStatusFilterViewText(props.currentView),
      iconProps: { iconName: 'List' },
      subMenuProps: {
        items: [ReadStatusFilterView.AllItems,
        ReadStatusFilterView.OnlyUnread,
        ReadStatusFilterView.OnlyPartiallyRead,
        ReadStatusFilterView.OnlyFullyRead].map(f => {
          return {
            key: f.toString(),
            canCheck: true,
            checked: props.currentView === f,
            text: getReadStatusFilterViewText(f),
            onClick: () => { props.readStatusFilterChanged(f) }
          } as IContextualMenuItem;
        })
      }
    }
  ];

  return (
    <CommandBar
      items={_items}
      farItems={_farItems}
    />
  );

}

export default DocumentCommandBar;
