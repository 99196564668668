import * as React from 'react';
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import EasyForm from '../../Form/Form';
import { ITheme } from 'office-ui-fabric-react';
import { IDeliveryNoticeFormDefinition } from '../../../types/DeliveryNotice';

interface IDeliveryNoticeDetailFormProp {
  isOpen: boolean,
  onClose: () => void;
  formDefinition: IDeliveryNoticeFormDefinition;
  onFormChange: (updatedForm: IDeliveryNoticeFormDefinition, isValid: boolean) => void;
  onFormSubmit: () => void;
  isSubmitEnabled: boolean;
  theme: ITheme;
}

const DeliveryNoticeDetailForm: React.FC<IDeliveryNoticeDetailFormProp> = ({
  formDefinition,
  isOpen,
  onClose,
  onFormChange,
  onFormSubmit,
  isSubmitEnabled,
  theme
}) => {

  return (
    <>
      <Panel
        headerText="Prenotazione"
        isOpen={isOpen}
        onDismiss={() => onClose()}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <EasyForm
          theme={theme}
          formDefinition={formDefinition}
          onChange={onFormChange}
          onSubmit={onFormSubmit}
          submitEnabled={isSubmitEnabled}
        //isSubmitting={isSubmitting}
        />
      </Panel>
    </>
  );
};

export default DeliveryNoticeDetailForm;
