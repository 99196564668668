// For a full list of msal.js configuration parameters, 
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

import { updateObject } from "./utility";
import { Configuration } from "@azure/msal-browser";
import { BrowserAuthOptions } from "@azure/msal-browser/dist/src/config/Configuration";


const defaultConfig: Configuration = {
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const getLoginConfig = (auth: BrowserAuthOptions): Configuration => {
    return updateObject(defaultConfig, {
        auth: auth
    })
}