import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { acquireToken } from './azureLogin';
import { AuthenticationResult } from '@azure/msal-browser';

let scopes = ["api://706035be-3437-4ea8-974b-74e177791c3e/access_as_user"];
export const getScopes = () => scopes;

const axiosInstance: AxiosInstance = axios.create();

export const replaceAuth: (
  config: AxiosRequestConfig,
  tk: AuthenticationResult) => AxiosRequestConfig =
  (config: AxiosRequestConfig, tk: AuthenticationResult) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `${tk?.tokenType} ${tk?.accessToken}`,
      }
    };
  }

const usePreferReturnRepresntation: (config: AxiosRequestConfig) => AxiosRequestConfig =
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Prefer: "return=representation", // consente di ottenere in risposta l'oggetto aggiornato
      }
    }
  }

export interface IHttpClient {
  get: <T>(url: string, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => Promise<AxiosResponse<T>>;
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => Promise<AxiosResponse<T>>
  patch: <T>(url: string, data?: any, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => Promise<AxiosResponse<T>>
  delete: (url: string, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => Promise<AxiosResponse>
  getInstance: () => AxiosInstance;
  setBaseUrl: (newBeseUrl: string) => void;
  getScopes: () => string[],
  setAppId: (appId: string) => void
}

const getAcquireToken: (authResult?: AuthenticationResult) => Promise<AuthenticationResult> =
  (authResult) => {
    return new Promise((resolve, reject) => {
      if (!authResult) {
        acquireToken(scopes).then(tk => {
          resolve(tk);
        }).catch(error => {
          reject(error);
        });
      } else {
        resolve(authResult)
      }
    })
  }

const requestWrapper: <T>(
  request: (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>,
  config?: AxiosRequestConfig,
  authResult?: AuthenticationResult) => Promise<AxiosResponse<T>> =
  (request, config, authResult) => {
    return getAcquireToken(authResult).then(tk => {
      const configWithAuth = replaceAuth(config || {}, tk);
      return request(configWithAuth);
    })
  }

const easyClient: IHttpClient = {
  get: <T>(url: string, config?: AxiosRequestConfig, authResult?: AuthenticationResult): Promise<AxiosResponse<T>> => {
    return requestWrapper((configWithAuth) => {
      return axiosInstance.get<T>(url, configWithAuth);
    }, config, authResult);
  },
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return acquireToken(scopes).then(tk => {
      if (!tk) {
        throw Error("errore nella acquisizione del token");
      }
      const configWithAuth = replaceAuth(config || {}, tk);
      return axiosInstance.post<T>(url, data, configWithAuth);
    })
  },
  patch: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return acquireToken(scopes).then(tk => {
      if (!tk) {
        throw Error("errore nella acquisizione del token");
      }
      const configWithPrefer = usePreferReturnRepresntation(config || {}); // consente di ottenere in risposta l'oggetto aggiornato
      const configWithAuth = replaceAuth(configWithPrefer || {}, tk);
      return axiosInstance.patch<T>(url, data, configWithAuth);
    })
  },
  delete: (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return acquireToken(scopes).then(tk => {
      if (!tk) {
        throw Error("errore nella acquisizione del token");
      }
      const configWithAuth = replaceAuth(config || {}, tk);
      return axiosInstance.delete(url, configWithAuth);
    })
  },
  getInstance: () => axiosInstance,
  setBaseUrl: (newBeseUrl: string) => { axiosInstance.defaults.baseURL = newBeseUrl; },
  setAppId: (appId: string) => { scopes = [`api://${appId}/access_as_user`]; },
  getScopes: () => scopes
}

export default easyClient;
