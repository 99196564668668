import { ITheme, Stack } from '@fluentui/react';
import React, { FormEvent } from 'react';
import { checkArrayValidity, checkNumberValidity, checkObjectValidity, checkStringValidity, updateObject } from '../../shared/utility';
import { FormDefinition, FormDefinitions, IValidationResult } from '../../types/Form';
import Button from '../UI/Button/Button';
import { areaElement, dateElement, documentGroupElement, documentTypeElement, fileElement, inputElement, multilineElement, multiSelectElement, numberElement, richTextElement, selectElement, sliderElement, toggleElement, userElement } from './Form.FieldElements';

export interface IEasyFormProps<T extends FormDefinitions> {
  formDefinition: T
  onChange: (updatedForm: T, isValid: boolean) => void;
  onSubmit: (updatedForm: T, isValid: boolean) => void;
  submitEnabled: boolean;
  theme: ITheme;
}

const formStackToken = {
  sectionStack: {
    childrenGap: 10,
  }
};

export function areAllTouchedValid<T extends FormDefinitions>(updatedForm: T) {
  return !Object.keys(updatedForm)
    .find(key => updatedForm[key].touched && !updatedForm[key].valid);
}


//export const updateFormFieldValue = <T extends FormDefinitions, NewValue extends string>(currForm: T, inputIdentifier: keyof T, newValue: NewValue): { updatedForm: T, formIsValid: boolean } => {
//  const def = currForm[inputIdentifier] as FormDefinition;
//  switch (def.elementType) {
//    case "richText":
//      checkStringValidity(newValue, { required: def.validation.required, isHtml: true });
//
//  }
//  const { isValid, errorMessage } = checkValidity(newValue, currForm[inputIdentifier].validation);
//  const updatedFormElement = updateObject(currForm[inputIdentifier], {
//    value: newValue,
//    valid: isValid,
//    errorMessage: errorMessage,
//    touched: true
//  });
//  // const updatedForm = {
//  //   ...currForm,
//  //   ...{ [inputIdentifier]: updatedFormElement }
//  // } as T;
//  const updatedForm = updateObject(currForm, {
//    [inputIdentifier]: updatedFormElement
//  });
//
//  const formIsValid = areAllTouchedValid(updatedForm);
//  return { updatedForm: updatedForm, formIsValid: formIsValid }
//}

export const checkWholeFormValidity = <T extends FormDefinitions>(formDefinition: T, setAllFieldsAsTouched: boolean) => {
  const keys: (keyof T)[] = Object.keys(formDefinition);
  const updatedForm = keys
    .reduce<T>((agg, key) => {
      const formElement = formDefinition[key];
      if ((setAllFieldsAsTouched === true) || formElement.touched) {
        const { valid, errorMessage } = validate(formElement);
        agg[key] = updateObject(formElement, {
          valid: valid,
          errorMessage: errorMessage,
          touched: true,
        });
      } else {
        agg[key] = formElement;
      }
      return agg;
    }, {} as T);

  const formIsValid = areAllTouchedValid(updatedForm);

  return { updatedForm: updatedForm, formIsValid: formIsValid };
}

const EasyForm = <T extends FormDefinitions>(props: IEasyFormProps<T>) => {
  const { formDefinition, onChange, onSubmit, submitEnabled, theme } = props;
  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { updatedForm, formIsValid } = checkWholeFormValidity(formDefinition, true);
    onChange(updatedForm, formIsValid);
    if (formIsValid) {
      onSubmit(updatedForm, formIsValid);
    }
  }

  const inputChangedHandler = <TNewValue,>(inputIdentifier: keyof T, newValue: TNewValue) => {
    const field = formDefinition[inputIdentifier];
    const newField = updateObject(field, {
      value: newValue,
    });
    const newFormDefinition = replaceFieldAndValidate<T>(newField, formDefinition, inputIdentifier);
    onChange(newFormDefinition, areAllTouchedValid(newFormDefinition));
  }

  return (<form onSubmit={submitHandler} noValidate>
    <Stack tokens={formStackToken.sectionStack}>
      {getAllFieldsStacked(formDefinition, inputChangedHandler, theme)}
      <Stack.Item>
        <Button btnType="primary" disabled={!submitEnabled}>Salva</Button>
      </Stack.Item>
    </Stack>
  </form>);
}

export default EasyForm;

export function replaceFieldAndValidate<T extends FormDefinitions>(newField: T[keyof T], formDefinition: T, inputIdentifier: keyof T) {
  newField.touched = true;
  const validationResult = validate(newField);
  newField.valid = validationResult.valid;
  newField.errorMessage = validationResult.errorMessage;
  const newFormDefinition = updateObject(formDefinition as FormDefinitions, { [inputIdentifier]: newField }) as T;
  return newFormDefinition;
}

function getAllFieldsStacked(formDefinition: FormDefinitions, inputChangedHandler: <T>(inputIdentifier: string, newValue: T) => void, theme: ITheme): string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined {
  return Object.keys(formDefinition).map(key => (
    <Stack.Item key={key}>
      {toFieldElement(formDefinition[key], inputChangedHandler, key, theme)}
    </Stack.Item>));
}

function toFieldElement(def: FormDefinition, inputChangedHandler: <T>(inputIdentifier: string, newValue: T) => void, key: string, theme: ITheme) {
  switch (def.elementType) {
    case "input": return inputElement(def, newVal => inputChangedHandler(key, newVal));
    case "multiline": return multilineElement(def, newVal => inputChangedHandler(key, newVal));
    case "multiselect": return multiSelectElement(def, newVal => inputChangedHandler(key, newVal));
    case "select": return selectElement(def, newVal => inputChangedHandler(key, newVal));
    case "date": return dateElement(def, newVal => inputChangedHandler(key, newVal));
    case "boolean": return toggleElement(def, newVal => inputChangedHandler(key, newVal));
    case "number": return numberElement(def, newVal => inputChangedHandler(key, newVal));
    case "slider": return sliderElement(def, newVal => inputChangedHandler(key, newVal));
    case "areaPicker": return areaElement(def, theme, newVal => inputChangedHandler(key, newVal));
    case "userPicker": return userElement(def, theme, newVal => inputChangedHandler(key, newVal));
    case "documentTypePicker": return documentTypeElement(def, theme, newVal => inputChangedHandler(key, newVal));
    case "documentGroupPicker": return documentGroupElement(def, theme, newVal => inputChangedHandler(key, newVal));
    case "fileInput": return fileElement(def, theme, newVal => inputChangedHandler(key, newVal));
    case "richText": return richTextElement(def, theme, newVal => inputChangedHandler(key, newVal));
    default: return <div>Invalid element type</div>;
  }
}

function validate(def: FormDefinition): IValidationResult {
  switch (def.elementType) {
    case "multiline": return checkStringValidity(def.value, { isHtml: false, isEmail: false, required: def.validation?.required, maxLength: def.validation?.maxLength, minLength: def.validation?.minLength });
    case "select": return checkStringValidity(def.value, { isHtml: false, isEmail: false, required: def.validation?.required, minLength: undefined, maxLength: undefined });
    case "input": return checkStringValidity(def.value, { isHtml: false, isEmail: def.validation?.isEmail, required: def.validation?.required, maxLength: def.validation?.maxLength, minLength: def.validation?.minLength });
    case "richText": return checkStringValidity(def.value, { isEmail: false, required: def.validation?.required ?? false, isHtml: true, maxLength: undefined, minLength: undefined })
    case "number": return checkNumberValidity(def.value, { max: def.validation.max, min: def.validation.min, required: def.validation.required });
    case "boolean":
    case "slider": return { valid: true };
    case "date": return checkObjectValidity(def.value, def.validation.required);
    case "areaPicker": return checkObjectValidity(def.value, def.validation.required ?? false);
    case "userPicker": return checkObjectValidity(def.value, def.validation.required ?? false);
    case "fileInput": return checkObjectValidity(def.value, def.validation.required);
    case "multiselect": return checkArrayValidity(def.value, { required: def.validation.required, maxItems: undefined, minItems: undefined });
    case "documentTypePicker": return checkArrayValidity(def.value, { required: def.validation.required, minItems: undefined, maxItems: undefined });
    case "documentGroupPicker": return checkArrayValidity(def.value, { required: def.validation.required, maxItems: undefined, minItems: undefined });
    default: throw new Error("Invalid type");
  }
}
