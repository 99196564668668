import React, { } from 'react';
import { Modal, ProgressIndicator, Spinner, Stack } from '@fluentui/react';
//import Backdrop from '../Backdrop/Backdrop';
import getStyles from './LoadingModal.style';

export enum LoadingModalMode {
  Spinner,
  Progress,
};

interface ILoadingModalProps {
  mode?: LoadingModalMode;
  show: boolean;
  message?: string;
  /**
  * Percentage of the operation's completeness, numerically between 0 and 1. If this is not set,
  * the indeterminate progress animation will be shown instead.
  */
  percentComplete?: number;
};

const LoadingModal: React.FunctionComponent<ILoadingModalProps> = ({
  mode,
  percentComplete,
  show, message }) => {
  const styles = getStyles();

  mode = mode !== undefined ? mode : LoadingModalMode.Spinner;
  message = message !== undefined ? message : "Caricamento...";

  return (
    <Modal isOpen={show}
      isBlocking={true}
      styles={styles.modal}
      allowTouchBodyScroll={true}
    >
      <Stack>
        <Stack.Item styles={{ root: { display: "flex", justifyContent: "end" } }}>
          <br />
        </Stack.Item>
        <Stack.Item styles={{ root: { padding: "2em" } }}>

          {show && mode === LoadingModalMode.Spinner && (
            <Spinner styles={styles.spinner} label={message} />
          )}
          {show && mode === LoadingModalMode.Progress && (
            <ProgressIndicator styles={styles.progress} label={message} percentComplete={percentComplete} />
          )}
        </Stack.Item>
      </Stack>
    </Modal>

  );
};

export default LoadingModal;
