import { ITheme } from 'office-ui-fabric-react';
import React from 'react'
import MainTitle from '../../components/UI/MainTitle/MainTitle'
import stylesFactory from './Page.styles';

interface IPageProps {
  title: string;
  iconName: string;
  pageWidth: string;
  theme: ITheme;
}

const Page: React.FC<IPageProps> = (props) => {
  const style = stylesFactory(props.pageWidth, props.theme);
  return (
    <>
      <div className={style.page}>
        <MainTitle theme={props.theme} title={props.title} iconName={props.iconName}/>
        {props.children}
      </div>
    </>
  );
}

export default Page;
