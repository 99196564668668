import { useCallback, useEffect, useState } from 'react';
import { LoginStatus } from "./types";
import { getMsalApp } from '../../shared/azureLogin';
import { scopes as graphScopes } from '../../shared/graphClient';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { getScopes } from '../../shared/easyAxios';

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  return msie || msie11;
};

const useRedirectFlow = isIE();

const useLogin = () => {

  const [userInfo, setUserInfo] = useState<{isAuthenticated: boolean, username: string, account?: AccountInfo | null}>({ isAuthenticated: false, username: "" });
  const [, setLoginError] = useState({ errorMessage: "" });
  const [status, setStatus] = useState(LoginStatus.lookingForAccounts);
  const msalApp = getMsalApp();

  const getAccounts = useCallback(() => {
    setStatus(LoginStatus.lookingForAccounts);
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    const currentAccounts = msalApp.getAllAccounts();
    if (currentAccounts === null || currentAccounts.length === 0) {
      setStatus(LoginStatus.inactive);
      setUserInfo({
        isAuthenticated: false,
        username: "",
        account: null
      });
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
      // Add choose account code here
      setUserInfo({
        username: currentAccounts[0].username,
        account: msalApp.getAccountByUsername(currentAccounts[0].username),
        isAuthenticated: true
      });
    } else if (currentAccounts.length === 1) {
      setUserInfo({
        username: currentAccounts[0].username,
        account: msalApp.getAccountByUsername(currentAccounts[0].username),
        isAuthenticated: true
      });
    }
  }, [msalApp]);

  const handleResponse = useCallback((response: any) => {
    if (response !== null) {
      setStatus(LoginStatus.inactive);
      setUserInfo({
        account: response.account,
        username: response.account.username,
        isAuthenticated: true
      });
    } else {
      getAccounts();
    }
  }, [getAccounts])

  const signIn = async (redirect?: RedirectRequest) => {
    if (redirect) {
      setStatus(LoginStatus.redirectLogin);
      return msalApp.loginRedirect({ scopes: getScopes(), extraScopesToConsent: graphScopes });
    }
    setStatus(LoginStatus.popupLogin);
    return msalApp.loginPopup({ scopes: getScopes(), extraScopesToConsent: graphScopes })
      .then(handleResponse)
      .catch(err => {
        setLoginError({ errorMessage: err.errorMessage });
        setStatus(LoginStatus.inactive);
      });
  }

  const signOut = async () => {
    return msalApp.logout();
  }

  useEffect(() => {
    if (msalApp && !userInfo.isAuthenticated) {
      if (useRedirectFlow) {
        msalApp.handleRedirectPromise()
          .then(handleResponse)
          .catch(err => {
            setLoginError({ errorMessage: err.errorMessage });
            console.error(err);
          });
      }
      getAccounts();
    }
  }, [userInfo.isAuthenticated, getAccounts, handleResponse, msalApp]);

  return {
    userInfo: userInfo,
    signIn: signIn,
    status: status,
    signOut: signOut
  };

}

export default useLogin;
