import { IUser, UsersFormDefinition } from '../../types/User'

export const createFormDefinition = (user: IUser): UsersFormDefinition => {
  return {
    "username": {
      elementType: "input",
      elementConfig: {
        label: 'Username'
      },
      value: user.username ?? "",
      validation: {
        required: true
      }
    },
    "name": {
      elementType: "input",
      elementConfig: {
        label: 'Nome'
      },
      value: user.name ?? "",
      validation: {
        required: true
      }
    },
    "email": {
      elementType: "input",
      elementConfig: {
        label: 'Email',
      },
      value: user.email ?? "",
      validation: {
        required: false,
        isEmail: true
      }
    },
    "roles": {
      elementType: "multiselect",
      elementConfig: {
        options: [{
          option: "Admin",
          text: "Amministratore 🌟"
        }, {
          option: "Owner",
          text: "Proprietario"
        }, {
          option: "DocumentManager",
          text: "Gestore di Documenti"
        }],
        label: 'Ruolo'
      },
      value: user.roles ?? [],
      validation: {
        required: false
      }
    },
    notificationsEnabled: {
      elementType: "boolean",
      elementConfig: {
        label: "Notifiche attive"
      },
      value: user.notificationsEnabled ?? false
    }
  }
}


