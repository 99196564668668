import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { watchConfig, watchContext } from "./sagas";

import contextReducer from "./reducers/context";
import configReducer from './reducers/config';
import { IConfigState, IContextState } from "./types/context";

export interface IEasyStore{
  config: IConfigState;
  context: IContextState;
}

const composeEnhancers = composeWithDevTools({});

const rootReducer = combineReducers({
  config: configReducer,
  context: contextReducer
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchConfig);
sagaMiddleware.run(watchContext);
