import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Announcements from '../../components/Announcements/Announcements';
import Carousel from '../../components/Carousel/Carousel';

import LinkList from '../../components/LinkList/LinkList';
import easyClient from '../../shared/easyAxios';
import { IEasyStore } from '../../store/store';
import NewsBoard from '../News/NewsBoard/NewsBoard';

import styleFactory from './Home.style';

const mapStateToProps = (store: IEasyStore) => {
  return {
    theme: store.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

const Home: React.FC<PropsFromStore> = (props) => {
  const style = styleFactory();
  return (
    <React.Fragment>
      <Carousel theme={props.theme} />
      <div className={style.centerLayout}>
        <div className={style.centerItem}>
          <NewsBoard easyClient={easyClient} theme={props.theme} />
        </div>
        <div className={style.centerItem}>
          {/* <Announcements /> */}
          <LinkList />
        </div>
      </div>
    </React.Fragment>
  )
}

export default connector(Home);
