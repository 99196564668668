import React from 'react';
import { getTheme, Stack } from '@fluentui/react';

import Logo, { LogoType } from '../../Logo/Logo';
import stylesFactory from './Footer.style';
import { ICompanyData } from '../../../store/types/context';


export interface IFooter {
  logoClicked?: () => void;
  companyData?: ICompanyData;
}

const Footer: React.FC<IFooter> = ({ companyData, logoClicked }) => {

  const theme = getTheme();
  const styles = stylesFactory(theme);

  const companyFooter=[];
  if(companyData?.name){
    companyFooter.push(companyData.name)
  }
  if(companyData?.address){
    companyFooter.push(companyData.address)
  }

  return (
    <>
      <Stack horizontal styles={styles.footer} verticalAlign="center">
        <Stack.Item grow={false} styles={styles.logo}>
          <Logo onClick={logoClicked} logoType={LogoType.EasyCollaboration} />
        </Stack.Item>
        <Stack.Item grow styles={styles.content}>
          {companyFooter.join(" - ")}
        </Stack.Item>
      </Stack>
    </>
  );
}

export default Footer;
