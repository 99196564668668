import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import withEasyHttp from '../../hoc/HttpClientWrapper/HttpClientWrapper';
import { useQuery } from '../../hooks/UseQuery/useQuery';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { IHttpClient } from '../../shared/easyAxios';
import { IDocument } from './types';
import { IODataList } from '../../models/odata';
import { ContextualMenu, IContextualMenuProps, IStackStyles, Stack } from '@fluentui/react';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import { desktop } from '../../shared/mediaQuery';
import EasyDocumentCard, { EasyDocumentCardMode } from '../../components/Document/Card/EasyDocumentCard';
import CommandBar, { ReadStatusFilterView } from '../../components/Document/CommandBar/CommandBar';
import MetadataPanel from '../../components/Document/Metadata/MetadataPanel';
import LoadingModal, { LoadingModalMode } from '../../components/UI/LoadingModal/LoadingModal';
import Page from '../../hoc/Page/Page';
import NoData from '../../components/NoData/NoData';
import AdvanceFilterPanel from '../../components/Document/AdvanceFilterPanel/AdvanceFilterPanel';
import DocumentPublisher from './DocumentPublisher';
import { onlyUnique } from '../../shared/utility';
import { IDocumentType, IGroup, UserRole } from '../../store/types/context';
import { pathForDocuments } from './route';
import { IAdvanceFilter, IAdvanceFilterFull } from '../../types/Document';

const mapStateToProps = (state: IEasyStore) => {
  return {
    searchFilter: state.context.globalSearchExpression,
    documentTypes: state.context.documentType,
    userGroups: state.context.groups,
    userRoles: state.context.roles,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>

const stackStyles: IStackStyles = {
  root: {
    display: "grid",
    gridGap: "0.2rem",
    gridTemplateColumns: "repeat(auto-fill, 1fr)",
    selectors: {
      [desktop]: {
        gridTemplateColumns: "repeat(auto-fill, minmax(24rem, 1fr))",
        gridGap: "0.5rem",
      }
    },
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
};

const userRolesActAsPublisher = [UserRole.Admin];

export const getPublishableDocTypesForCurrUser: (userDocTypes: IDocumentType[], userGroups: IGroup[]) => IDocumentType[] =
  (userDocTypes, userGroups) => {
    const pubDocTypeIds = userGroups.flatMap(g => g.publishableDocTypeIds).filter(onlyUnique);
    return userDocTypes.filter(dt => pubDocTypeIds.indexOf(dt.id) >= 0)
  };

export interface IDocumentPathProps {
  docTypeId: string;
}

export interface IDocumentProps extends RouteComponentProps<IDocumentPathProps>, PropsFromStore {
  easyClient: IHttpClient;
  searchFilter: string;
  documentTypes: IDocumentType[];
  userGroups: IGroup[];
  userRoles: UserRole[];
}

function buildFilterExpression(routeParam: IDocumentPathProps, advanceFilters: IAdvanceFilterFull): string {

  const filter: string[] = [];
  const metadataFilter: string[] = [];
  const recipientsFilter: string[] = [];
  if (routeParam.docTypeId) {
    filter.push(`documentTypeId eq '${routeParam.docTypeId}'`)
  }
  if (advanceFilters.id) {
    filter.push(`id eq '${advanceFilters.id}'`)
  }
  if (advanceFilters.fromDateAcquired) {
    recipientsFilter.push(`r/acquired/timestamp ge ${new Date(advanceFilters.fromDateAcquired).toISOString()}`)
  }
  if (advanceFilters.toDateAcquired) {
    recipientsFilter.push(`r/acquired/timestamp lt ${toDatePlusOneDay(advanceFilters.toDateAcquired).toISOString()}`)
  }
  if (advanceFilters.fromRefDate) {
    metadataFilter.push(`m/ref_date ge ${new Date(advanceFilters.fromRefDate).toISOString()}`)
  }
  if (advanceFilters.toRefDate) {
    metadataFilter.push(`m/ref_date lt ${toDatePlusOneDay(advanceFilters.toRefDate).toISOString()}`)
  }
  if (advanceFilters.fromDatePublish) {
    filter.push(`publishedTimestamp ge ${new Date(advanceFilters.fromDatePublish).toISOString()}`)
  }
  if (advanceFilters.toDatePublish) {
    filter.push(`publishedTimestamp lt ${toDatePlusOneDay(advanceFilters.toDatePublish).toISOString()}`)
  }

  let filterExpression = "";
  if (filter.length) {
    filterExpression = filter.join(" and ");
  }
  if (metadataFilter.length) {
    const metdataExpression = `contentMetadata/any(m:${metadataFilter.join(" and ")})`;
    if (filterExpression) { filterExpression = filterExpression + " and "; }
    filterExpression = filterExpression + metdataExpression;
  }
  if (recipientsFilter.length) {
    const recipientsExpression = `recipients/any(r:${recipientsFilter.join(" and ")})`;
    if (filterExpression) { filterExpression = filterExpression + " and "; }
    filterExpression = filterExpression + recipientsExpression;
  }
  return filterExpression;
}

export const Document: React.FC<IDocumentProps> = ({ theme, match, history, easyClient, searchFilter, documentTypes, userGroups, userRoles }) => {

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [itemsAreLoading, setItemsAreLoading] = useState<boolean>(true);
  const [itemIsDowloading, setItemIsDownloading] = useState<boolean>(false);
  const [currPage, setCurrPage] = useState<number>(1);
  const [totCount, setTotCount] = useState<number>(0);
  const [isMetadataPanelOpen, setIsMetmetadataPanelOpen] = useState<boolean>(false);
  const [currentDocument, setCurrentDocument] = useState<IDocument>();
  const [isCardContextualMenuShown, setIsCardContextualMenuShown] = useState<boolean>(false);
  const [cardContextualMenuProps, setCardContextualMenuProps] = useState<IContextualMenuProps | undefined>();
  const [loadingMessage, setLoadingMessage] = useState<string>("Caricamento...");
  const [loadingMode, setLoadingMode] = useState<LoadingModalMode>(LoadingModalMode.Spinner);
  const [readStatusFilter, setReadStatusFilter] = useState<ReadStatusFilterView>(ReadStatusFilterView.AllItems);
  const [refreshView, setRefreshView] = useState({});
  const [advanceFilters, setAdvanceFilter] = useState<IAdvanceFilter>({});
  const [editingAdvanceFilters, setEditingAdvanceFilter] = useState<IAdvanceFilterFull>({ id: "" });
  const [advanceFilterIsOpen, setAdvanceFilterIsOpen] = useState(false);
  const [isPublishPanelOpen, setIsPublishPanelOpen] = useState<boolean>(false);
  const queryParam = useQuery();
  const publishableDocTypes = useMemo<IDocumentType[]>(() => {
    return getPublishableDocTypesForCurrUser(documentTypes, userGroups) || [];
  }, [userGroups, documentTypes])

  const PAGE_SIZE = 12;

  const totalPages = useMemo<number>(() => {
    return Math.ceil(totCount / PAGE_SIZE);
  }, [totCount]);


  const pageChanged: (page: number) => void = (page) => {
    const maxPage = Math.max(1, Math.ceil(totCount / PAGE_SIZE));
    setCurrPage(Math.min(maxPage, Math.max(1, page)));
  }

  const showMetadata: (doc: IDocument) => void = (doc) => {
    setCurrentDocument(doc);
    setIsMetmetadataPanelOpen(true);
  };

  const closeMetadata: () => void = () => {
    setIsMetmetadataPanelOpen(false);
  };

  const showCardContextualMenu = useCallback(
    (menuProps: IContextualMenuProps) => {
      setIsCardContextualMenuShown(true);
      setCardContextualMenuProps(menuProps);
    }, []);

  const hideContextualMenu = useCallback(() => setIsCardContextualMenuShown(false), []);

  const setDocumentAcquiredAfterDownload = (id: string) => {

    setLoadingMessage(`Aggiornamento documento in corso...`);
    setLoadingMode(LoadingModalMode.Spinner);
    setItemsAreLoading(true);

    const url = `/api/Documents('${id}')`;
    easyClient.patch<IDocument>(url, null, { headers: { "X-version": "1.0" } })
      .then(response => {
        //var oldDoc = documents.find(doc=>doc.id===id);
        var newDoc = response.data;
        if (newDoc) {
          setDocuments(documents.map(d => d.id === newDoc.id ? newDoc : d));
        }
      })
      .catch(err => {
        console.log(err);
        //setDocuments([]);
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  }

  const downloadDocument: (doc: IDocument) => void = (doc) => {
    const url = `/api/Documents('${doc.id}')/FileNameStorage`;

    setLoadingMessage(`Download in corso...`);
    setLoadingMode(LoadingModalMode.Progress);
    //setLoadingPercentage(0);
    setItemIsDownloading(true);

    easyClient.get<any>(url, {
      responseType: "blob",
      headers: { "X-version": "1.0" },
      // onDownloadProgress: progressEvent => {
      //   setLoadingPercentage(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
      // }
    })
      .then(response => {
        const filename = doc?.fileNameOriginal || doc.id;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          setDocumentAcquiredAfterDownload(doc.id);
        }, 300);

        // segnalo di aver ricevuto il file e aggiorno il documento
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setItemIsDownloading(false);
        // setLoadingPercentage(undefined);
      });
  };

  useEffect(() => {
    setCurrPage(1);
  }, [searchFilter, match.params.docTypeId, readStatusFilter])

  useEffect(() => {
    setLoadingMessage("Caricamento...");
    setLoadingMode(LoadingModalMode.Spinner);
    setItemsAreLoading(true);

    let url = `/api/Documents?$count=true&$top=${PAGE_SIZE}&$skip=${(currPage - 1) * PAGE_SIZE}&$orderby=${encodeURIComponent('publishedTimestamp desc')}`;

    const filter = buildFilterExpression(match.params, { ...advanceFilters, id: queryParam.get("id") ?? "" });
    if (filter) {
      url += "&$filter=" + encodeURIComponent(filter);
    }

    if (searchFilter && searchFilter.trim().length > 0) {
      url += "&search=" + encodeURIComponent(searchFilter);
    }

    if (readStatusFilter && readStatusFilter !== ReadStatusFilterView.AllItems) {
      url += "&readFilter=" + readStatusFilter;
    }

    easyClient.get<IODataList<IDocument>>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setDocuments(response.data.value);
        setTotCount(response.data["@odata.count"] || 0);
      })
      .catch(err => {
        console.log(err);
        setDocuments([]);
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  }, [currPage, easyClient, match.params, searchFilter, readStatusFilter, refreshView, advanceFilters, queryParam]);

  const currDocTypeName = useMemo(() => {

    if (match.params.docTypeId && documentTypes) {
      return documentTypes.find(dt => dt.id === match.params.docTypeId)?.name || match.params.docTypeId;
    }

    return undefined;

  }, [match.params.docTypeId, documentTypes]);


  const refreshViewHandler = () => {
    setRefreshView({});
    setIsMetmetadataPanelOpen(false);
    setCurrentDocument(undefined);
  }

  const userGroupIds = userGroups?.map(g => g.id) || [];

  const advanceFilterChangeHandler = (filters: IAdvanceFilterFull) => {
    setEditingAdvanceFilter(filters);
  }

  const advanceFilterSubmitHandler = (filters: IAdvanceFilterFull) => {
    const path = pathForDocuments(match.params.docTypeId, filters.id);
    history.replace(path);
    setAdvanceFilter(filters);
    setAdvanceFilterIsOpen(false);
  }

  const advanceFilterCloseHandler = () => {
    setAdvanceFilterIsOpen(false);
  }

  const advanceFilterOpenHandler = () => {
    setAdvanceFilterIsOpen(true)
    setEditingAdvanceFilter({ ...advanceFilters, id: queryParam.get("id") ?? "" });
  }

  const clearAdvanceFiltersHandler = () => {
    setAdvanceFilter({});
    history.replace(pathForDocuments(match.params.docTypeId, ""))
  }
  const publishDisabled = useMemo(() => {
    const currDocTypeId = match.params.docTypeId || "";
    return publishableDocTypes.length === 0 || (currDocTypeId.length > 0 && !publishableDocTypes.some(dt => dt.id === currDocTypeId));
  }, [match.params.docTypeId, publishableDocTypes])

  return (
    <Page title={currDocTypeName || "Documenti"} iconName="TextDocument" pageWidth="100%" theme={theme}>

      <LoadingModal
        show={itemsAreLoading || itemIsDowloading}
        mode={loadingMode}
        //percentComplete={loadingPercentage}
        message={loadingMessage}
      />
      <CommandBar
        newItemClicked={() => { setIsPublishPanelOpen(true) }}
        refreshClicked={refreshViewHandler}
        currentView={readStatusFilter}
        readStatusFilterChanged={(newReadStatusFitler) => setReadStatusFilter(newReadStatusFitler)}
        filterClick={advanceFilterOpenHandler}
        clearFilters={clearAdvanceFiltersHandler}
        filtersApplied={Object.keys(advanceFilters).some(k => !!advanceFilters[k as keyof IAdvanceFilter]) || !!queryParam.get("id")}
        publishDisabled={publishDisabled}
      />
      {documents.length === 0 && !itemsAreLoading && (
        <NoData title="Nessun documento trovato" />
      )}
      {documents.length > 0 && (
        <Stack
          horizontal
          tokens={{ childrenGap: 0, padding: 0 }}
          styles={stackStyles}
        >
          {documents.map((doc, index) => {
            const userIsPublisher = userRoles.some(r => userRolesActAsPublisher.includes(r)) ||
              userGroups.some(r => r.id === doc.publisherGroupId);
            return (
              <EasyDocumentCard
                theme={theme}
                key={doc.id}
                doc={doc}
                index={index}
                totCount={documents.length}
                showDocumentType={!match.params.docTypeId}
                modeShow={userIsPublisher ? EasyDocumentCardMode.Publisher : EasyDocumentCardMode.Recipient}
                onDownloadClick={downloadDocument}
                onShowMetadataClick={showMetadata}
                onShowContextMenuClick={showCardContextualMenu}
                userGroupIds={userGroupIds}
              />);
          })}

        </Stack>
      )}
      {totCount > 0 && (
        <Pagination
          theme={theme}
          page={currPage}
          totalPages={totalPages}
          totalItemsCount={totCount}
          handlePagination={(page) => pageChanged(page)}
        />)
      }
      <MetadataPanel
        theme={theme}
        doc={currentDocument}
        isOpen={isMetadataPanelOpen}
        onClose={closeMetadata}
      />
      <AdvanceFilterPanel
        theme={theme}
        filterChange={advanceFilterChangeHandler}
        filterSubmit={advanceFilterSubmitHandler}
        filters={{ ...editingAdvanceFilters }}
        isOpen={advanceFilterIsOpen}
        panelClose={advanceFilterCloseHandler} />
      {cardContextualMenuProps && <ContextualMenu
        {...cardContextualMenuProps}
        onDismiss={hideContextualMenu}
        hidden={!isCardContextualMenuShown}
        isBeakVisible={true}
      />}
      <DocumentPublisher
        easyClient={easyClient}
        docTypeId={match.params.docTypeId}
        isOpen={isPublishPanelOpen}
        onDismiss={(refresh) => {
          setIsPublishPanelOpen(false);
          refresh && refreshViewHandler();
        }}
      />
    </Page>)
}

export default connector(withEasyHttp(Document));
function toDatePlusOneDay(dateString: string | Date) {
  const dateSelected = new Date(dateString);
  const dateSelectedPlusOneDay = new Date(dateSelected.setDate(dateSelected.getDate() + 1));
  return dateSelectedPlusOneDay;
}

