import { generatePath } from 'react-router-dom';
import ProvisionReport from '../containers/ProvisionReport'
import ProvisionReportIndex from '../containers/ProvisionReport/ProvisionReportIndex/ProvisionReportIndex';

const prefix = "/area/soci";
export const provisionReportSelect = prefix + "/select-provision-report";

export const rootPath = prefix + "/provision-report/:partnerCode/:year";
export const varietyPickerPath = prefix + "/provision-report/:partnerCode/:year/:specie";
export const provisionsSummaryPath = prefix + "/provision-report/:partnerCode/:year/:specie/:variety/summary/:group";
export const provisionSummaryPath = prefix + "/provision-report/:partnerCode/:year/:specie/:variety/summary/:group/:groupValue";
export const provisionReportDetaiListlPath = prefix + "/provision-report/:partnerCode/:year/:specie/:variety/provisions";
export const provisionReportDetailPath = prefix + "/provision-report/:partnerCode/:year/provision/:provision";
const allPath = [
  provisionSummaryPath,
  provisionsSummaryPath,
  provisionReportDetaiListlPath,
  provisionReportDetailPath,
  varietyPickerPath,
  rootPath
]

export interface IProvisionReportPathProps {
  year: string;
  partnerCode: string;
  specie?: string;
  variety?: string;
  provision?: string;
  group?: string;
  groupValue?: string;
}

export const pathForProvisionsReportRoot = (partnerCode: string, year: string) => {
  return generatePath(rootPath, { partnerCode, year });
}

export const pathForProvisionsReportBySpecie = (partnerCode: string, year: string, specie: string) => {
  return generatePath(varietyPickerPath, { partnerCode, year, specie })
}

export const pathForProvisionsReportByVariety = (partnerCode: string, year: string, specie: string, variety: string) => {
  return generatePath(provisionReportDetaiListlPath, { partnerCode, year, specie, variety })
}

export const pathForProvisionReport = (partnerCode: string, year: string, provision: string) => {
  return generatePath(provisionReportDetailPath, { partnerCode, year, provision })
}

export const pathForProvisionsSummaryReport = (partnerCode: string, year: string, specie: string, variety: string, group: string) => {
  return generatePath(provisionsSummaryPath, { partnerCode, year, specie, variety, group });
}

export const pathForProvisionsSummaryGroupReport = (partnerCode: string, year: string, specie: string, variety: string, group: string, groupValue: string) => {
  return generatePath(provisionSummaryPath, { partnerCode, year, specie, variety, group, groupValue });
}

export const provisionReportRoute = [
  { path: allPath, component: ProvisionReport, key:"provisionReport" },
  { path: provisionReportSelect, component: ProvisionReportIndex, key: "provisionReportIndex"}]
