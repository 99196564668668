//import './wdyr'; // <--- first import
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';
import Init from './containers/Init/Init';
import Login from './containers/Login/Login';
import Context from './containers/Context/Context'

import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';

import { initializeIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { store } from './store/store';
import { BrowserRouter } from 'react-router-dom';

initializeIcons();
initializeFileTypeIcons();

ReactDOM.render(
  // Strict Mode not supported from fluent UI 7
  // see https://github.com/microsoft/fluentui/projects/35
  // <React.StrictMode>
  <Provider store={store}>
    <Init>
      <Login>
        <Context>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Context>
      </Login>
    </Init>
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
