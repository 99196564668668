import React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';

export enum ListView {
  AllItems,
  OnlyEnabled,
  OnlyDisabled
}

export interface IDocumentTypeCommandBarProp {
  newItemClicked: () => void,
  deleteItemClicked: () => void,
  viewAllItemsClicked: () => void,
  viewOnlyEnabledClicked: () => void,
  viewOnlyDisabledItemsClicked: () => void,
  disabled?: boolean,
  canDelete?: boolean,
  currentView: ListView
};

const DocumentTypeCommandBar: React.FC<IDocumentTypeCommandBarProp> = (props) => {

  const _items: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'Nuovo',
      iconProps: { iconName: 'Add' },
      disabled: props.disabled || false,
      onClick: props.newItemClicked
    },
    {
      key: 'deleteItem',
      text: 'Elimina',
      iconProps: { iconName: 'Delete' },
      disabled: props.disabled || !props.canDelete || false,
      onClick: props.deleteItemClicked,
    }
  ];

  const _farItems: ICommandBarItemProps[] = [
    {
      key: 'viewAllItems',
      text: props.currentView===ListView.OnlyEnabled?'Solo gli attivi':
      (props.currentView===ListView.OnlyDisabled?'Solo i non attivi':'Tutti gli elementi'),
      iconProps: { iconName: 'List' },
      subMenuProps: {
        items: [
          {
            key: 'viewAllItems',
            canCheck: true,
            checked:props.currentView===ListView.AllItems,
            text: 'Tutti gli elementi',
            onClick: props.viewAllItemsClicked
          },
          {
            key: 'viewOnlyEnabledItems',
            canCheck: true,
            checked:props.currentView===ListView.OnlyEnabled,
            text: 'Solo gli attivi',
            onClick: props.viewOnlyEnabledClicked
          },
          {
            key: 'viewOnlyDisabledItems',
            canCheck: true,
            checked:props.currentView===ListView.OnlyDisabled,
            text: 'Solo i non attivi',
            onClick: props.viewOnlyDisabledItemsClicked
          }
        ]
      }
    }
  ];

  return (
    <CommandBar
      items={_items}
      farItems={_farItems}
    />
  );

}

export default DocumentTypeCommandBar;
