import React, { useEffect, useState } from 'react'
import { ShimmeredDetailsList, Panel, IColumn, omit } from '@fluentui/react'
import DocumentTypeCommandBar, { ListView } from './CommandBar'
import Page from '../../hoc/Page/Page';
import EasyForm from '../../components/Form/Form';
import withApiWrapper from '../../hoc/HttpClientWrapper/HttpClientWrapper'
import easyClient, { IHttpClient } from '../../shared/easyAxios'
import { IODataList } from '../../models/odata';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import * as documentTypeRoute from './DocumentType.route'
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { IWithInteractiveLoginProps } from '../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper';
import { FormDefinitions, IAreaPickerDefinition, IInputDefinition, ISelectDefinition } from '../../types/Form';
import { IArea } from '../../types/Area';

const mapStateToProps = (store: IEasyStore) => {
  return {
    theme: store.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

interface IDocumentTypeProps extends RouteComponentProps<IDocumentTypeRouteProps>, PropsFromStore, IWithInteractiveLoginProps {
}

interface IDocumentTypeRouteProps {
  viewname: string;
}

interface IDocumentType {
  id?: string;
  eTag?: string;
  description?: string;
  descriptionShort?: string;
  behaviour?: string;
  priority?: number;
  enabled?: boolean;
  area?: string;
  areaName?: string;
  section?: string;
}

const createFormDefinition: (docType: IDocumentType, easyClient: IHttpClient) => FormDefinitions = (docType) => {
  return {
    "id": {
      elementType: "input",
      elementConfig: {
        label: 'Codice'
      },
      value: docType.id ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false,
    } as IInputDefinition,
    "description": {
      elementType: "input",
      elementConfig: {
        label: 'Descrizione'
      },
      value: docType.description ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false,
    } as IInputDefinition,
    "descriptionShort": {
      elementType: "input",
      elementConfig: {
        label: 'Descrizione breve'
      },
      value: docType.descriptionShort ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false,
    } as IInputDefinition,
    "behaviour": {
      elementType: "select",
      elementConfig: {
        label: 'Modalità di gestione',
        options: [
          {
            option: "F",
            text: "File"
          },
          {
            option: "P",
            text: "Pdf"
          }
        ]
      },
      value: docType.behaviour ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false,
    } as ISelectDefinition,
    "area": {
      elementType: "areaPicker",
      elementConfig: {
        label: 'Area',
        easyClient: easyClient
      },
      value: docType.area ? { id: docType.area, name: docType?.areaName } : undefined,
      valid: false,
      touched: false,
      validation: {
        required: false
      },
    } as IAreaPickerDefinition,
    "section": {
      elementType: "input",
      elementConfig: {
        label: 'Sezione'
      },
      value: docType.section ?? "",
      valid: false,
      touched: false,
      validation: {
        required: false
      },
    } as IInputDefinition,
    "priority": {
      elementType: "number",
      elementConfig: {
        label: "Priorità",
        min: 0,
        max: 10,
        step: 1
      },
      value: docType?.priority?.toLocaleString() ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    "enabled": {
      elementType: "boolean",
      elementConfig: {
        label: 'Attivo'
      },
      value: docType.enabled ?? false,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    }
  }
}

const columns: IColumn[] = [

  {
    key: "id",
    minWidth: 100,
    maxWidth: 100,
    name: "Codice",
    fieldName: "id"
  },
  {
    key: "description",
    minWidth: 100,
    name: "Descrizione",
    fieldName: "description"
  },
  {
    key: "enabled",
    minWidth: 100,
    name: "Attivo",
    fieldName: "enabled"
  }]

enum EditMode {
  Edit,
  New
}

const DocumentType: React.FC<IDocumentTypeProps> = (props) => {
  const easyClient = props.easyClient;
  const [items, setItems] = useState<IDocumentType[]>([]);
  const [detailOpened, setDetailOpened] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState<IDocumentType>({});
  const [currentFormDefinition, setCurrentFormDefinition] = useState<FormDefinitions>({});
  const [formIsValid, setFormIsValid] = useState(false);
  const [submit, setSubmit] = useState<any>(null);
  const [editMode, setEditMode] = useState(EditMode.Edit);
  // const [listView, setListView] = useState(ListView.AllItems);
  let { viewname } = useParams<IDocumentTypeRouteProps>();

  let listView = ListView.AllItems;
  switch (viewname) {
    case "enabled":
      listView = ListView.OnlyEnabled;
      break;
    case "disabled":
      listView = ListView.OnlyDisabled;
      break;
    default:
      listView = ListView.AllItems
  }

  useEffect(() => {
    let url = "/api/DocumentTypes?$orderby=id";
    if (listView === ListView.OnlyEnabled) {
      url += "&$filter=Enabled eq true";
    } else if (listView === ListView.OnlyDisabled) {
      url += "&$filter=Enabled eq false";
    }
    easyClient.get<IODataList<IDocumentType>>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setItems(response.data.value as IDocumentType[]);
      })
  }, [easyClient, listView])

  const submitEnabled = true;
  const selectItemHandler = (item?: IDocumentType) => {
    if (!item)
      return;
    setEditMode(EditMode.Edit);
    setCurrentEditItem(item);
    setCurrentFormDefinition(createFormDefinition(item, props.easyClient));
    setDetailOpened(true);
  }
  const closeDetailHandler = (): void => {
    setDetailOpened(false);
  }
  const formChangedHandler = (updatedForm: FormDefinitions, isValid: boolean): void => {
    setCurrentFormDefinition(updatedForm);
    setFormIsValid(isValid);
  }
  const formSubmitHandler = (): void => {
    setSubmit({});
  }

  useEffect(() => {
    if (submit && formIsValid) {
      setSubmit(null);
      currentEditItem.id = currentFormDefinition.id.value as string;
      currentEditItem.description = currentFormDefinition.description.value as string;
      currentEditItem.descriptionShort = currentFormDefinition.descriptionShort.value as string;
      currentEditItem.behaviour = currentFormDefinition.behaviour.value as string;
      currentEditItem.area = (currentFormDefinition.area.value as IArea)?.id;
      currentEditItem.areaName = (currentFormDefinition.area.value as IArea)?.name as string;
      currentEditItem.section = currentFormDefinition.section.value as string;
      currentEditItem.priority = currentFormDefinition.priority.value as number;
      currentEditItem.enabled = currentFormDefinition.enabled.value as boolean;
      if (editMode === EditMode.New) {
        easyClient.post<IDocumentType>("/api/DocumentTypes",
          omit(currentEditItem, ["eTag"]),
          { headers: { "X-version": "1.0" } })
          .then(response => {
            setDetailOpened(false);
            setItems([...items, response.data])
          })
      } else {
        easyClient.patch<IDocumentType>(`/api/DocumentTypes('${currentEditItem.id}')`,
          currentEditItem,
          { headers: { "X-version": "1.0" } })
          .then(response => {
            const localAllItems = [...items];
            const index = localAllItems.indexOf(currentEditItem);
            if (index !== -1) {
              localAllItems.splice(index, 1, response.data);
            }
            setItems(localAllItems);
            setDetailOpened(false);
          })
      }
    }
  }, [submit, formIsValid, easyClient, currentFormDefinition, currentEditItem, items, editMode]);

  const newItemHandler = () => {
    setEditMode(EditMode.New);
    setCurrentEditItem({});
    setCurrentFormDefinition(createFormDefinition({}, props.easyClient))
    setDetailOpened(true)
  }

  const deleteItemHandler = () => {
  }

  const viewAllItemsHandler = () => {
    props.history.push(documentTypeRoute.pathForDocumentType())
  }
  const viewOnlyEnabledHandler = () => {
    props.history.push(documentTypeRoute.pathForDocumentType(ListView.OnlyEnabled))
  }
  const viewOnlyDisabledHandler = () => {
    props.history.push(documentTypeRoute.pathForDocumentType(ListView.OnlyDisabled))
  }

  const renderItemColumn = (item?: IDocumentType, index?: number | undefined, column?: IColumn | undefined) => {
    if (!item || !column)
      return;
    const fieldContent = item[column.fieldName as keyof IDocumentType];
    switch (column.key) {
      case 'enabled':
        return <span>{fieldContent ? 'Si' : 'No'}</span>
      default:
        return <span>{fieldContent?.toString()}</span>;
    }
  }

  return (
    <Page title="Tipi documento" iconName="DocumentSet" pageWidth="960px" theme={props.theme}>
      <DocumentTypeCommandBar
        newItemClicked={newItemHandler}
        deleteItemClicked={deleteItemHandler}
        viewAllItemsClicked={viewAllItemsHandler}
        viewOnlyEnabledClicked={viewOnlyEnabledHandler}
        viewOnlyDisabledItemsClicked={viewOnlyDisabledHandler}
        currentView={listView}
      />
      <ShimmeredDetailsList
        items={items}
        onItemInvoked={selectItemHandler}
        columns={columns}
        onRenderItemColumn={renderItemColumn}
      />
      <Panel
        headerText="Tipo Documento"
        isOpen={detailOpened}
        onDismiss={closeDetailHandler}
        closeButtonAriaLabel="Close"
      >
        <EasyForm
          theme={props.theme}
          formDefinition={currentFormDefinition}
          onChange={formChangedHandler}
          onSubmit={formSubmitHandler}
          submitEnabled={submitEnabled}
        />
      </Panel>
    </Page>
  );
}

export default connector(withRouter(withApiWrapper(DocumentType)));
