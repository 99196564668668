import { put } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { IContextAction, IContextFetchAction, ISetThemeAction } from "../types/context";
import { changeThemeAction, contextError, contextLoaded, contextLoading } from "../actions/context";

export function* contextSaga(action: IContextFetchAction) {
  yield put(contextLoading(window.location.hostname));
  try {
    const response: AxiosResponse<IContextAction> = yield action.easyClient.get<IContextAction>("/api/SPAContext", { headers: { "X-version": "1.0" } })
    yield put(contextLoaded(response.data));
    const themeName: string = yield localStorage.getItem("theme");
    if (themeName === "themeDark" || themeName === "themeLight") {
      yield put(changeThemeAction(themeName));
    }
  } catch (e) {
    yield put(contextError());
  }
}

export function* setThemeToStorage(action: ISetThemeAction) {
  yield localStorage.setItem("theme", action.theme);
}
