import React from 'react';

import Flicking from "@egjs/react-flicking";
import styleFactory from './Carousel.style';
import { ITheme } from 'office-ui-fabric-react';

interface ICarouselProps{
  theme: ITheme;
}

const Carousel: React.FC<ICarouselProps> = (props) => {
  const style = styleFactory(props.theme);
  return (
    <section className={style.carousel}>
      <Flicking circular autoResize gap={5}>
        <div className={`${style.items} ${style.item1}`}></div>
        <div className={`${style.items} ${style.item2}`}></div>
        <div className={`${style.items} ${style.item3}`}></div>
      </Flicking>
    </section>
  );
};

export default Carousel;
