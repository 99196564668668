import { ITheme } from 'office-ui-fabric-react';
import React from 'react';

import styleFactory from './Hamburger.styles';

interface IHamburgerProps {
  click: () => void;
  theme: ITheme;
}

const hamburger: React.FC<IHamburgerProps> = (props) => {
  const style = styleFactory(props.theme);
  return (
    <div className={style.hamburger} onClick={props.click}>
      <div className={style.line}></div>
      <div className={style.line}></div>
      <div className={style.line}></div>
    </div>
  )
}

export default hamburger;
