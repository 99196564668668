import { APP_SET_CONFIG, APP_SET_CONFIG_DEFAULT, APP_SET_CONFIG_DONE, APP_SET_CONFIG_START, IEasyConfig, SetConfigAction, SetConfigDefaultAction, SetConfigDoneAction, SetConfigStartAction } from '../types/config';

export const appSetConfig = (): SetConfigAction => {
  return {
    type: APP_SET_CONFIG
  };
};

export const appSetConfigStart = (): SetConfigStartAction => {
  return {
    type: APP_SET_CONFIG_START
  }
}

export const appSetConfigDone = (easyConfig: IEasyConfig): SetConfigDoneAction => {
  return {
    type: APP_SET_CONFIG_DONE,
    easyConfig: easyConfig
  };
}

export const appSetConfigDefault = (): SetConfigDefaultAction => {
  return {
    type: APP_SET_CONFIG_DEFAULT
  }
}


