import axios, { AxiosRequestConfig } from 'axios';
import { replaceAuth } from './easyAxios';
import { acquireToken } from './azureLogin';

export const scopes = ["openid", "profile", "offline_access", "User.Read"];

const __axios = axios.create({ baseURL: "https://graph.microsoft.com" });

const graphClient = {
    get: (url: string, config?: AxiosRequestConfig) => {
        return acquireToken(scopes).then(tk => {
            if (!tk) {
                throw Error("errore nella acquisizione del token");
            }
            const configWithAuth = replaceAuth(config ?? {}, tk);
            return __axios.get(url, configWithAuth);
        })
    },
    getInstance: () => __axios
};

export default graphClient;
