import React, { useEffect, useMemo, useState } from 'react';
import { omit, Panel, PanelType } from '@fluentui/react';
import EasyForm from '../../../components/Form/Form';
import { IHttpClient } from '../../../shared/easyAxios';
import { IEasyStore } from '../../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { getFileContentAndMimeType } from '../../../shared/utility';
import LoadingModal, { LoadingModalMode } from '../../../components/UI/LoadingModal/LoadingModal';
import { INewsArticle, INewsArticleForEditor } from '../News.types';
import { FormDefinitions } from '../../../types/Form';
import { IFileInputValue } from '../../../types/FileInput';

const mapStateToProps = (state: IEasyStore) => {
  return {
    userRoles: state.context.roles,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>
interface INewsEditorOwnProps {
  easyClient: IHttpClient;
  isOpen: boolean;
  onDismiss: (refresh?: boolean) => void;
  articleId?: string;
}

type NewsEditorProps = PropsFromStore & INewsEditorOwnProps;

const createFormDefinition: (currentArticle?: INewsArticle) => FormDefinitions = (currentArticle) => {

  return {
    "title": {
      elementType: "input",
      elementConfig: {
        label: 'Titolo',
      },
      value: currentArticle?.title,
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    "summary": {
      elementType: "multiline",
      elementConfig: {
        label: 'Sommario',
      },
      value: currentArticle?.summary,
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    "body": {
      elementType: "richText",
      elementConfig: {
        label: 'Corpo',
        placeholder: "Inserisci qui il corpo della news...",
      },
      value: currentArticle?.body,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    },
    "articleDate": {
      elementType: "date",
      elementConfig: {
        label: 'Data articolo',
      },
      value: currentArticle?.articleDate ? new Date(currentArticle.articleDate) : undefined,
      validation: {
        required: true
      },
      valid: false,
      touched: false,
    },
    "imageFile": {
      elementType: "fileInput",
      elementConfig: {
        label: 'Immagine',
        showFileIcon: false,
        showImagePreview: true,
        previewMaxHeight: "300px",
        accepts: ["image/*"],
      },
      value: (currentArticle?.imageFileContent?.length || 0) > 0
        ? {
          fileDataURL: currentArticle?.imageFileContent
        }
        : undefined,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
    "visibleOnBoard": {
      elementType: "boolean",
      elementConfig: {
        label: 'Visibile in bacheca'
      },
      value: currentArticle?.visibleOnBoard ?? false,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    },
    "priorityOnBoard": {
      elementType: "slider",
      elementConfig: {
        label: "Priorità in bacheca (-10: bassa, +10: alta)",
        min: -10,
        max: 10,
        step: 1
      },
      value: currentArticle?.priorityOnBoard ?? 0,
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
  }
}

const NewsEditor: React.FC<NewsEditorProps> = (props: NewsEditorProps) => {

  const editMode = useMemo(() => {
    return (props.articleId?.length || 0) > 0;
  }, [props.articleId]);

  const easyClient = props.easyClient;
  const [currentForm, setCurrentForm] = useState<FormDefinitions>(createFormDefinition());
  const [formIsValid, setFormIsValid] = useState(false);
  const [submit, setSubmit] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [savingPercentageComplete, setSavingPercentageComplete] = useState<number>();
  const [articleIsLoading, setArticleIsLoading] = useState<boolean>(editMode);
  const [imageChanged, setImageChanged] = useState<boolean>(false);
  const [articleETag, setArticleETag] = useState<string>();

  useEffect(() => {
    if (props.articleId) {
      setArticleIsLoading(true);
      let url = `/api/Articles('${encodeURIComponent(props.articleId)}')?getThumbImage=false`;

      easyClient.get<INewsArticle>(url, { headers: { "X-version": "1.0" } })
        .then(response => {
          setCurrentForm(createFormDefinition(response.data));
          setArticleETag(response.data?.eTag);
        })
        .catch(err => {
          console.log(err);
          props.onDismiss();
        })
        .finally(() => {
          setArticleIsLoading(false);
        });
    } else {
      setCurrentForm(createFormDefinition());
      setArticleETag(undefined);
    }
  }, [easyClient, props])

  const submitEnabled = useMemo(() => { return submit === null && !isSubmitting }, [submit, isSubmitting]);

  const updateFormDefinition = (updatedForm: FormDefinitions, formIsValid: boolean) => {
    // let needNewValidation = false;

    const fileAdded = currentForm["imageFile"]?.value === undefined && updatedForm["imageFile"]?.value !== undefined;
    const fileRemoved = currentForm["imageFile"]?.value !== undefined && updatedForm["imageFile"]?.value === undefined;

    if (fileAdded || fileRemoved) {
      setImageChanged(true);
    }

    setCurrentForm(updatedForm);
    setFormIsValid(formIsValid);

  }

  const formChangedHandler = (updatedForm: FormDefinitions, isValid: boolean) => {
    updateFormDefinition(updatedForm, isValid);
  }
  const formSubmitHandler = () => {
    setSubmit({});
  }

  useEffect(() => {
    if (submit && formIsValid && !isSubmitting) {
      setSubmit(null);
      setIsSubmitting(true);
      setSavingPercentageComplete(0);

      const { fileContent: imageFileContent, mimeType: imageMimeType } = getFileContentAndMimeType((currentForm["imageFile"]?.value as IFileInputValue | undefined)?.fileDataURL);

      const articleForEditor: INewsArticleForEditor = {
        id: props.articleId,
        title: currentForm["title"]?.value as string | undefined,
        summary: currentForm["summary"]?.value as string | undefined,
        body: currentForm["body"]?.value as string | undefined,
        imageChanged: !editMode || imageChanged,
        imageFileContent: imageChanged ? imageFileContent : undefined,
        imageMimeType: imageChanged ? imageMimeType : undefined,
        articleDate: currentForm["articleDate"]?.value as Date | undefined,
        visibleOnBoard: currentForm["visibleOnBoard"]?.value as boolean | undefined,
        priorityOnBoard: currentForm["priorityOnBoard"]?.value as number | undefined,
        eTag: articleETag,
      };

      if (editMode) {
        // Modifica articolo esistente
        let fieldsToOmit: (keyof INewsArticleForEditor)[] = ["creationLog", "lastChangeLog"];
        if (!articleForEditor.imageChanged) fieldsToOmit.push("imageFileContent");

        easyClient.patch<INewsArticleForEditor>(`/api/Articles('${encodeURIComponent(props.articleId!)}')`, omit(articleForEditor, fieldsToOmit),
          {
            headers: { "X-version": "1.0" },
            onUploadProgress: progressEvent => {
              setSavingPercentageComplete(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
            }
          })
          .then(response => {
            window.alert("L'articolo è stato aggiornato.");
            props.onDismiss(true);
          })
          .finally(() => {
            setIsSubmitting(false);
            setSavingPercentageComplete(0);
          });
      } else {
        // Pubblicazione nuovo articolo
        easyClient.post<INewsArticleForEditor>("/api/Articles", omit(articleForEditor, ["id", "eTag", "creationLog", "lastChangeLog"]),
          {
            headers: { "X-version": "1.0" },
            onUploadProgress: progressEvent => {
              setSavingPercentageComplete(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
            }
          })
          .then(response => {
            window.alert("L'articolo è stato pubblicato.");
            props.onDismiss(true);
          })
          .finally(() => {
            setIsSubmitting(false);
            setSavingPercentageComplete(0);
          });
      }
    }
  }, [submit, isSubmitting, formIsValid, easyClient, currentForm, props, imageChanged, editMode, articleETag]);


  return <>
    {isSubmitting && (
      <LoadingModal
        show={isSubmitting}
        mode={LoadingModalMode.Progress}
        percentComplete={savingPercentageComplete}
        message={"Salvataggio in corso..."}
      />
    )}
    {articleIsLoading && (
      <LoadingModal
        show={articleIsLoading}
        mode={LoadingModalMode.Spinner}
        message={"Carciamento in corso..."}
      />)}
    <Panel
      isOpen={props.isOpen}
      onDismiss={() => {
        props.onDismiss();
      }}
      headerText={editMode ? "Modifica articolo" : "Pubblica un nuovo articolo"}
      type={PanelType.large}
      layerProps={{ eventBubblingEnabled: true }}
      onOuterClick={() => { }}
    >
      <EasyForm
        theme={props.theme}
        formDefinition={currentForm}
        onChange={formChangedHandler}
        onSubmit={formSubmitHandler}
        submitEnabled={submitEnabled}
      />
    </Panel>
  </>;
};

export default connector(NewsEditor);
