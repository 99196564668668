import React from 'react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { IStackTokens, Link, mergeStyles, Panel, Stack } from '@fluentui/react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import ThemeContainer from '../../../../containers/ThemeContainer/ThemeContainer';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ISettingsProps {
  allSettingClicked: () => void;
  isOpen: boolean;
  openPanelClicked: () => void;
  dismissPanel: () => void;
}

const Settings: React.FC<ISettingsProps & RouteComponentProps> = (props) => {
  const stackTokens: IStackTokens = { childrenGap: 12 };

  const allSettingsClickHandler = () => {
    props.allSettingClicked();
    props.dismissPanel();
  }

  return (
    <>
      <FontIcon iconName="Settings"
        onClick={props.openPanelClicked}
        className={mergeStyles({
          fontSize: "2rem",
          verticalAlign: "middle",
        })} />
      <Panel
        headerText="Impostazioni"
        isBlocking={false}
        isLightDismiss
        isOpen={props.isOpen}
        onDismiss={props.dismissPanel}
        closeButtonAriaLabel="Close"
      >
        <Stack tokens={stackTokens}>
            <>
              <ThemeContainer />
              <Text><Link onClick={allSettingsClickHandler}>Tutte le impostazioni</Link></Text>
            </>
        </Stack>
      </Panel>
    </>
  );
}

export default withRouter(Settings);
