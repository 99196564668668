import React, { useEffect, useMemo, useState } from 'react';
import { Panel, PanelType } from '@fluentui/react';
import EasyForm, { areAllTouchedValid, checkWholeFormValidity, replaceFieldAndValidate } from '../../components/Form/Form';
import { IHttpClient } from '../../shared/easyAxios';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { getFileContentAndMimeType, updateObject } from '../../shared/utility';
import LoadingModal, { LoadingModalMode } from '../../components/UI/LoadingModal/LoadingModal';
import { getPublishableDocTypesForCurrUser } from './Document';
import { getSelectedGroupsIdFromTags } from '../DocumentGroupPicker/DocumentGroupPicker';
import { IDocumentType, IGroup, UserRole } from '../../store/types/context';
import { IPublisherFormDefinition } from '../../types/Document';

const mapStateToProps = (state: IEasyStore) => {
  return {
    userDocumentTypes: state.context.documentType,
    userGroups: state.context.groups,
    userRoles: state.context.roles,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

interface IDocumentPublisherStateProps {
  userDocumentTypes: IDocumentType[];
  userGroups: IGroup[];
  userRoles: UserRole[];
}

interface IDocumentPublisherOwnProps {
  easyClient: IHttpClient;
  docTypeId?: string;
  isOpen: boolean;
  onDismiss: (refresh?: boolean) => void;
}

type DocumentPublisherProps = IDocumentPublisherStateProps & IDocumentPublisherOwnProps & PropsFromStore;


interface IDocumentForPublish {
  documentTypeId?: string;
  fileName?: string;
  fileContent?: string | ArrayBuffer | null;
  publisherGroupId?: string;
  recipientGroupsId?: string[];
}

const getPubGroups: (userGroups: IGroup[], docTypeId?: string) => IGroup[] = (userGroups, docTypeId) => {
  return userGroups.filter(g => g.publishableDocTypeIds.indexOf(docTypeId || "_FAKE_") >= 0);
}

const createFormDefinition = (easyClient: IHttpClient, publishableDocTypes: IDocumentType[], userGroups: IGroup[], currDocTypeId?: string): IPublisherFormDefinition => {

  const currDocTypeIsPublishable = currDocTypeId !== undefined && publishableDocTypes.some((dt) => dt.id === currDocTypeId);
  const initialDocTypeId = currDocTypeIsPublishable ? currDocTypeId : undefined;
  const initialPubGroups = getPubGroups(userGroups, initialDocTypeId);
  const initialPubGroupId = initialPubGroups.length === 1 ? initialPubGroups[0].id : undefined;

  return {
    "documentType": {
      elementType: "select",
      elementConfig: {
        label: 'Tipo documento',
        disabled: currDocTypeIsPublishable,
        options: publishableDocTypes.map(dt => {
          return {
            option: dt.id,
            text: dt.name,
          }
        })
      },
      value: initialDocTypeId,
      validation: {
        required: true
      },
      valid: false,
      touched: currDocTypeIsPublishable,
    },
    "file": {
      elementType: "fileInput",
      elementConfig: {
        label: 'File',
      },
      value: undefined,
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    "fileNameOriginal": {
      elementType: "input",
      elementConfig: {
        label: 'Nome documento',
        disabled: true,
      },
      value: undefined,
      validation: {
        required: true,
        maxLength: 230,
      },
      valid: false,
      touched: false,
    },
    "publisherGroup": {
      elementType: "select",
      elementConfig: {
        label: 'Gruppo di pubblicazione',
        disabled: initialDocTypeId === undefined,
        options: initialPubGroups.map(g => {
          return {
            option: g.id,
            text: g.description,
          }
        })
      },
      value: initialPubGroupId,
      validation: {
        required: true,
      },
      valid: false,
      touched: initialPubGroupId !== undefined,
    },
    "recipientGroups": {
      elementType: "documentGroupPicker",
      elementConfig: {
        label: 'Gruppi destinatari',
        disabled: initialDocTypeId === undefined,
        easyClient: easyClient,
        maxItemsSelectable: 50,
        receivableDocTypeIdFilter: currDocTypeIsPublishable ? currDocTypeId : undefined,
      },
      value: [],
      validation: {
        required: true,
      }
    },
  }
}

const DocumentPublisher: React.FC<DocumentPublisherProps> = (props: DocumentPublisherProps) => {
  const publishableDocTypes = useMemo<IDocumentType[]>(() => {
    return getPublishableDocTypesForCurrUser(props.userDocumentTypes, props.userGroups);
  }, [props.userGroups, props.userDocumentTypes])

  const easyClient = props.easyClient;
  const [currentForm, setCurrentForm] = useState<IPublisherFormDefinition>(createFormDefinition(props.easyClient, publishableDocTypes, props.userGroups, props.docTypeId));
  const [formIsValid, setFormIsValid] = useState(false);
  const [submit, setSubmit] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [publishPercentageComplete, setPublishPercentageComplete] = useState<number>();


  const submitEnabled = useMemo(() => { return submit === null && !isSubmitting }, [submit, isSubmitting]);

  const updateFormDefinition = (updatedForm: IPublisherFormDefinition, formIsValid: boolean) => {
    let needNewValidation = false;

    const fileAdded = currentForm["file"]?.value === undefined && updatedForm["file"]?.value !== undefined;
    const fileRemoved = currentForm["file"]?.value !== undefined && updatedForm["file"]?.value === undefined;

    const documentTypeChanged = (currentForm["documentType"]?.value || "") !== (updatedForm["documentType"]?.value || "");

    const fileExt = updatedForm["file"]?.value?.fileNameExtension?.toLowerCase();
    const fileExtWithDot = (fileExt?.length ?? 0) === 0 ? undefined : ("." + fileExt);
    const fileNameWithoutExtension = updatedForm["file"]?.value?.fileNameWithoutExtension;

    // Se ho aggiunto/rimosso il file abilito/disabilito i campi
    if (fileAdded || fileRemoved) {
      updatedForm["fileNameOriginal"].elementConfig = updateObject(updatedForm["fileNameOriginal"].elementConfig, {
        disabled: fileRemoved,
        suffix: fileExtWithDot,  // Per il nome file aggiorno anche l'estensione (suf)fissa
      });
    }

    // se è stato cambiato il tipo documento, va ricomputato l'elenco dei gruppi di pubblicazione / destinazione
    // e vanno ripulite le destinazioni che erano state eventualmente selezionate in precedenza
    if (documentTypeChanged) {

      const selectedDocTypeId = updatedForm["documentType"]?.value;

      // Se è stato selezionato un tipo documento allora abilito i gruppi pubblicazione e destinazione, altrimenti li disabilito

      ["publisherGroup" as keyof IPublisherFormDefinition, "recipientGroups" as keyof IPublisherFormDefinition].forEach((fieldName) => {
        updatedForm[fieldName].elementConfig = updateObject(updatedForm[fieldName].elementConfig, {
          disabled: selectedDocTypeId === undefined,
        });
      });

      //#region Gruppi pubblicazione
      // *********************
      // Per il gruppo di pubblicazione aggiorno l'elenco dei possibili valori e,
      // - se ce ne è uno solo, lo seleziono già
      // - altrimenti:
      //      - se tra i possibili valori c'è anche quello precedentemente selezionato, lo mantengo selezionato
      //      - altrimenti pulisco il valore precedentemente selezionato
      // *********************
      const derivedPubGroups = getPubGroups(props.userGroups, selectedDocTypeId);

      // Aggiorno i possibili valori
      updatedForm["publisherGroup"].elementConfig = updateObject(updatedForm["publisherGroup"].elementConfig, {
        options: derivedPubGroups.map(pg => {
          return {
            option: pg.id,
            text: pg.description,
          }
        })
      });

      const derivedPubGroupId = derivedPubGroups.length === 1 ? derivedPubGroups[0].id : undefined; // se ce ne è uno solo seleziono quello
      const selectedPubGroupId = updatedForm["publisherGroup"]?.value;
      let newSelectedPubGroupId: string | undefined;
      let changeSelectedPubGroupId = false;

      if (derivedPubGroupId === undefined) {
        // Il pubblicatore può essere scelto tra più valori.
        // Se uno dei possibili valori è quello già selezionato in precedenza lo mantengo (quindi non cambio valore/validazione), altrimenti lo svuoto.
        if (derivedPubGroups.length === 0 || !derivedPubGroups.some(pg => pg.id !== undefined && pg.id === selectedPubGroupId)) {
          changeSelectedPubGroupId = true;
          newSelectedPubGroupId = undefined;
        }
      } else if (selectedPubGroupId !== derivedPubGroupId) {
        // Il pubblicatore può essere uno solo ed è diverso dal precedente: lo seleziono
        changeSelectedPubGroupId = true;
        newSelectedPubGroupId = derivedPubGroupId;
      }

      if (changeSelectedPubGroupId) {
        needNewValidation = true;
        const newField = { ...updatedForm.publisherGroup, value: newSelectedPubGroupId }
        updatedForm = replaceFieldAndValidate<IPublisherFormDefinition>(newField, updatedForm, "publisherGroup");
        formIsValid = areAllTouchedValid(updatedForm);
      }
      //#endregion Gruppi pubblicazione

      //#region Gruppi destinazione
      // *********************
      // Per i gurppi di destinazione aggiorno il filtro sul controllo input e azzero la selezione precedente
      // *********************
      // Aggiorno i possibili valori
      updatedForm["recipientGroups"].elementConfig = updateObject(updatedForm["recipientGroups"].elementConfig, {
        receivableDocTypeIdFilter: selectedDocTypeId,
      });

      // Azzero l'eventuale selezione precedente
      if (updatedForm["recipientGroups"].touched) {
        needNewValidation = true;
        const newField = { ...updatedForm.publisherGroup, value: newSelectedPubGroupId }
        updatedForm = replaceFieldAndValidate<IPublisherFormDefinition>(newField, updatedForm, "recipientGroups");
        formIsValid = areAllTouchedValid(updatedForm);
      }

      //#endregion Gruppi destinazione
    }

    //#region File
    const fileNameOriginalForInput = fileAdded === true
      ? fileNameWithoutExtension
      : fileRemoved === true
        ? undefined
        : updatedForm["fileNameOriginal"]?.value;

    // Se il valore è cambiato, aggiornao la form e rieffetto la validazione
    if (!(updatedForm["fileNameOriginal"]?.value === undefined && fileNameOriginalForInput === undefined)
      && updatedForm["fileNameOriginal"]?.value !== fileNameOriginalForInput) {
      needNewValidation = true;
      const newField = { ...updatedForm.fileNameOriginal, value: fileNameOriginalForInput }
      updatedForm = replaceFieldAndValidate<IPublisherFormDefinition>(newField, updatedForm, "fileNameOriginal");
      formIsValid = areAllTouchedValid(updatedForm);
    }
    //#endregion File

    //Se ho cambiato qualcosa rivalido tutta la form (per i soli campi "toccati")
    if (needNewValidation) {
      const { updatedForm: newValidatedForm, formIsValid: newFormIsValid } = checkWholeFormValidity(updatedForm, false);
      updatedForm = newValidatedForm;
      formIsValid = newFormIsValid;
    }

    setCurrentForm(updatedForm);
    setFormIsValid(formIsValid);

  }

  const formChangedHandler = (updatedForm: IPublisherFormDefinition, isValid: boolean) => {
    updateFormDefinition(updatedForm, isValid);
  }
  const formSubmitHandler = () => {
    setSubmit({});
  }

  useEffect(() => {
    setCurrentForm(createFormDefinition(props.easyClient, publishableDocTypes, props.userGroups, props.docTypeId));
  }, [props.isOpen, publishableDocTypes, props.docTypeId, props.easyClient, props.userGroups]);

  useEffect(() => {
    if (submit && formIsValid && !isSubmitting) {
      setSubmit(null);
      setIsSubmitting(true);
      setPublishPercentageComplete(0);

      const fileValue = currentForm["file"]?.value;
      const { fileContent } = getFileContentAndMimeType(fileValue?.fileDataURL);

      let newDocument: IDocumentForPublish = {
        documentTypeId: currentForm["documentType"]?.value,
        fileName: (currentForm["fileNameOriginal"]?.value || "") + (currentForm["fileNameOriginal"]?.elementConfig?.suffix || ""),
        fileContent: fileContent,
        publisherGroupId: currentForm["publisherGroup"]?.value,
        recipientGroupsId: getSelectedGroupsIdFromTags(currentForm["recipientGroups"]?.value),
      }
      //console.log("submitting nuew document", newDocument);
      easyClient.post<IDocumentType>("/api/Documents", newDocument,
        {
          headers: { "X-version": "1.0" },
          onUploadProgress: progressEvent => {
            setPublishPercentageComplete(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
          }
        })
        .then(response => {
          window.alert("Il documento è stato pubblicato.");
          props.onDismiss(true);
        })
        .finally(() => {
          setIsSubmitting(false);
          setPublishPercentageComplete(0);
        });
    }
  }, [submit, isSubmitting, formIsValid, easyClient, currentForm, props]);


  return <>
    {isSubmitting && (
      <LoadingModal
        show={isSubmitting}
        mode={LoadingModalMode.Progress}
        percentComplete={publishPercentageComplete}
        message={"Pubblicazione documento in corso..."}
      />
    )}
    <Panel
      isOpen={props.isOpen}
      onDismiss={() => {
        props.onDismiss();
      }}
      headerText="Pubblica un nuovo documento"
      type={PanelType.large}
      layerProps={{ eventBubblingEnabled: true }}
    >
      <EasyForm
        theme={props.theme}
        formDefinition={currentForm}
        onChange={formChangedHandler}
        onSubmit={formSubmitHandler}
        submitEnabled={submitEnabled}
      />
    </Panel>
  </>;
};

export default connector(DocumentPublisher);

