import { updateObject } from '../../shared/utility';
import { buildMenu } from './context.functions';
import {
  ContextActionTypes,
  CONTEXT_ERROR,
  CONTEXT_LOADED,
  CONTEXT_LOADING,
  GLOBAL_SET_SEARCH_FILTER,
  IContextAction,
  IContextState,
  IGroup,
  SET_FULL_THEME,
  SET_THEME,
  ThemeName,
  UserRole
} from '../types/context';
import { getTheme, ITheme } from 'office-ui-fabric-react';

const initialState: IContextState = {
  displayname: "",
  documentType: [],
  partners: [],
  roles: [],
  groups: [],
  username: "",
  loading: false,
  topMenu: [],
  menuByArea: {},
  error: false,
  licenseDomain: "",
  currentDomain: "",
  modules: [],
  theme: 'themeLight',
  globalSearchExpression: "",
  fullTheme: getTheme()
};

const contextLoading = (state: IContextState, domain: string): IContextState => {
  return updateObject(state, { loading: true, currentDomain: domain });
}

const contextLoaded = (state: IContextState, contextData: IContextAction): IContextState => {
  const menus = buildMenu(
    contextData.partners,
    contextData.roles as UserRole[],
    contextData.docTypes,
    contextData.areas ?? {},
    contextData.licenseInfo?.modules ?? []);
  return updateObject(state, {
    displayname: contextData.displayname,
    documentType: contextData.docTypes,
    partners: contextData.partners,
    roles: contextData.roles as UserRole[],
    groups: contextData.groups as IGroup[],
    username: contextData.username,
    loading: false,
    topMenu: menus.topMenu,
    menuByArea: menus.menuByArea,
    licenseDomain: contextData.licenseInfo?.domain,
    licenseEndValidityDate: new Date(contextData.licenseInfo?.endValidity ?? ""),
    modules: contextData.licenseInfo?.modules ?? []
  });
}

const contextError = (state: IContextState): IContextState => {
  return updateObject(state, {
    error: true
  })
}

const setTheme = (state: IContextState, theme: ThemeName): IContextState => {
  return updateObject(state, {
    theme: theme
  });
}

const setSearch = (state: IContextState, search: string): IContextState => {
  return updateObject(state, {
    globalSearchExpression: search
  })
}

const setFullTheme = (state: IContextState, fullTheme: ITheme) => {
  return updateObject(state, {
    fullTheme: fullTheme
  })
}

const reducer = (state: IContextState = initialState, action: ContextActionTypes): IContextState => {
  switch (action.type) {
    case CONTEXT_LOADING: return contextLoading(state, action.domain);
    case CONTEXT_LOADED: return contextLoaded(state, action.contextData);
    case CONTEXT_ERROR: return contextError(state);
    case SET_THEME: return setTheme(state, action.theme);
    case SET_FULL_THEME: return setFullTheme(state, action.theme);
    case GLOBAL_SET_SEARCH_FILTER: return setSearch(state, action.searchExpression);
  }
  return state;
}

export default reducer;
