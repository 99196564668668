import { IStyle, IProcessedStyleSet, mergeStyleSets, ITheme } from "@fluentui/react";

interface IBackgroundStyle {
  Background: IStyle;
}

const styleFactory = (theme: ITheme): IProcessedStyleSet<IBackgroundStyle> => {
  return mergeStyleSets<IBackgroundStyle>({
    Background: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: -1,
      backgroundColor: theme.palette.neutralLighter,
    }
  })
}

export default styleFactory;
