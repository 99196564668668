import React from 'react';

import { Card } from '@uifabric/react-cards';
import { Stack, Text } from '@fluentui/react';

import { ITheme, mergeStyleSets, AnimationStyles } from "@fluentui/react";
import { ICardSectionTokens } from "@uifabric/react-cards";

export const getCardClassNames = (theme: ITheme) => {
  const { palette } = theme;

  return mergeStyleSets({
    card: {
      boxSizing: "border-box",
      textAlign: 'center',
      width: "100%",
      background: palette.neutralLighter,
      margin: "auto",
      ...AnimationStyles.slideLeftIn10
    },
    title: {
      borderBottom: `1px solid ${palette.neutralTertiaryAlt}`
    }
  });
}
export const cardsToken: ICardSectionTokens = { padding: "0.3125rem" };

export interface ISpecieCardProps {
  title: string;
  delivered: number;
  sampled: number;
  theme: ITheme;
}

export const ProvisionCard: React.FC<ISpecieCardProps> = ({
  title,
  delivered,
  sampled,
  children,
  theme
}) => {
  const { palette } = theme;
  const classNames = getCardClassNames(theme);
  const numberFormat = Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
  const percentFormat = Intl.NumberFormat(undefined, { style: "percent", maximumFractionDigits: 2 });
  const ratio = sampled / delivered;
  return (
    <Card className={classNames.card} >
      <Card.Item tokens={cardsToken} className={classNames.title}>
        <Text variant="large">{title}</Text>
      </Card.Item>
      <Card.Section>
        <Stack.Item>Kg conferiti: {numberFormat.format(delivered)}</Stack.Item>
        <Stack.Item>Kg campionati: {numberFormat.format(sampled)}</Stack.Item>
        <Stack.Item><Text variant="xxLarge" styles={{ root: { color: ratio < 1 ? palette.red : palette.green } }}>{percentFormat.format(ratio)}</Text></Stack.Item>
      </Card.Section>
      <Card.Section horizontal horizontalAlign="center" tokens={cardsToken}>
        {children}
      </Card.Section>
    </Card>
  );
}
