import * as React from 'react';
import { Label, Panel, Stack, Text, ILabelStyles, ITheme } from '@fluentui/react';
import { IDeliveryNotice } from '../../../models/deliveryNotice';

interface IDeliveryNoticeViewerProp {
  isOpen: boolean,
  onClose: () => void;
  deliveryNotice: IDeliveryNotice;
  theme: ITheme;
}

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  fieldStack: {
    childrenGap: 0,
  },
};

const DeliveryNoticeViewer: React.FC<IDeliveryNoticeViewerProp> = ({
  deliveryNotice,
  isOpen,
  onClose,
  theme
}) => {


  const labelStyle = {
    root: {
      color: theme.palette.themePrimary,
    }
  } as ILabelStyles;

  return (
    <>
      <Panel
        headerText="Prenotazione"
        isOpen={isOpen}
        onDismiss={() => onClose()}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <Stack tokens={tokens.sectionStack}>

          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Fornitore</Label>
            <Text>{deliveryNotice.partnerName} ({deliveryNotice.partnerCode})</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Data consegna</Label>
            <Text>{new Date(deliveryNotice.deliveryDate).toLocaleDateString()}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Periodo consegna</Label>
            <Text>{deliveryNotice.deliveryPeriodDesc || "-"}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Varietà</Label>
            <Text>{deliveryNotice.varietyDesc} ({deliveryNotice.varietyCode})</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Qualità</Label>
            <Text>{deliveryNotice.qualityDesc || "-"}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Imballo</Label>
            <Text>{deliveryNotice.packagingDesc} ({deliveryNotice.packagingCode})</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Nr. colli</Label>
            <Text>{deliveryNotice.parcels}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Pedana</Label>
            <Text>{(deliveryNotice.palletDesc && deliveryNotice.palletCode) ? deliveryNotice.palletDesc + " (" + deliveryNotice.palletCode + ")" : "-"}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Nr. pedane</Label>
            <Text>{deliveryNotice.pallets || "-"}</Text>
          </Stack>
          <Stack tokens={tokens.fieldStack}>
            <Label styles={labelStyle}>Note</Label>
            <Text>{deliveryNotice.notes || "-"}</Text>
          </Stack>
        </Stack>

      </Panel>
    </>
  );
};

export default DeliveryNoticeViewer;
