import React from 'react';
import { mergeStyles } from '@fluentui/react';

const style = mergeStyles({ fontFamily: "Segoe UI Light" });

const Title2: React.FC = ({ children }) => {

    return <h2 className={style}>{children}</h2>
}

export default Title2;