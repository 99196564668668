import { IPartialTheme, ITheme } from '@fluentui/react';
import { IHttpClient } from '../../shared/easyAxios';

export const CONTEXT_LOADING = "CONTEXT_LOADING";
export const CONTEXT_LOADED = "CONTEXT_LOADED";
export const CONTEXT_FETCH = "CONTEXT_FETCH";
export const CONTEXT_ERROR = "CONTEXT_ERROR";
export const SET_THEME = "SET_THEME";
export const GLOBAL_SET_SEARCH_FILTER = "GLOBAL_SET_SEARCH_FILTER";
export const SET_FULL_THEME = "SET_FULL_THEME";

export interface ISetSearchExpressionAction{
  type: typeof GLOBAL_SET_SEARCH_FILTER;
  searchExpression: string;
}

export interface ISetThemeAction{
  type: typeof SET_THEME;
  theme: ThemeName;
}

export interface IContextFetchAction {
  type: typeof CONTEXT_FETCH,
  easyClient: IHttpClient
}

export interface IContextErrorAction {
  type: typeof CONTEXT_ERROR
}

export interface IContextLoadingAction {
  type: typeof CONTEXT_LOADING;
  domain: string;
}

export interface IContextLoadedAction {
  type: typeof CONTEXT_LOADED;
  contextData: IContextAction;
}

export interface ISetFullThemeAction{
  type: typeof SET_FULL_THEME;
  theme: ITheme
}

export type ContextActionTypes = IContextFetchAction | IContextErrorAction |
  IContextLoadedAction | IContextLoadingAction |
  ISetThemeAction | ISetSearchExpressionAction | ISetFullThemeAction;

export enum UserRole {
  Admin = "Admin",
  Owner = "Owner",
  DocumentManager = "DocumentManager"
}

export interface IGroup {
  id: string;
  description: string;
  publishableDocTypeIds: string[];
  receivableDocTypeIds: string[];
};

export interface IDocumentType {
  id: string;
  name: string;
  area: string;
  section: string;
}

export interface IAreas {
  [area: string]: number;
}

export interface INavRoute {
  key: string;
  text: string;
  url: string;
  section?: string;
}

export interface IContextState {
  username: string;
  displayname: string;
  roles: UserRole[];
  groups: IGroup[];
  partners: string[];
  documentType: IDocumentType[]
  loading: boolean;
  topMenu: INavRoute[];
  menuByArea: Record<string, INavRoute[]>;
  error: boolean;
  licenseDomain?: string;
  currentDomain: string;
  modules: string[],
  licenseEndValidityDate?: Date;
  theme: ThemeName;
  fullTheme: ITheme;
  globalSearchExpression: string;
}

export interface ILicenseInfo {
  modules: string[];
  domain: string;
  endValidity: string;
}

export interface IContextAction {
  username: string;
  displayname: string;
  roles: string[];
  groups: IGroup[];
  partners: string[];
  docTypes: IDocumentType[];
  areas?: IAreas;
  licenseInfo?: ILicenseInfo;
}

export interface ICompanyData {
  name: string,
  shortName?: string,
  address?: string
}

export type ThemeName = "themeLight" | "themeDark";

export interface IConfigState {
  initializing: boolean;
  easyApiUrl: string;
  clientId: string;
  authority: string;
  redirectUri: string;
  themeLight: IPartialTheme;
  themeDark: IPartialTheme;
  companyData: ICompanyData;
}
