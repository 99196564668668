import { IAreas, IDocumentType, INavRoute, UserRole } from '../types/context';

export const buildMenu = (
  partners: string[],
  roles: UserRole[],
  documentTypes: IDocumentType[],
  areas: IAreas,
  modules: string[]) => {

  // aree from documentType
  const menu = documentTypes.reduce<Record<string, INavRoute[]>>((agg, current) => {
    const area = getArea(current.area);
    agg[area] = agg[area] ?? [];
    agg[area].push({
      key: "documentTypes_" + current.id,
      text: current.name,
      url: "/documenti/" + current.id,
      section: current.section ? current.section : "Documenti"
    })
    return agg;
  }, {});

  // aree soci
  if (canViewProvisionReport(modules, partners, roles)) {
    menu["soci"] = menu["soci"] ?? [];
    // provision reports
    menu["soci"].push({
      key: "000_Report Conferimento",
      text: "Report Conferimento",
      url: "/area/soci/select-provision-report",
      section: "Conferimento"
    })
  }

  if (canViewDeliveryNotice(modules, partners, roles)) {
    // delivery notice
    menu["soci"] = menu["soci"] ?? [];
    menu["soci"].push({
      key: "001_Prenotazioni Consegne",
      text: "Prenotazioni Consegne",
      url: "/area/soci/delivery-notice",
      section: "Prenotazione consegne"
    });
  }

  const topMenuUnorder = Object.keys(menu);
  const mapped = topMenuUnorder.map((menu, i) => ({ index: i, order: (areas ? areas[menu] : 100) ?? 100 }));
  mapped.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    }
    if (a.order < b.order) {
      return -1;
    }
    return 0
  });
  const topMenuOrdered = mapped.map(menu => createAreaMenu(topMenuUnorder[menu.index]))

  const menuSortByText = Object.keys(menu).reduce<Record<string, INavRoute[]>>((agg, current) => {
    agg[current] = [...menu[current]].sort((a, b) => {
      if(!a.section || !b.section){
        return -1;
      }
      const compareSection = a.section.localeCompare(b.section);
      if (compareSection !== 0) {
        return compareSection;
      }
      return a.text.localeCompare(b.text)
    });
    return agg;
  }, {});
  return { topMenu: topMenuOrdered, menuByArea: menuSortByText };
}

const getArea = (area: string) => area || "partner";


const createAreaMenu = (area: string): INavRoute => {
  return {
    key: keyForArea(area),
    url: "/area/" + encodeURIComponent(area),
    text: "Area " + area
  }
}

const roleCanViewDeliveryNotice: string[] = ["Admin", "Owner", "DocumentManager"];
export function canViewDeliveryNotice(modules: string[], partners: string[], roles: UserRole[]) {
  return modules.includes("DeliveryNotice") && (partners.length || roles.some(r => roleCanViewDeliveryNotice.includes(r)));
}

const roleCanViewProvisionReport: string[] = ["Admin", "Owner", "DocumentManager"];
export function canViewProvisionReport(modules: string[], partners: string[], roles: UserRole[]) {
  return modules.includes("ProvisionReport") && (partners.length || roles.some(r => roleCanViewProvisionReport.includes(r)));
}

function keyForArea(area: string): string {
  return "area_" + area;
}

