import { Icon, ITheme } from '@fluentui/react';
import React from 'react';
import styleFactory from './MetadataSet.style';

interface IMetadataTypeConfig {
  iconName: string;
  description: string;
  getDisplayValue: (value: any) => string;
}

const matadataTypeConfig: Record<string, IMetadataTypeConfig> =
{
  "partner": { iconName: "AccountManagement", description: "Partner", getDisplayValue: value => value },
  "ref_no": { iconName: "NumberSymbol", description: "Numero documento", getDisplayValue: value => value },
  "ref_date": { iconName: "EventDate", description: "Data documento", getDisplayValue: value => new Date(value).toLocaleDateString() },
  "bill_cust": { iconName: "PartyLeader", description: "Cliente fatturazione", getDisplayValue: value => value },
  "ship_cust": { iconName: "DeliveryTruck", description: "Cliente spedizione", getDisplayValue: value => value },
};


interface IMetadataInfo {
  iconName: string;
  description: string;
  displayValue: string;
}


// interface IOverflowData {
//   primary: IMetadataInfo[];
//   overflow: IMetadataInfo[];
//   cacheKey?: string;
// }

// const onRenderData = (data: any) => (
//   <OverflowSet
//     //role="menubar"
//     vertical
//     items={data.primary}
//     overflowItems={data.overflow.length ? data.overflow : null}
//     onRenderItem={onRenderItem}
//     onRenderOverflowButton={onRenderOverflowButton}
//   />
// );

// const onReduceData = (currentData: any): any => {
//   if (currentData.primary.length === 0) {
//     return undefined;
//   }
//   const overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
//   const primary = currentData.primary.slice(0, -1);
//   return { primary, overflow };
// };


export interface IMetadataSetProps {
  items: Record<string, any>,
  showMetaNumber?: boolean,
  metaNumber?: number,
  theme: ITheme
}

// const styles = styleFactory();
//   const iconMap = matadataIconMap[props.metaKey];
//   return <li><Icon iconName={iconMap?.iconName || "BookAnswer"} title={iconMap?.description || props.metaKey} /><span>{props.metaValue}</span></li>;

const MAX_DISPLAYED_METADATA = 99;

const MetadataSet: React.FunctionComponent<IMetadataSetProps> = ({ items, showMetaNumber, metaNumber, theme }) => {
  const classes = styleFactory(theme);
  // const metaKey = "partner";
  // const metaValue = items[metaKey];
  //const metaMap = matadataTypeMap[metaKey];

  const metadataItemsArray: IMetadataInfo[] = [];
  let i = 0;
  for (let metaKey in items) {
    const metaConfig = matadataTypeConfig[metaKey];

    if (i < MAX_DISPLAYED_METADATA) {
      metadataItemsArray.push({
        description: metaConfig?.description || metaKey,
        iconName: metaConfig?.iconName || "BookAnswers",
        displayValue: metaConfig?.getDisplayValue(items[metaKey]) || items[metaKey],
      });
    }
    i++;
  }

  return <div className={classes.root}>
    {showMetaNumber === true && metaNumber && (
      <div className={classes.metaNumber}>{metaNumber}</div>
    )}
    <ul className={classes.metadataList}>
      {metadataItemsArray.map((m, index) => {
        return <li key={`meta_${index}`}>
          <Icon className={classes.metadataListIcon} iconName={m.iconName} title={m.description} />
          <div className={classes.metadataListText}>
            <div className={classes.metadataKey}>{m.description}</div>
            <div className={classes.metadataValue}>{m.displayValue}</div>
          </div>
        </li>
      })}
      {(i > MAX_DISPLAYED_METADATA) && (
        <li key="meta_more"><div className={classes.moreMetadata}>+ altri {(i - MAX_DISPLAYED_METADATA)}</div></li>
      )}

    </ul>
  </div >;
};

export default MetadataSet;
