import { IStyle, IProcessedStyleSet, mergeStyleSets, ITheme } from "@fluentui/react"
import { desktop } from '../../shared/mediaQuery';

interface ILayoutStyle {
  layout: IStyle;
  headerLayout: IStyle;
  navigationSideLayout: IStyle;
  mainLayout: IStyle;
}

const layoutStyleFactory = (theme: ITheme): IProcessedStyleSet<ILayoutStyle> => {

  return mergeStyleSets<ILayoutStyle>({
    layout: {
      display: "grid",
      boxSizing: "border-box",
      gridTemplateColumns: "100%",
      //gridTemplateColumns: "15.6rem auto",
      gridTemplateRows: "fit-content(3.3rem) auto fit-content(3.3rem)",
      gridTemplateAreas:
        `"header"
                "main"
                "footer"`,
      height: "100%",
      // selectors: {
      //     [desktop]: {
      //         gridTemplateRows: "fit-content(3.3rem) auto fit-content(3.3rem)",
      //         gridTemplateAreas:
      //             `"header"
      //             "main"
      //             "footer"`,
      //     }
      // },
      color: theme.semanticColors.bodyText
    },
    headerLayout: {
      gridArea: "header"
    },
    navigationSideLayout: {
      display: "none",
      gridArea: "side",
      selectors: {
        [desktop]: {
          display: "block"
        }
      }
    },
    mainLayout: {
      gridArea: "main",
      padding: "0",
      // margin: "0.5rem",
      selectors: {
        [desktop]: {
          width: "auto",
          //maxWidth: "960px",
          //margin: "0 auto",
          //padding: "0 0.5rem",
        }
      }
    },
  })
}

export default layoutStyleFactory;
