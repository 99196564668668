import { DefaultButton, Icon, IconButton, Image, ITheme } from '@fluentui/react';
import React, { CSSProperties, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { getFileExtension, getFileNameFromUrl, getFileNameWithoutExtension } from '../../../shared/utility';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import filesize from 'filesize';
import { IFileInputValue } from '../../../types/FileInput'

export interface IFileInputProps {
  value?: IFileInputValue;
  accepts?: string[];
  onChange: (value?: IFileInputValue) => void;
  disabled?: boolean;
  showFileIcon?: boolean;
  showImagePreview?: boolean;
  previewMaxHeight?: string;
  theme: ITheme;
}

const FileDropInput: React.FC<IFileInputProps> = (props) => {
  const { showFileIcon = true, showImagePreview = false, previewMaxHeight = "200px", theme } = props;
  const [selectedFile, setSelectedFile] = useState<IFileInputValue | undefined>(props.value);


  const { getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({
      maxFiles: 1,
      multiple: false,
      noClick: true,
      noKeyboard: true,
      disabled: props.disabled || false,
      accept: props.accepts,
      onDrop: (accepted, rejected, ev) => {
        if (Object.keys(rejected).length !== 0) {
          setSelectedFile(undefined);
          props.onChange();
        } else {
          const acceptedFile = accepted[0];

          const fileBlobPromise = new Promise<string | null>((resolve, reject) => {
            const reader = new window.FileReader();
            reader.readAsDataURL(acceptedFile);
            reader.onloadend = () => {
              const base64data = reader.result;
              resolve(base64data as string);
            };
            reader.onerror = (e) => {
              reject(e?.target?.error || "Errore durante il recupero del contenuto del file");
            }
          });

          fileBlobPromise.then(
            //fulfilled
            (fileDataURL) => {
              const fileInputSelectedValue: IFileInputValue = {
                fileName: getFileNameFromUrl(acceptedFile.name),
                fileSize: acceptedFile.size,
                fileNameWithoutExtension: getFileNameWithoutExtension(acceptedFile.name),
                fileNameExtension: getFileExtension(acceptedFile.name) || "",
                fileDataURL: fileDataURL ?? undefined
              };
              props.onChange(fileInputSelectedValue);
              setSelectedFile(fileInputSelectedValue);

            },

            // rejected: segnalo l'errore e lascio tutto aperto
            (err) => {
              window.alert("Errore durante la lettura del file.\n" + err);
              console.log("Errore durante la lettura del file.", err);
            }
          );
        }
      }
    });

  const style = useMemo(() => {
    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: theme.palette.neutralQuaternary, //'#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: theme.palette.neutralLighterAlt, //'#fafafa',
      color: theme.palette.neutralSecondary,//'#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
      borderColor: '#2196f3'
    };

    const acceptStyle = {
      borderColor: '#00e676'
    };

    const rejectStyle = {
      borderColor: '#ff1744'
    };

    return ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    })
  }, [
    isDragActive,
    isDragReject,
    isDragAccept,
    theme
  ]) as CSSProperties;

  const handleRemoveFile = () => {
    setSelectedFile(undefined);
    props.onChange();
  }

  return (
    <div>
      {selectedFile === undefined && (<div {...getRootProps({ style: { ...style } })}>
        <input {...getInputProps()} />
        <p>Trascina qui il file, oppure clicca sul pulsante per cercarlo</p>
        <DefaultButton onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); open(); }} disabled={props.disabled}>
          Seleziona un file
        </DefaultButton>
      </div>)
      }
      {selectedFile !== undefined && showFileIcon && (
        <div key={selectedFile.fileName} style={{ display: "flex", alignItems: "center", padding: "0.5em 0", width: "fit-content", maxWidth: "100%", backgroundColor: theme.palette.neutralLighter }}>
          <Icon {...getFileTypeIconProps({ extension: selectedFile.fileNameExtension || "", size: 40 })} styles={{ root: { flexShrink: 0 } }} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "start", padding: "0 0.5em" }}>
            <span style={{ fontSize: "0.85rem" }}>{selectedFile.fileName || ""}</span>
            <span style={{ fontSize: "0.7rem" }}>{filesize(selectedFile.fileSize || 0)}</span>
          </div>
          <IconButton styles={{ root: { flexShrink: 0, width: 40, height: 40 } }} iconProps={{ iconName: "Delete" }} onClick={handleRemoveFile} />
        </div>
      )}
      {selectedFile !== undefined && showImagePreview && selectedFile && selectedFile.fileDataURL && selectedFile.fileDataURL.startsWith("data:image/") && (
        <div style={{ display: "flex", alignItems: "center", padding: "0.5em 0", width: "fit-content", maxWidth: "100%", backgroundColor: theme.palette.neutralLighter }}>
          <Image src={selectedFile.fileDataURL} styles={{ root: {}, image: { minWidth: 50, minHeight: 50, height: "auto", maxHeight: previewMaxHeight, maxWidth: "100%" } }} />
          <IconButton styles={{ root: { flexShrink: 0, width: 40, height: 40 } }} iconProps={{ iconName: "Delete" }} onClick={handleRemoveFile} />
        </div>
      )}
    </div >
  );
}

export default FileDropInput;

