import { updateObject } from '../../shared/utility';
import { easyThemes } from './config.theme';
import { IConfigState } from '../types/context';
import {
  APP_SET_CONFIG_DONE,
  APP_SET_CONFIG_DEFAULT,
  ConfigActions,
  IEasyConfig
} from '../types/config';

const initialState: IConfigState = {
  initializing: true,
  //easyApiUrl: "https://localhost:44350",
  easyApiUrl: "http://localhost:5000",
  authority: "https://login.microsoftonline.com/common",
  clientId: "b624f1ef-3caf-4fbb-b4b0-7cbd0abf7af5",
  redirectUri: "http://localhost:3000/spa",
  themeLight: easyThemes.light,
  themeDark: easyThemes.dark,
  companyData:{
    name: "Easy Collaboration"
  }
}

const setConfig = (state: IConfigState, easyConfig: IEasyConfig): IConfigState => {
  return updateObject(state, {
    initializing: false,
    easyApiUrl: easyConfig?.easyApiUrl ?? state.easyApiUrl,
    clientId: easyConfig?.auth?.clientId ?? state.clientId,
    authority: easyConfig?.auth?.authority ?? state.authority,
    redirectUri: easyConfig?.auth?.redirectUri ?? state.redirectUri,
    themeLight: easyConfig?.themes?.light ?? state.themeLight,
    themeDark: easyConfig?.themes?.dark ?? state.themeDark,
    companyData: easyConfig?.company ?? state.companyData
  })
}

const setConfigDefault = (state: IConfigState): IConfigState => {
  return updateObject(state, { initializing: false })
}

const reducer = (state: IConfigState = initialState, action: ConfigActions): IConfigState => {
  switch (action.type) {
    case APP_SET_CONFIG_DONE: return setConfig(state, action.easyConfig);
    case APP_SET_CONFIG_DEFAULT: return setConfigDefault(state);
  }
  return state;
}

export default reducer;
