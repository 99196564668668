import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CommandBar from '../../components/DeliveryNotice/CommandBar/CommandBar';
import DetailList from '../../components/DeliveryNotice/Details/DeliveryNoticeDetailsList';
import DetailForm from '../../components/DeliveryNotice/DetailForm/DeliveryNoticeDetailForm';
import DetailViewver from '../../components/DeliveryNotice/DetailViewer/DeliveryNoticeDetailViewer';
import Page from '../../hoc/Page/Page';
import { IDeliveryNotice, IDeliveryNoticePathProps, IFormDataDeliveryNotice, IItem, IPartner, ITableItem } from '../../models/deliveryNotice';
import { IODataList } from '../../models/odata';
import { ISelection, omit } from '@fluentui/react';
import { Pagination } from '../../components/UI/Pagination/Pagination';
import { IHttpClient } from '../../shared/easyAxios';
import { RouteComponentProps } from 'react-router-dom';
import withEasyHttp from '../../hoc/HttpClientWrapper/HttpClientWrapper';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { IOptionConfig } from '../../types/Form';
import { IDeliveryNoticeFormDefinition } from '../../types/DeliveryNotice'
import { defaultNumberParser } from '../../shared/utility';

const mapStateToProps = (state: IEasyStore) => {
  return {
    searchFilter: state.context.globalSearchExpression,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>

const getOptions: <T>(items: T[], mapFunc: (i: T) => IOptionConfig, addEmptyOption?: boolean) => IOptionConfig[] =
  (items, mapFunc, addEmptyOption?) => {
    const options: IOptionConfig[] = [];
    addEmptyOption && addEmptyOption === true && options.push({ option: "", text: "", selected: false });
    items && mapFunc && options.push(...items.map(mapFunc));
    return options;
  };

const mapPartners: (i: IPartner) => IOptionConfig = (i) => { return { option: i.id, text: i.name, selected: false } as IOptionConfig };
const mapItems: (i: IItem) => IOptionConfig = (i) => { return { option: i.itemCode, text: i.itemDescription, selected: false } as IOptionConfig };
const mapTables: (i: ITableItem) => IOptionConfig = (i) => { return { option: i.code, text: i.desc, selected: false } as IOptionConfig };

const createFormDefinition = (deliveryNotice: IDeliveryNotice, partners: IPartner[], varieties: IItem[], packagings: IItem[], pallets: IItem[], qualities: ITableItem[], deliveryPeriods: ITableItem[]): IDeliveryNoticeFormDefinition => {
  const deliveryDate = deliveryNotice.deliveryDate && new Date(deliveryNotice.deliveryDate);
  return {
    "partner": {
      elementType: "select",
      elementConfig: {
        label: 'Fornitore',
        options: getOptions<IPartner>(partners, mapPartners)
      },
      value: deliveryNotice.partnerCode || (partners.length === 1 ? partners[0].id : ""),
      // displayValue: deliveryNotice.partnerName || (partners.length === 1 && partners[0].name),
      validation: {
        required: true
      }
    },
    "deliveryDate": {
      elementType: "date",
      elementConfig: {
        label: 'Data consegna',
        //placeholder: 'Specificare la data di consegna'
      },
      value: deliveryDate,
      // displayValue: deliveryDate?.toLocaleDateString(),
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    "deliveryPeriod": {
      elementType: "select",
      elementConfig: {
        label: 'Periodo consegna',
        options: getOptions<ITableItem>(deliveryPeriods, mapTables, true),
      },
      value: deliveryNotice.deliveryPeriodCode || "",
      // displayValue: deliveryNotice.deliveryPeriodDesc,
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    "variety": {
      elementType: "select",
      elementConfig: {
        label: 'Varietà',
        options: getOptions<IItem>(varieties, mapItems),
      },
      value: deliveryNotice.varietyCode,
      // displayValue: deliveryNotice.varietyDesc,
      validation: {
        required: true,
      },
      valid: false,
      touched: false
    },
    "quality": {
      elementType: "select",
      elementConfig: {
        label: 'Qualità',
        options: getOptions<ITableItem>(qualities, mapTables, true),
      },
      value: deliveryNotice.qualityCode || "",
      // displayValue: deliveryNotice.qualityDesc,
      validation: {
        required: false,
      },
      valid: false,
      touched: false
    },
    "packaging": {
      elementType: "select",
      elementConfig: {
        label: 'Imballo',
        options: getOptions<IItem>(packagings, mapItems),
      },
      value: deliveryNotice.packagingCode,
      // displayValue: deliveryNotice.packagingDesc,
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    "parcels": {
      elementType: "number",
      elementConfig: {
        label: 'Nr. colli',
        min: 0,
        max: 999,
        step: 1
      },
      value: deliveryNotice.parcels?.toLocaleString(),
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    "pallet": {
      elementType: "select",
      elementConfig: {
        label: 'Pedana',
        options: getOptions<IItem>(pallets, mapItems, true),
        //placeholder: 'Inserire la pedana',
      },
      value: deliveryNotice.palletCode || "",
      // displayValue: deliveryNotice.palletDesc,
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    "pallets": {
      elementType: "number",
      elementConfig: {
        label: 'Nr. pedane',
        //placeholder: 'Inserire il numero di pedane',
        min: 0,
        max: 100,
        step: 1,
      },
      value: deliveryNotice.pallets?.toLocaleString() || "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
    "notes": {
      elementType: "multiline",
      elementConfig: {
        label: 'Note',
      },
      value: deliveryNotice.notes || "",
      validation: {
        required: false
      },
      valid: false,
      touched: false
    },
  }
}

interface IPaginationInfo {
  currPage: number,
  pageSize: number,
}


const compareStrings: (a: string, b: string) => number = (A, B) => {
  const a = A?.toLowerCase() || "";
  const b = B?.toLowerCase() || "";

  return a < b ? -1 : a > b ? 1 : 0;
}

const sortDeliveryNotice: (a: IDeliveryNotice, b: IDeliveryNotice) => number = (a, b) => {
  return (
    (a.deliveryDate ? new Date(a.deliveryDate).valueOf() : 0) - (b.deliveryDate ? new Date(b.deliveryDate).valueOf() : 0) ||
    compareStrings(a.deliveryPeriodCode ?? "", b.deliveryPeriodCode ?? "") ||
    compareStrings(a.partnerName, b.partnerName) ||
    compareStrings(a.varietyCode, b.varietyCode)
  );
};

const getSearchPot = (dn: IDeliveryNotice) => {
  let pot: string[] = [];
  dn.partnerCode && pot.push(dn.partnerCode);
  dn.partnerName && pot.push(dn.partnerName);
  dn.varietyCode && pot.push(dn.varietyCode);
  dn.varietyDesc && pot.push(dn.varietyDesc);
  dn.qualityDesc && pot.push(dn.qualityDesc);
  dn.notes && pot.push(dn.notes);
  return pot.join(" ").toLowerCase();
};

const filterDeliveryNotices: (arr: IDeliveryNotice[], searchString?: string) => IDeliveryNotice[] = (arr, searchString) => {
  const searchTerms = (searchString && searchString?.toLowerCase().split(' ').filter(t => t && t.trim().length > 0)) || [];

  if (searchTerms.length === 0) return arr.slice(); // restituisco una copia

  return arr.filter(dn => {
    const searchPot = getSearchPot(dn);
    for (let t in searchTerms) {
      if (searchPot.indexOf(searchTerms[t]) < 0) return false; // al primo non matchato scarto l'elemento
    }

    return true;
  });
};

export interface IDeliveryNoticeProps extends RouteComponentProps<IDeliveryNoticePathProps>, PropsFromStore {
  easyClient: IHttpClient;
  searchFilter: string;
}

export const DeliveryNotice: React.FC<IDeliveryNoticeProps> = ({ theme, match, history, easyClient, searchFilter }) => {

  const [allItems, setAllItems] = useState<IDeliveryNotice[]>([]);

  const [itemsAreLoading, setItemsAreLoading] = useState(true);

  const [allPartners, setAllPartners] = useState<IPartner[]>([]);
  const [allVarieties, setAllVarieties] = useState<IItem[]>([]);
  const [allPackagings, setAllPackagings] = useState<IItem[]>([]);
  const [allPallets, setAllPallets] = useState<IItem[]>([]);
  const [allQualities, setAllQualities] = useState<ITableItem[]>([]);
  const [allDeliveryPeriods, setAllDeliveryPeriods] = useState<ITableItem[]>([]);

  const [selectedItems, setSelectedItems] = useState<IDeliveryNotice[]>([])
  const [formDefinition, setFormDefinition] = useState<IDeliveryNoticeFormDefinition>(createFormDefinition({} as IDeliveryNotice, [], [], [], [], [], []));
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState<boolean>(false);
  const [currentEditItem, setCurrentEditItem] = useState<IDeliveryNotice>({} as IDeliveryNotice);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [pagingInfo, setPagingInfo] = useState<IPaginationInfo>({ currPage: 0, pageSize: 5 });
  const [filteredItemsCount, setFilteredItemsCount] = useState(0);
  const [refreshView, setRefreshView] = useState({});

  const [userIsDocumentManager, setUserIsDocumentManager] = useState<boolean | undefined>(undefined);

  const items: IDeliveryNotice[] = useMemo(() => {

    const filteredItems = filterDeliveryNotices(allItems, searchFilter);

    // const filterLowerCase = searchFilter?.toLowerCase();

    // const filteredItems = (filterLowerCase && filterLowerCase.length > 0)
    //   ? allItems.filter(i =>
    //     i.partnerName.toLowerCase().indexOf(filterLowerCase) > -1 ||
    //     i.varietyDesc.toLowerCase().indexOf(filterLowerCase) > -1 ||
    //     (i.notes && i.notes.toLowerCase().indexOf(filterLowerCase) > -1))
    //   : allItems;

    filteredItems.sort(sortDeliveryNotice);

    const startIndex = Math.max(0, pagingInfo.currPage * pagingInfo.pageSize);
    const endIndex = Math.max(startIndex, startIndex + pagingInfo.pageSize);

    setFilteredItemsCount(filteredItems.length);

    return (filteredItems.slice(startIndex, endIndex));
  }, [allItems, searchFilter, pagingInfo.currPage, pagingInfo.pageSize]);


  const initFormForNewItem: () => void = useCallback(() => {
    //alert("inizializzazione form");
    let item = {} as IDeliveryNotice;
    setFormDefinition(createFormDefinition(item, allPartners, allVarieties, allPackagings, allPallets, allQualities, allDeliveryPeriods));
    setIsDetailPanelOpen(true);
    setCurrentEditItem(item)
  }, [allDeliveryPeriods, allPackagings, allPallets, allPartners, allQualities, allVarieties]);

  const removeItems: (itemsToRemove: IDeliveryNotice[]) => void =
    useCallback((itemsToRemove: IDeliveryNotice[]) => {
      var localAllItems = [...allItems];
      for (let i = 0; i < itemsToRemove.length; i++) {
        const itemToRemove = itemsToRemove[i];
        var index = localAllItems.indexOf(itemToRemove);
        if (index !== -1) {
          localAllItems.splice(index, 1);
        }
      }
      if (allItems.length !== localAllItems.length) {
        setAllItems(localAllItems);
        setSelectedItems([]);
        const maxPage = Math.max(0, Math.ceil(localAllItems.length / pagingInfo.pageSize) - 1);
        if (pagingInfo.currPage > maxPage) {
          setPagingInfo({ ...pagingInfo, currPage: maxPage });
        }

      }
    }, [allItems, pagingInfo]);

  const onDeleteInvoked: () => void = useCallback(() => {
    if (window.confirm('Eliminare ' + selectedItems.length + ' elementi?')) {
      //window.alert('Avresti eliminato');
      //allItems.splice(0,selectedItems.length,) TODO: eliminare gli elementi selezionati
      if (selectedItems.length > 0) {
        setItemsAreLoading(true);
        const selectedId = selectedItems[0].id;
        easyClient.delete(`/api/DeliveryNotices('${selectedId}')`, { headers: { "X-version": "1.0" } })
          .then(response => {
            removeItems([selectedItems[0]]);
            setSelectedItems([]);
          })
          .catch(err => {
            console.log("Errore: " + err);
          })
          .finally(() => {
            setItemsAreLoading(false);
          });
      }
    } else {
      window.alert('operazione annullata');
    }
  }, [removeItems, selectedItems, easyClient]);

  // const testGetFile = (id: string) => {
  //     setItemsAreLoading(true);
  //     easyClient.get(`/api/Documents('${id}')/FileNameStorage`, { headers: { "X-version": "1.0" }, responseType: "blob" })
  //         .then(response => {
  //             console.log(response);
  //             let url = window.URL.createObjectURL(response.data);
  // 				let a = document.createElement('a');
  // 				a.href = url;
  // 				a.download = `file_${id}.pdf`;
  // 				a.click();
  //         })
  //         .catch(err => {
  //             console.log("Errore: " + err);
  //         })
  //         .finally(() => {
  //             setItemsAreLoading(false);
  //         });
  // }

  useEffect(() => {
    setPagingInfo({ ...pagingInfo, currPage: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, refreshView])

  const pageChanged: (page: number) => void = (page) => {
    page = page - 1;
    const maxPage = Math.max(0, Math.ceil(allItems.length / pagingInfo.pageSize) - 1);
    setPagingInfo({ ...pagingInfo, currPage: Math.min(maxPage, Math.max(0, page)) });
    //loadItems();
  }

  const selectionChanged: (selection: ISelection) => void = (selection) => {
    let localSelectedItems: IDeliveryNotice[] = [];
    if (selection) {
      //selectionCount = selection.getSelectedCount();
      localSelectedItems = selection.getSelection().map(s => s as IDeliveryNotice);
    }
    setSelectedItems(localSelectedItems);
  };

  const selectionCount = useMemo(() => {
    return selectedItems.length;
  }, [selectedItems.length])

  const selectionMessage = useMemo<string>(() => {
    switch (selectedItems?.length || 0) {
      case 0:
        return 'Nessun elemento selezionato';
      case 1:
        return `1 elemento selezionato: ${(selectedItems[0] as IDeliveryNotice)?.varietyDesc}`;
      default:
        return `${selectedItems.length} elementi selezionati`;
    }
  }, [selectedItems]);

  const totalPages = useMemo<number>(() => {
    return Math.ceil(filteredItemsCount / pagingInfo.pageSize);
  }, [filteredItemsCount, pagingInfo.pageSize]);

  const closeDetailPanel = () => {
    resetPath();
    setIsDetailPanelOpen(false);
  }

  const resetPath = useCallback(() => {
    //match.params.id && history.replace(window.location.href.replace(match.params.id,''));//  "/delivery-notice");
    match.params.id && history.replace('/area/soci/delivery-notice');//  "/delivery-notice");
  }, [history, match.params.id])

  const invokeItem = useCallback((item?: IDeliveryNotice) => {
    if (item) {
      setCurrentEditItem(item);
      if (!userIsDocumentManager) {
        setFormDefinition(createFormDefinition(item, allPartners, allVarieties, allPackagings, allPallets, allQualities, allDeliveryPeriods));
      }
      setIsDetailPanelOpen(true);
    }
  }, [allDeliveryPeriods, allPackagings, allPallets, allPartners, allQualities, allVarieties, userIsDocumentManager]);

  useEffect(() => {
    setItemsAreLoading(true);

    const fetchGridData = easyClient.get<IODataList<IDeliveryNotice>>("/api/DeliveryNotices", { headers: { "X-version": "1.0" } });
    const fetchFormData = easyClient.get<IFormDataDeliveryNotice>("/easyportal/formdata-deliverynotice", { headers: { "X-version": "1.0" } });

    Promise.all([fetchGridData, fetchFormData])
      .then(([gridDataReposnse, formDataResponse]) => {

        // fetchFormData
        setUserIsDocumentManager(formDataResponse.data.documentManagerMode);
        setAllPartners(formDataResponse.data.partners);
        setAllVarieties(formDataResponse.data.varieties);
        setAllPackagings(formDataResponse.data.packagings);
        setAllPallets(formDataResponse.data.pallets);
        setAllQualities(formDataResponse.data.qualities);
        setAllDeliveryPeriods(formDataResponse.data.deliveryPeriods);

        // fetchGridData
        setAllItems(gridDataReposnse.data.value);
      })
      .catch(err => {
        console.log(err);
        setAllItems([]);
        setAllPartners([]);
        setAllVarieties([]);
        setAllPackagings([]);
        setAllPallets([]);
        setAllQualities([]);
        setAllDeliveryPeriods([]);
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  }, [easyClient, refreshView]);

  const refreshViewHandler = () => {
    setRefreshView({});
    setIsDetailPanelOpen(false);
    setCurrentEditItem({} as IDeliveryNotice);
  }

  useEffect(() => {
    if (!itemsAreLoading && match.params.id) {
      const pathItem = allItems.find(item => item.id === (match.params.id || ""));
      if (pathItem) {
        // elemento trovato => lo apro
        setSelectedItems([pathItem]);
        invokeItem(pathItem);
      } else {
        // elemetno NON trovato => errore + push history
        console.error(`Elemento con id '${match.params.id}' non trovato`);
        resetPath();
      }
    }
  }, [allItems, invokeItem, itemsAreLoading, match.params.id, resetPath])

  //<Link onClick={() => testGetFile("cosmos")}>Download</Link>

  return (
    <Page title="Prenotazione consegne" iconName="DeliveryTruck" pageWidth="100%" theme={theme}>

      <CommandBar
        onNewItem={initFormForNewItem}
        onDeleteItem={onDeleteInvoked}
        refreshClicked={refreshViewHandler}
        showCreate={userIsDocumentManager === false}
        showDelete={userIsDocumentManager === false}
        createDisabled={false} // è nascosto se non può usarlo
        deleteDisabled={selectionCount === 0}
      />
      <DetailList
        items={items}
        itemsAreLoading={itemsAreLoading}
        isFormDetailOpen={isDetailPanelOpen}
        isMultiPartner={userIsDocumentManager === true || allPartners?.length > 1}
        //onFilter={(e, text) => { filterChanged(text || ""); }}
        searchFilter={searchFilter}
        onItemInvoked={invokeItem}
        onSelectionChanged={selectionChanged}
        selectionDetails={selectionMessage}
      />
      {!itemsAreLoading && (
        <Pagination
          theme={theme}
          page={pagingInfo.currPage + 1}
          totalPages={totalPages}
          handlePagination={(page) => pageChanged(page)}
        />)
      }
      <DetailViewver
        theme={theme}
        isOpen={isDetailPanelOpen && (userIsDocumentManager === true || (match.params.id || "").length > 0)}
        onClose={closeDetailPanel}
        deliveryNotice={currentEditItem}
      />
      <DetailForm
        theme={theme}
        isOpen={isDetailPanelOpen && !userIsDocumentManager && !match.params.id}
        formDefinition={formDefinition}
        onClose={closeDetailPanel}
        onFormChange={(formDef, isValid) => {
          setFormDefinition(formDef);
          setFormIsValid(isValid);
        }}
        isSubmitEnabled={!itemsAreLoading}
        //isSubmitting={itemsAreLoading}
        onFormSubmit={() => {

          //updateObject ???????
          // const origianlItem = { ...currentEditItem };
          if (formIsValid) {
            currentEditItem.partnerCode = formDefinition.partner.value ?? "";
            currentEditItem.partnerName = formDefinition.partner.elementConfig.options.find(o => o.option === currentEditItem.partnerCode)?.text ?? "";
            if (!formDefinition.deliveryDate.value) {
              throw new Error("delivery date mandatory")
            }
            currentEditItem.deliveryDate = formDefinition.deliveryDate.value;
            currentEditItem.deliveryPeriodCode = formDefinition.deliveryPeriod.value;
            currentEditItem.deliveryPeriodDesc = formDefinition.deliveryPeriod.elementConfig.options.find(o => o.option === formDefinition.deliveryPeriod.value)?.text;
            currentEditItem.varietyCode = formDefinition.variety.value ?? "";
            currentEditItem.varietyDesc = formDefinition.variety.elementConfig.options.find(o => o.option === formDefinition.variety.value)?.text ?? "";
            currentEditItem.qualityCode = formDefinition.quality.value;
            currentEditItem.qualityDesc = formDefinition.quality.elementConfig.options.find(o => o.option === formDefinition.quality.value)?.text;
            currentEditItem.packagingCode = formDefinition.packaging.value;
            currentEditItem.packagingDesc = formDefinition.packaging.elementConfig.options.find(o => o.option === formDefinition.packaging.value)?.text;
            currentEditItem.parcels = defaultNumberParser.parse(formDefinition.parcels.value!);
            currentEditItem.palletCode = formDefinition.pallet.value;
            currentEditItem.palletDesc =  formDefinition.pallet.elementConfig.options.find(o=> o.option === formDefinition.pallet.value)?.text;
            currentEditItem.pallets = defaultNumberParser.parse(formDefinition.pallets.value ?? "0");
            currentEditItem.notes = formDefinition.notes.value as string;

            setItemsAreLoading(true);
            if (!currentEditItem.id || currentEditItem.id.length === 0) {
              // nuovo elemento: lo aggiungo -> POST
              easyClient.post<IDeliveryNotice>("/api/DeliveryNotices",
                omit(currentEditItem, ["id", "eTag"]),
                { headers: { "X-version": "1.0" } })
                .then(response => {
                  setAllItems([...allItems, response.data]);
                  setIsDetailPanelOpen(false);
                })
                .catch(err => {
                  console.log("Errore: " + err);
                })
                .finally(() => {
                  setItemsAreLoading(false);
                });
              // currentEditItem.id = test_uuid();
              // const localAllItems = [...allItems];
              // localAllItems.push(currentEditItem);
              // setAllItems(localAllItems);
            } else {
              // elemonto già esistente: lo aggiorno (parzialmente) -> PATCH
              easyClient.patch<IDeliveryNotice>(`/api/DeliveryNotices('${currentEditItem.id}')`,
                omit(currentEditItem, ["creationLog", "lastChangeLog"]),
                { headers: { "X-version": "1.0" } })
                .then(response => {
                  const localAllItems = [...allItems];
                  const index = allItems.indexOf(currentEditItem);
                  if (index !== -1) {
                    localAllItems.splice(index, 1, response.data);
                  }
                  setAllItems(localAllItems);

                  setIsDetailPanelOpen(false);
                  //setSoftRefreshView({});
                })
                .catch(err => {
                  console.log("Errore: " + err);
                  // TODO: ripristinare vecchio elemento
                  // anzi, sarebbe da intercettare il tipo di erore e se è una PreconditionFailed (HttpStatus = 412)
                  // (quindi  la versione sul server è più aggiornata di quella locale)
                  // sostituire quella locale con quella server
                  //currentEditItem = {...origianlItem};
                })
                .finally(() => {
                  setItemsAreLoading(false);
                });
            }

          }
        }
        } />
      {/* <span>Totale elementi: {filteredItemsCount}</span>
      <p>{match.params.id}</p> */}
    </Page>)
}

export default connector(withEasyHttp(DeliveryNotice));
