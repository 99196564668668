import React, { useRef } from 'react';
import { IDocument, IRecipient, Status } from '../../../containers/Document/types';
import { Text, DocumentCard, DocumentCardActivity, DocumentCardDetails, DocumentCardTitle, DocumentCardType, IconButton, DocumentCardPreview, ContextualMenuItemType, IContextualMenuProps, IContextualMenuItem, IContextualMenuItemProps, ITheme, Stack } from '@fluentui/react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import getStyles, { classFactory } from './EasyDocumentCard.style';
import { getFileExtension } from '../../../shared/utility';
import filesize from 'filesize';

export enum EasyDocumentCardMode {
  Publisher = "Publisher",
  Recipient = "Recipient",
}

enum ReadStatus {
  Unread = "Unread",
  PartiallyRead = "PartiallyRead",
  FullyRead = "FullyRead"
}

const compareRecipients: (r1?: IRecipient, r2?: IRecipient) => number = (r1, r2) => {
  var a = r1?.description?.toLowerCase() || "";
  var b = r2?.description?.toLowerCase() || "";
  if (a < b) { return -1; }
  if (a > b) { return 1; }
  return 0;
}


export interface IEasyDocumentCardProps {
  doc: IDocument;
  index: number;
  totCount: number;
  showDocumentType: boolean;
  modeShow: EasyDocumentCardMode;
  onDownloadClick: (doc: IDocument) => void;
  onShowMetadataClick: (document: IDocument) => void;
  onShowContextMenuClick: (menuProps: IContextualMenuProps) => void;
  userGroupIds: string[];
  theme: ITheme;
}


const EasyDocumentCard: React.FunctionComponent<IEasyDocumentCardProps> = ({ doc: d, index, totCount, showDocumentType, modeShow, onDownloadClick, onShowMetadataClick, onShowContextMenuClick, userGroupIds, theme }) => {

  const cardMenuIconRef = useRef(null);

  const pubDate = new Date(d.publishedTimestamp);
  //const lastDot = d.fileNameOriginal.lastIndexOf(".");
  const fileExt = getFileExtension(d.fileNameOriginal) || "";
  const title = d.fileNameOriginal;
  const previewIconSize = 64;// window.matchMedia("all and " + desktopCriteria).matches ? 64 : 48;
  const styles = getStyles(theme);
  const classes = classFactory();

  let readStatus = ReadStatus.Unread;
  let userRecipients: IRecipient[] = d?.recipients || [];

  let recipientsCount = 0;
  let recipientsRead = 0;

  // ----- ora dovrebbe essere gestito lato server -----
  // // Se l'utente è il pubblicatore vede tutti i destinatari
  // // Altrimenti vede solo i gruppi destinatari a cui appartiene l'utente corrente
  // if (modeShow === EasyDocumentCardMode.Recipient) {
  //   userRecipients = userRecipients.filter(r => userGroupIds.includes(r.groupId));
  // }
  //----------------------------------------------------

  recipientsCount = userRecipients.length;
  recipientsRead = userRecipients.filter(r => r.status !== Status.New).length;
  readStatus = recipientsRead === 0 ? ReadStatus.Unread
    : recipientsRead < recipientsCount ? ReadStatus.PartiallyRead
      : ReadStatus.FullyRead;

  const cardMenuItemsProps: Partial<IContextualMenuItemProps> = {
    styles: styles.cardContextMenuItem,
  };

  const recipientsMenuItemProps: Partial<IContextualMenuItemProps> = {
    styles: styles.recipientsContextMenuItem,
  };


  const getContextualMenu: () => IContextualMenuProps = () => {
    const menuProps: IContextualMenuProps = {
      target: cardMenuIconRef,
      shouldFocusOnMount: true,
      items: [
        {
          key: 'dowlonad',
          iconProps: { iconName: 'Download' },
          text: 'Scarica',
          title: 'Scarica il documento',
          onClick: () => { onDownloadClick(d) },
          itemProps: { ...cardMenuItemsProps },
        },
        {
          key: 'metadata', text: 'Attributi', iconProps: { iconName: 'Parameter' },
          onClick: () => { onShowMetadataClick(d) },
          disabled: !d.contentMetadata || d.contentMetadata.length === 0,
          //secondaryText: "" + (d.contentMetadata && d.contentMetadata.length > 0 ? d.contentMetadata.length : ""),
          itemProps: { ...cardMenuItemsProps },
        },
        { key: 'divider_30', itemType: ContextualMenuItemType.Divider },
      ],
    };

    //const multiRecipients = userRecipients.length > 1;

    //modeShow === EasyDocumentCardMode.Publisher &&
    menuProps.items.push({
      //key: 'readstatusAsPublisher',
      key: 'readstatus',
      text: readStatus === ReadStatus.Unread ? (modeShow === EasyDocumentCardMode.Publisher ? "Non acquisito" : "Nuovo")
        : (readStatus === ReadStatus.FullyRead ? "Acquisito"
          : "Parzialmente acquisito"),
      className: readStatus.toLowerCase(),
      //itemProps: {{}...styles: styles.cardContextMenuItem}
      itemProps: { ...cardMenuItemsProps },
      //styles: styles.cardContextMenuItem,
      iconProps: { iconName: "View" },
      secondaryText: `${userRecipients.filter(r => r.status !== Status.New).length}/${userRecipients.length}`,
      subMenuProps: {
        styles: styles.recipientsContextMenu,
        items: userRecipients.sort(compareRecipients).map(r => {
          const read = r.status !== Status.New;
          return {
            key: `rec${r.groupId}`,
            text: r.description,
            onClick: (ev) => { ev?.preventDefault(); },
            itemProps: { ...recipientsMenuItemProps },
            //onRenderContent: (props: IContextualMenuItemProps) => <div>Custom rendered {props.item.text}</div>,
            //disabled: true,
            secondaryText: read ? ((r.acquired && r.acquired.timestamp) ? new Date(r.acquired.timestamp).toLocaleString() : "?") : "non acquisito",
            iconProps: { iconName: read ? "StatusCircleCheckmark" : "" }
          } as IContextualMenuItem;
        })
      }
    });

    return menuProps;
  }

  return (<DocumentCard
    className={readStatus !== ReadStatus.FullyRead ? "unread" : ""}
    key={d.id}
    type={DocumentCardType.compact}
    styles={styles.documentCard}
    aria-posinset={index + 1}
    aria-setsize={totCount}
    onClick={() => onShowContextMenuClick(getContextualMenu())}
    data-is-focusable >
    <DocumentCardPreview
      styles={styles.documentCardPreview}
      previewImages={[{
        previewIconProps: {
          ...getFileTypeIconProps({ extension: fileExt, size: previewIconSize })
        },
      }]}
    >
    </DocumentCardPreview>
    <DocumentCardDetails styles={styles.documentCardDetails}>
      <div>
        {showDocumentType &&
          <Text styles={styles.documentType} block nowrap >{d.documentTypeDesc?.toUpperCase() || d.documentTypeId}</Text>
        }
        <DocumentCardTitle
          title={title}
          styles={styles.documentCardTitle}
          shouldTruncate
        />
        {(d.fileSize !== undefined && d.fileSize > 0) &&
          <Text styles={styles.fileSize} block nowrap >{filesize(d.fileSize)}</Text>
        }
      </div>
      <DocumentCardActivity
        styles={styles.documentCardActivity}
        activity={`${pubDate.toLocaleDateString()} ${pubDate.toLocaleTimeString()}`}
        people={[{ name: d.publisherGroupDesc || d.publisherGroupId, profileImageSrc: "fake" }]}
      />
      <div ref={cardMenuIconRef} className={classes.iconButtonWrapper} >
        <IconButton
          styles={styles.iconButtonContextMenu}
          iconProps={{ iconName: "More" }}
          onClick={() => onShowContextMenuClick(getContextualMenu())} />
      </div>
    </DocumentCardDetails>
  </DocumentCard>);
};

export default EasyDocumentCard;
