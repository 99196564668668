import React, { useEffect } from 'react';
import SplashScreen from '../../components/SplashScreen/SplashScreen';
import { ProgressIndicator } from '@fluentui/react';

import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { IHttpClient } from '../../shared/easyAxios';
import withInteractionRequiredWrapper, { IWithInteractiveLoginProps } from '../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper';
import useLogin from '../../hooks/UseLogin';
import { contextFetch } from '../../store/actions/context';

const mapStateToProps = (state: IEasyStore) => {
  return {
    contextInitialized: !!state.context.username,
    cancelOperation: state.context.error,
    theme: state.context.fullTheme
  }
}
const mapDispatchToProps = {
  initializeContext: (easyClient: IHttpClient) => contextFetch(easyClient)
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

interface IContextProps extends IWithInteractiveLoginProps, PropsFromStore {
  contextInitialized: boolean;
  cancelOperation: boolean;
}

const Context: React.FC<IContextProps> = (props) => {
  const contextInitialized = props.contextInitialized;
  const initializeContext = props.initializeContext;
  const easyClient = props.easyClient;
  const cancelOperation = props.cancelOperation;

  const { signOut } = useLogin();

  useEffect(() => {
    if (cancelOperation) {
      signOut()
        .catch(() => {
          window.location.reload();
        })
    }
  }, [cancelOperation, signOut])

  useEffect(() => {
    if (!contextInitialized)
      initializeContext(easyClient);
  }, [contextInitialized, initializeContext, easyClient]);

  if (!contextInitialized) {
    return (
      <SplashScreen theme={props.theme}>
        <ProgressIndicator label="Ci siamo quasi" />
      </SplashScreen>
    )
  }
  else {
    return <>{props.children}</>
  }
}

export default connector(withInteractionRequiredWrapper(Context));
