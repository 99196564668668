import { mergeStyleSets, IProcessedStyleSet, IStyle, ITheme } from '@fluentui/react'
import { desktop } from '../../../shared/mediaQuery';

interface IPaginationStyles {
  pagination: IStyle,
  paginationWrapper: IStyle,
  separator: IStyle,
  pageItem: IStyle,
  active: IStyle,
  sides: IStyle,
  elementCounter: IStyle,
}

const pagintaionStyles = (theme: ITheme): IProcessedStyleSet<IPaginationStyles> => {
  return mergeStyleSets<IPaginationStyles>({
    pagination: {
      padding: "0.5rem 0",
      fontSize: "0.8rem",
      selectors: {
        [desktop]: {
          fontSize: "0.9rem",
        }
      }
    },
    elementCounter: {
      display: "flex",
      justifyContent: "flex-start",
      //marginBottom: "0.5rem",
      padding: "0.5rem",

    },
    paginationWrapper: {
      padding: "0.5rem 0",
      display: "flex",
      justifyContent: "center",
      // fontSize: "0.8rem",
      // selectors: {
      //   [desktop]: {
      //     fontSize: "0.9rem",
      //   }
      // }
    },
    separator: {
      width: "2em",
      margin: "0 0.25em",
      userSelect: "none",
      textAlign: "center",
    },
    pageItem: {
      background: "transparent",
      border: "none",
      height: "2em",
      width: "2em",
      margin: "0 0.25em",
      borderRadius: "50%",
      fontWeight: "600",
      color: theme.palette.themePrimary,
      cursor: "pointer",
      padding: "0",
      userSelect: "none",
      selectors: {
        ':hover': { textDecoration: "underline" },
        ':focus': { outline: "0" }
      }
    },
    active: {
      backgroundColor: theme.palette.themePrimary,
      color: theme.palette.white,
      cursor: "auto",
      ':hover': {
        textDecoration: "none",
      }
    },
    sides: {
      border: "1px solid",
      selectors: {
        ':disabled': {
          borderColor: theme.semanticColors.disabledBorder,
          color: theme.semanticColors.disabledText,
          cursor: "auto",
        },
        ':hover': {
          textDecoration: "none",
        }
      }
    }
  });
};

export default pagintaionStyles;
