import {
    takeEvery,
    //all,
    //takeLatest
} from "redux-saga/effects";
import { APP_SET_CONFIG } from "../types/config";
import { CONTEXT_FETCH, SET_THEME } from "../types/context";

import { configSaga } from './config';
import { contextSaga, setThemeToStorage } from './context';

export function* watchConfig(){
    yield takeEvery(APP_SET_CONFIG, configSaga);
}

export function* watchContext(){
  yield takeEvery(CONTEXT_FETCH, contextSaga);
  yield takeEvery(SET_THEME, setThemeToStorage);
}
