import React from 'react';

import { IReportData } from '../types';
import ListSpecie from '../../../components/ProvisionReport/ListSpecie/ListSpecie';
import { ISpecieCardProps } from '../../../components/ProvisionReport/Card/Card';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { pathForProvisionsReportBySpecie } from '../../../shared/route';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../../store/store';
const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
})
const connector = connect(mapStateToProps)
type PropsFromStore = ConnectedProps<typeof connector>

interface IListSpecieContainer extends RouteComponentProps, PropsFromStore {
  reportData: IReportData;
}

const ListSpecieContainer: React.FC<IListSpecieContainer> = (props) => {

  const onClickHandler = (specie: ISpecieCardProps) => {
    const nextPath = pathForProvisionsReportBySpecie(props.reportData.partnerCode, props.reportData.yearCode, specie.title);
    props.history.push(nextPath);
  }

  const specieGrouped = props.reportData.provisions.reduce((agg, next) => {
    const specieGroup = agg.get(next.specie) ?? { weight: 0, sampled: 0 };
    specieGroup.weight = specieGroup.weight + next.weight;
    specieGroup.sampled = specieGroup.sampled + props.reportData.sampling.filter(s => s.receiptId === next.provisionId).reduce((agg, next) => agg + next.developedWeight, 0);
    agg.set(next.specie, specieGroup);
    return agg;
  }, new Map<string, { weight: number, sampled: number }>());

  const items = Array.from(specieGrouped.entries()).map<ISpecieCardProps & { key: string }>(entry => {
    const [specie, values] = entry;
    return {
      delivered: values.weight,
      key: "specie_" + specie,
      sampled: values.sampled,
      title: specie,
      theme: props.theme
    }
  })

  return <ListSpecie theme={props.theme} items={items} onSpecieSelected={onClickHandler} />;
}



export default connector(withRouter(ListSpecieContainer));
