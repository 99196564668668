import { IImageStyles } from '@fluentui/react';
import { desktop } from '../../shared/mediaQuery';

interface ILogoStyles {
  imageStyle: IImageStyles,
}

const logoStyles: (hasLink?: boolean) => ILogoStyles = (hasLink) => {

  return {
    imageStyle: {
      root: {
        minHeight: "2.7rem",
        maxHeight: "2.7rem",
        height: "2.7rem",
        minWidth: "2.7rem",
        maxWidth: "6rem",
        overflow: "hidden",
        selectors: {
          [desktop]: {
            minHeight: "5rem",
            maxHeight: "5rem",
            height: "5rem",
            minWidth: "5rem",
            maxWidth: "10rem",
          }
        }
      },
      image: {
        objectPosition: "left center",
        cursor: hasLink ? "pointer" : "default",
      }
    }
  };
}

export default logoStyles;
