import React from 'react';
import { desktop } from '../../shared/mediaQuery';
import { Stack, Link, mergeStyleSets, IStyle } from '@fluentui/react';
import EasySection from '../../components/EasySection/EasySection';
import Page from '../../hoc/Page/Page';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { INavRoute } from '../../store/types/context';

const mapStateToProps = (state: IEasyStore) => {
  return {
    menus: state.context.menuByArea,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>

interface IMenuProps extends RouteComponentProps<IMenuRoute>, PropsFromStore {
  menus: Record<string, INavRoute[]>;
}

interface IMenuRoute {
  id: string;
}

const classNames = mergeStyleSets({
  "Box": {
    boxSizing: "border-box",
    width: "100%",
    selectors: {
      [desktop]: {
        width: "auto"
        // width: "30rem",
        // overflow: "auto"
        // maxWidth: "40rem"
      }
    }
  } as IStyle
})

const Menu: React.FC<IMenuProps> = (props) => {
  const menuId = props.match.params.id;
  const history = props.history;
  const menus = props.menus;

  const menuList = menus[menuId] ?? [];
  const menuBySection = menuList.reduce<Record<string, INavRoute[]>>((agg, current) => {
    const key = current.section ?? "(nessuna sezione)";
    agg[key] = agg[key] ?? [];
    agg[key].push(current);
    return agg;
  }, {});

  const sections = Object.keys(menuBySection).map(section => (
    <Stack.Item key={section} className={classNames.Box}>
      <EasySection title={section}>
        <Stack>
          {menuBySection[section].map(m => (
            <Stack.Item key={m.key}>
              <Link onClick={() => history.push(m.url)}>{m.text}</Link>
            </Stack.Item>
          ))}
        </Stack>
      </EasySection>
    </Stack.Item>
  ))

  return (
    <Page title={`Area ${menuId}`} iconName="ConnectContacts" pageWidth="960px" theme={props.theme}>
      <Stack horizontal wrap tokens={{ childrenGap: "1rem" }} horizontalAlign="center">{sections}</Stack>
    </Page>
  );
}

export default connector(withRouter(Menu));
