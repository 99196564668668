import { IStackStyles, IStackItemStyles, ITheme } from '@fluentui/react'
import { desktop } from '../../../shared/mediaQuery';

const headerStyles = (theme: ITheme) => {

  return {
    header: {
      root: {
        backgroundColor: theme.palette.neutralTertiaryAlt,
        height: "3.5rem",
        padding: "0.6rem 0.4rem",
        overflow: "hidden",
        maxWidth: "100vw",
        selectors: {
          [desktop]: {
            height: "6rem",
            padding: "1.1rem",
          },
          "> div": {
            margin: "0.3rem",
          }
        }
      },
    } as IStackStyles,
    hamburger: {
      root: {
        marginRight: "0.6rem!important",
        selectors: {
          [desktop]: {
            display: "none",
          },
        },
      }
    } as IStackItemStyles,
    logo: {
      root: {
        maxWidth: "6rem",
        selectors: {
          [desktop]: {
            maxWidth: "10rem",
          }
        }
      }
    } as IStackItemStyles,
    settings: {
      root: {
        display: "none",
        selectors: {
          [desktop]: {
            display: "block",
          },
        }
      }
    } as IStackItemStyles,
    search: {
      root: {
        minWidth: "8rem",
      }
    } as IStackItemStyles,
  }
};

export default headerStyles;
