import React, { useEffect, useState } from 'react'
import { ShimmeredDetailsList, Panel, IColumn, omit } from '@fluentui/react'
import AreaCommandBar from './CommandBar'
import EasyForm from '../../components/Form/Form';
import Page from '../../hoc/Page/Page';
import withApiWrapper from '../../hoc/HttpClientWrapper/HttpClientWrapper'
import { IHttpClient } from '../../shared/easyAxios';
import { IODataList } from '../../models/odata';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../store/store';
import { FormDefinitions } from '../../types/Form';

const mapStateToProps = (state: IEasyStore) => {
  return {
    theme: state.context.fullTheme
  }
}
const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

interface IAreaProps extends PropsFromStore {
  easyClient: IHttpClient;
}

interface IArea {
  id?: string;
  order?: number;
  eTag?: string;
  name?: string;
  creationLog?: any;
  lastChangeLog?: any;
}

const createFormDefinition: (area: IArea) => FormDefinitions = (area) => {
  return {
    "name": {
      elementType: "input",
      elementConfig: {
        label: 'Area'
      },
      value: area.name ?? "",
      validation: {
        required: true,
        isEmail: false
      },
      valid: false,
      touched: false,
    },
    "order": {
      elementType: "number",
      elementConfig: {
        label: "Ordinamento",
        min: -100,
        max: 100,
        step: 0.5
      },
      value: area?.order?.toString() ?? "",
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  }
}

const columns: IColumn[] = [{
  key: "name",
  minWidth: 100,
  name: "Area",
  fieldName: "name"
}, {
  key: "order",
  minWidth: 100,
  name: "Ordinamento",
  fieldName: "order"
}]

const Area: React.FC<IAreaProps> = (props) => {
  const easyClient = props.easyClient;
  const [items, setItems] = useState<IArea[]>([]);
  const [detailOpened, setDetailOpened] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState<IArea>({});
  const [currentFormDefinition, setCurrentFormDefinition] = useState<FormDefinitions>({});
  const [formIsValid, setFormIsValid] = useState(false);
  const [submit, setSubmit] = useState<any>(null);
  useEffect(() => {
    easyClient.get<IODataList<IArea>>("/api/Areas", { headers: { "X-version": "1.0" } })
      .then(response => {
        setItems(response.data.value as IArea[]);
      })
  }, [easyClient])

  const submitEnabled = true;
  const selectItemHandler = (item?: IArea) => {
    if (!item)
      return;
    setCurrentEditItem(item);
    setCurrentFormDefinition(createFormDefinition(item));
    setDetailOpened(true);
  }
  const closeDetailHandler = () => {
    setDetailOpened(false);
  }
  const formChangedHandler = (updatedForm: FormDefinitions, isValid: boolean) => {
    setCurrentFormDefinition(updatedForm);
    setFormIsValid(isValid);
  }
  const formSubmitHandler = () => {
    setSubmit({});
  }
  useEffect(() => {
    if (submit && formIsValid) {
      setSubmit(null);
      currentEditItem.name = currentFormDefinition.name.value as string;
      currentEditItem.order = currentFormDefinition.order.value as number;
      if (!currentEditItem.id || currentEditItem.id.length === 0) {
        easyClient.post<IArea>("/api/Areas",
          omit(currentEditItem, ["id", "eTag"]),
          { headers: { "X-version": "1.0" } })
          .then(response => {
            setDetailOpened(false);
            setItems([...items, response.data])
          })
      } else {
        easyClient.patch<IArea>(`/api/Areas('${currentEditItem.id}')`,
          omit( currentEditItem, ["creationLog", "lastChangeLog"]),
          { headers: { "X-version": "1.0" } })
          .then(response => {
            const localAllItems = [...items];
            const index = localAllItems.indexOf(currentEditItem);
            if (index !== -1) {
              localAllItems.splice(index, 1, response.data);
            }
            setItems(localAllItems);
            setDetailOpened(false);
          })
      }
    }
  }, [submit, formIsValid, easyClient, currentFormDefinition, currentEditItem, items]);
  const newItemHandler = () => {
    setCurrentEditItem({});
    setCurrentFormDefinition(createFormDefinition({}))
    setDetailOpened(true)
  }
  const deleteItemHandler = () => {
  }

  return (
    <Page title="Aree" iconName="Sections" pageWidth="960px" theme={props.theme}>
      <AreaCommandBar
        newItemClicked={newItemHandler}
        deleteItemClicked={deleteItemHandler}
      />
      <ShimmeredDetailsList
        items={items}
        onItemInvoked={selectItemHandler}
        columns={columns}
      />
      <Panel
        headerText="Area"
        isOpen={detailOpened}
        onDismiss={closeDetailHandler}
        closeButtonAriaLabel="Close"
      >
        <EasyForm
          theme={props.theme}
          formDefinition={currentFormDefinition}
          onChange={formChangedHandler}
          onSubmit={formSubmitHandler}
          submitEnabled={submitEnabled}
        />
      </Panel>
    </Page>
  );
}



export default connector(withApiWrapper(Area));
