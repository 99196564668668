import { IStyle, IProcessedStyleSet, mergeStyleSets, ITheme } from "@fluentui/react";

interface IMetadataSetStyle {
  root: IStyle;
  metadataList: IStyle;
  metadataListIcon: IStyle;
  metadataListText: IStyle;
  metadataKey: IStyle;
  metadataValue: IStyle;
  moreMetadata: IStyle;
  metaNumber: IStyle;
}

const styleFactory = (theme: ITheme): IProcessedStyleSet<IMetadataSetStyle> => {

  const FONT_SIZE_KEY = 11;
  const FONT_SIZE_VALUE = 14;
  const FONT_SIZE_MORE = 12;

  return mergeStyleSets<IMetadataSetStyle>({
    root: {
      fontSize: FONT_SIZE_VALUE,
      color: theme.semanticColors.bodySubtext,
      padding: "0.15em 0.5em",
      display: "inline",
      width: "fit-content",
      maxWidth: "95%",
      //overflow: "hidden",
      position: "relative",
      selectors: {
        '::before': {
          border: "1px solid " + theme.palette.themeLight,
          bottom: 0,
          content: '""',
          position: "absolute",
          top: 0,
          width: "2px",
          borderRight: "none",
          left: 0
        },

        // '::after, ::before': {
        //   border: "1px solid " + theme.palette.themeLight,
        //   bottom: 0,
        //   content: '""',
        //   position: "absolute",
        //   top: 0,
        //   width: "2px",
        // },
        // '::after': {
        //   borderLeft: "none",
        //   right: 0
        // },
        // '::before': {
        //   borderRight: "none",
        //   left: 0
        // }
      }
    },
    metadataList: {
      padding: 0,
      margin: `0 0 0 1.5em`,
      listStyleType: "none",
      lineHeight: "1.1em",
      //maxHeight: "3.3em",
      selectors: {
        "> li": {
          position: "relative",
          marginBottom: "0.3em",
        },
        "> li:last-child": {
          marginBottom: 0,
        },
      }
    },
    metadataListIcon: {
      position: "absolute",
      left: "-1.5em",// * 2,
      top: 1,
      width: 0,//"2em",//FONT_SIZE * 2,
      textAlign: "center",
      color: theme.palette.themePrimary,
    },
    metadataListText: {
      display: "block",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    metadataKey: {
      fontSize: FONT_SIZE_KEY,
      textTransform: "uppercase",
      fontWeight: 500,
      color: theme.palette.themePrimary,
    },
    metadataValue: {

    },
    moreMetadata: {
      fontSize: FONT_SIZE_MORE,
    },
    metaNumber: {
      position: "absolute",
      top: "-0.5em",
      left: "-2.2em",
      width: "2em",
      overflow: "hidden",
      fontSize: 10,
      textAlign: "right",
    }
  });
}

export default styleFactory;
