import React, { useCallback, useEffect, useState } from 'react';
import { Text, FocusZone, ITheme, List, mergeStyleSets, IStyle, DefaultButton, Persona, PersonaSize, Spinner } from '@fluentui/react';
import { IHttpClient } from '../../../shared/easyAxios';

import withApiWrapper from '../../../hoc/HttpClientWrapper/HttpClientWrapper'
import { Card } from '@uifabric/react-cards';
import { getCardClassNames, cardsToken } from '../../../components/ProvisionReport/Card/Card'
import { connect } from 'react-redux';
import { IEasyStore } from '../../../store/store';
import { pathForProvisionsReportRoot } from '../../../shared/route';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../hoc/Page/Page';
import NoData from '../../../components/NoData/NoData';

interface IProvisionReportIndexContainerPropsFromStore {
  theme: ITheme;
  searchFilter: string;
}

interface IProvisionReportIndexContainerProps extends RouteComponentProps, IProvisionReportIndexContainerPropsFromStore {
  easyClient: IHttpClient;
}

interface IProvisionReportInxdexResponse {
  value: IProvisionReportIndex[];
}

interface IProvisionReportIndex {
  partnerCode: string;
  yearCode: string;
  partnerName: string
}

const itemCountForPage = () => 15;

const ProvisionReportIndexContainer: React.FC<IProvisionReportIndexContainerProps> = (props) => {
  const cardClassName = getCardClassNames(props.theme);
  const easyClient = props.easyClient;
  const searchFilter = props.searchFilter;
  const history = props.history;
  const [reports, setReports] = useState<IProvisionReportIndex[]>([])
  const [loading, setLoading] = useState(false)
  const { palette } = props.theme;

  const classNames = mergeStyleSets({
    listGridExample: {
      display: "flex"
    },
    listGridExampleTile: {
      textAlign: 'center',
      outline: 'none',
      position: 'relative',
      float: 'left',
      selectors: {
        'focus:after': {
          content: '',
          position: 'absolute',
          left: 2,
          right: 2,
          top: 2,
          bottom: 2,
          boxSizing: 'border-box',
          border: `1px solid ${palette.white}`,
        },
      },
    },
    listGridExampleSizer: {
      padding: '10px',
    },
    listGridExamplePadder: {
      position: 'absolute',
      left: 10,
      top: 10,
      right: 10,
      bottom: 10,
    }
  });

  const goToReportHandler = useCallback((partnerCode: string, yearCode: string) => {
    const navigateTo = pathForProvisionsReportRoot(partnerCode, yearCode)
    history.push(navigateTo);
  }, [history])

  let onRenderCell = (item?: IProvisionReportIndex) => {
    if (!item)
      return;
    return (
      <div data-is-focusable style={{ margin: "12px" }}>
        <Card className={cardClassName.card} tokens={{ width: "250px", minWidth: "250px", maxWidth: "250px" }} >
          <Card.Item tokens={cardsToken} className={cardClassName.title}>
            <Text variant="large">{item?.partnerCode.toUpperCase()} ({item?.yearCode})</Text>
          </Card.Item>
          <Card.Item tokens={cardsToken}>
            <Persona styles={{
              root: {
                margin: "auto",
                selectors: {
                  "& :global(.ms-Persona-initials)": {
                    color: "#ffffff",
                  }
                }
              }
            }} size={PersonaSize.size32} text={item?.partnerName}></Persona>
          </Card.Item>
          <Card.Section horizontal horizontalAlign="center" tokens={cardsToken}>
            <DefaultButton text="Vai" onClick={() => goToReportHandler(item.partnerCode, item.yearCode)} />
          </Card.Section>
        </Card>
      </div>
    );
  };

  useEffect(() => {
    let url = `/api/ProvisionReportIndexes?$orderby=${encodeURIComponent('startDate desc, partnerName')}`
    if (searchFilter) {
      url = url + "&search=" + encodeURIComponent(searchFilter)
    }
    setLoading(true);
    easyClient.get<IProvisionReportInxdexResponse>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setReports(response.data.value);
      }).finally(() => {
        setLoading(false);
      });
  }, [easyClient, searchFilter])

  return (
    <Page title="Report Conferimento" iconName="PowerBILogo" pageWidth="960px" theme={props.theme}>
      <FocusZone>
        {loading ?
          <Spinner label="Caricamento" /> :
          reports.length ?
            <List
              className={classNames.listGridExample}
              items={reports}
              getPageStyle={() => {
                return {
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center"
                } as IStyle
              }}
              onRenderCell={onRenderCell}
              getItemCountForPage={itemCountForPage}
              version={props.theme}
            /> :
            <NoData />}
      </FocusZone>
    </Page>
  );
};

const mapStateToProps = (state: IEasyStore): IProvisionReportIndexContainerPropsFromStore => {
  return {
    searchFilter: state.context.globalSearchExpression,
    theme: state.context.fullTheme
  }
}

export default connect(mapStateToProps)(withApiWrapper(ProvisionReportIndexContainer));
