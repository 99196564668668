import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import withEasyHttp from '../../../hoc/HttpClientWrapper/HttpClientWrapper';
import { IEasyStore } from '../../../store/store';
import { INewsArticle } from '../News.types';
import LoadingModal, { LoadingModalMode } from '../../../components/UI/LoadingModal/LoadingModal';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { Pagination } from '../../../components/UI/Pagination/Pagination';
import Page from '../../../hoc/Page/Page';
import NoData from '../../../components/NoData/NoData';
import { IODataList } from '../../../models/odata';
import NewsArchiveCommandBar from '../../../components/News/NewsArchiveCommandBar/NewsArchiveCommandBar';
import NewsList from '../NewsList/NewsList';
import NewsViewer from '../NewsViewer/NewsViewer';
import NewsEditor from '../NewsEditor/NewsEditor';
import * as newsRoute from '../../../containers/News/NewsArchive/NewsArchive.route';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { UserRole } from '../../../store/types/context';
import { IWithInteractiveLoginProps } from '../../../hoc/InteractiveLoginWrapper/InteractiveLoginWrapper';

const PAGE_SIZE = 20;

const mapStateToProps = (state: IEasyStore) => {
  return {
    searchFilter: state.context.globalSearchExpression,
    documentTypes: state.context.documentType,
    userGroups: state.context.groups,
    userRoles: state.context.roles,
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;
export interface INewsArchivePathProps {
  articleId?: string,
}

export interface INewsArchiveProps extends RouteComponentProps<INewsArchivePathProps>, PropsFromStore, IWithInteractiveLoginProps {

}

const deleteDialogContentProps = {
  type: DialogType.normal,
  title: 'Conferma',
  closeButtonAriaLabel: 'Close',
  subText: 'Eliminare l\'articolo corrente?',
};

const userRolesCanEdit: UserRole[] = [UserRole.Admin, UserRole.DocumentManager, UserRole.Owner];

const NewsArchive: React.FC<INewsArchiveProps> = ({ theme, match, history, easyClient, searchFilter, userRoles }) => {

  const [articles, setArticles] = useState<INewsArticle[]>([]);
  const [currPage, setCurrPage] = useState<number>(1);
  const [totCount, setTotCount] = useState<number>(0);
  const [itemsAreLoading, setItemsAreLoading] = useState<boolean>(true);
  //const [currentArticle, setCurrentArticle] = useState<INewsArticle>();
  const [loadingMessage, setLoadingMessage] = useState<string>("Caricamento...");
  const [loadingMode, setLoadingMode] = useState<LoadingModalMode>(LoadingModalMode.Spinner);
  const [isFormPanelOpen, setIsFormPanelOpen] = useState<boolean>(false);
  const [refreshView, setRefreshView] = useState({});
  //const [selectedArticleId, setSelectedArticle] = useState<INewsArticle>();
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const deleteModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
    }),
    [labelId, subTextId],
  );

  const userCanEdit = useMemo(() => {
    return userRoles.some(r => userRolesCanEdit.includes(r));
  }, [userRoles])

  const totalPages = useMemo<number>(() => {
    return Math.ceil(totCount / PAGE_SIZE);
  }, [totCount]);

  const pageChanged: (page: number) => void = (page) => {
    const maxPage = Math.max(1, Math.ceil(totCount / PAGE_SIZE));
    setCurrPage(Math.min(maxPage, Math.max(1, page)));
  }

  useEffect(() => {
    setCurrPage(1);
  }, [searchFilter, match.params.articleId])

  useEffect(() => {
    setLoadingMessage("Caricamento...");
    setLoadingMode(LoadingModalMode.Spinner);
    setItemsAreLoading(true);

    let url = `/api/Articles?getThumbImage=true&$count=true&$top=${PAGE_SIZE}&$skip=${(currPage - 1) * PAGE_SIZE}&$orderby=${encodeURIComponent('publishedTimestamp desc')}`;

    if (searchFilter && searchFilter.trim().length > 0) {
      url += "&search=" + encodeURIComponent(searchFilter);
    }

    easyClient.get<IODataList<INewsArticle>>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setArticles(response.data.value);
        setTotCount(response.data["@odata.count"] || 0);
      })
      .catch(err => {
        console.log(err);
        setArticles([]);
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  }, [currPage, easyClient, match.params, searchFilter, refreshView]);

  const newItemHandler = () => {
    setIsFormPanelOpen(true)
  };

  const refreshViewHandler = () => {
    setRefreshView({});
  }

  const editItemHandler = () => {
    setIsFormPanelOpen(true)
  }

  const deleteItemHandler = () => {
    if ((match.params.articleId?.length || 0) > 0) {
      toggleHideDeleteDialog();
    }
  }

  const deleteConfirmedHandler = () => {
    if ((match.params.articleId?.length || 0) > 0) {
      const selectedId = match.params.articleId!;
      toggleHideDeleteDialog();
      setItemsAreLoading(true);
      easyClient.delete(`/api/Articles('${selectedId}')`, { headers: { "X-version": "1.0" } })
        .then(response => {
          resetPath();
        })
        .catch(err => {
          console.log("Errore: " + err);
        })
        .finally(() => {
          setItemsAreLoading(false);
        });
    }
  }

  const articleLoadingHandler = useCallback(
    () => {
      setLoadingMessage("Caricamento...");
      setLoadingMode(LoadingModalMode.Spinner);
      setItemsAreLoading(true);
    },
    [],
  )

  const articleLoadedHandler = useCallback(
    () => {
      setItemsAreLoading(false);
    },
    [],
  )

  const resetPath = useCallback(() => {
    match.params.articleId && history.replace(newsRoute.basePath);
  }, [history, match.params.articleId])

  const closeArticleHandler = useCallback(
    () => {
      setItemsAreLoading(false);
      resetPath();
    },
    [resetPath],
  )

  const articleErrorHandler = useCallback(
    () => {
      articleLoadedHandler();
      closeArticleHandler();
    },
    [articleLoadedHandler, closeArticleHandler],
  )

  const articleSelectedHandler = useCallback(
    (selectedArticleId?: string) => {
      selectedArticleId
        ? history.push(newsRoute.path.replace(":articleId?", selectedArticleId))
        : resetPath();
    },
    [history, resetPath],
  )

  const hasArticle = (match.params.articleId?.length || 0) > 0

  return (
    <Page title="Notizie" iconName="TextDocument" pageWidth="960px" theme={theme}>

      <LoadingModal
        show={itemsAreLoading}
        mode={loadingMode}
        //percentComplete={loadingPercentage}
        message={loadingMessage}
      />
      <NewsArchiveCommandBar
        newItemClicked={newItemHandler}
        refreshClicked={refreshViewHandler}
        deleteItemClicked={deleteItemHandler}
        editItemClicked={editItemHandler}
        showRefresh={!hasArticle}
        showPublish={userCanEdit && !hasArticle}
        showEdit={userCanEdit && hasArticle}
        showDelete={userCanEdit && hasArticle}
      />
      {hasArticle && (
        <NewsViewer
          articleId={match.params.articleId!}
          easyClient={easyClient}
          onArticleError={articleErrorHandler}
          onArticleLoading={articleLoadingHandler}
          onArticleLoaded={articleLoadedHandler}
          refreshView={refreshView}
        />
      )}
      {!match.params.articleId && articles.length === 0 && !itemsAreLoading && (
        <NoData title="Nessun articolo trovato" />
      )}
      {!match.params.articleId && articles.length > 0 && (
        <NewsList
          articles={articles}
          onArticleSelected={articleSelectedHandler}
        />
      )}
      {!match.params.articleId && totCount > 0 && (
        <Pagination
        theme={theme}
          page={currPage}
          totalPages={totalPages}
          totalItemsCount={totCount}
          handlePagination={(page) => pageChanged(page)}
        />)
      }
      <NewsEditor
        easyClient={easyClient}
        isOpen={isFormPanelOpen}
        onDismiss={(refresh) => {
          setIsFormPanelOpen(false);
          refresh && refreshViewHandler();
        }}
        articleId={match.params.articleId}
      />
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        dialogContentProps={deleteDialogContentProps}
        modalProps={deleteModalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteConfirmedHandler} text="Elimina" />
          <DefaultButton onClick={toggleHideDeleteDialog} text="Non eliminare" />
        </DialogFooter>
      </Dialog>
    </Page>);

}

export default connector(withEasyHttp(NewsArchive));


