import React from 'react';
import { FontWeights, Text, mergeStyleSets, IStyle, Stack, DetailsList, SelectionMode, ConstrainMode, ITheme } from '@fluentui/react'

import { Pie } from 'react-chartjs-2';
import { IProvisionSampledListItem, ISampledDevelopment } from '../../../containers/ProvisionReport/types';

interface IProvisionDetailProp {
  item: IProvisionSampledListItem;
  theme: ITheme;
}

const getClasses = (theme: ITheme) => {
  const { palette, effects } = theme;

  return mergeStyleSets({
    headerBox: {
      border: `1px solid ${palette.green}`,
      boxSizing: "border-box",
      display: "grid",
      gridTemplateColumns: "1fr 1.5fr 1fr 0.5fr",
      gridColumnGap: "1rem",
      gridRowGap: "1rem",
      boxShadow: effects.elevation4,
      margin: "0.5rem",
      padding: "0.5rem",
      alignItems: "center"
    } as IStyle,
    label: {
      fontWeight: FontWeights.semibold
    } as IStyle,
    itemName: { gridColumnEnd: "span 3" } as IStyle,
    sampled: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr"
    } as IStyle
  });
}

const ConditionalRender: React.FC<{ className: string, label: string, value?: string }> = ({ className, label, value }) => {
  if (value) {
    return <>
      <div className={className}>{label}</div>
      <div>{value}</div>
    </>
  } else {
    return null;
  }
}

export const ProvisionDetail: React.FC<IProvisionDetailProp> = ({ item, theme }) => {
  const classes = getClasses(theme);
  const { palette } = theme;
  const devSorted4Pie = [...item.development].sort((a, b) => a.percent > b.percent ? -1 : a.percent < b.percent ? 1 : 0);
  const data4Pie = {
    labels: devSorted4Pie.map(d => d.calibre),
    datasets: [{
      data: devSorted4Pie.map(d => d.percent),
      backgroundColor: Array.from(Object.values(palette))
    }]
  };

  const percentRenderHanlder = (item: ISampledDevelopment) => {
    return <span>{item.percent.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
  }

  const weightRenderHandler = (item: ISampledDevelopment) => {
    return <span>{item.weight.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
  }
  return (
    <div>
      <div className={classes.headerBox}>
        <div className={classes.label}>Prodotto</div>
        <div className={classes.itemName}><Text variant="xLarge">{item.name}</Text></div>
        <ConditionalRender className={classes.label} label="Qualità" value={item.quality} />
        <ConditionalRender className={classes.label} label="Tp Prodotto" value={item.type} />
        <ConditionalRender className={classes.label} label="Tp Lavorazione" value={item.work} />
        <ConditionalRender className={classes.label} label="Differenziale" value={item.differential} />
        <ConditionalRender className={classes.label} label="Colli" value={item.parcel?.toLocaleString()} />
        <ConditionalRender className={classes.label} label="Data" value={item.date?.toLocaleDateString()} />
        <ConditionalRender className={classes.label} label="DDT" value={item.ddt} />
        <ConditionalRender className={classes.label} label="BEM" value={item.bem} />
      </div>
      <Stack horizontal horizontalAlign="center" verticalAlign="center" wrap >
        <Stack.Item grow>
          <Text block variant="large" style={{ marginBottom: "0.5rem" }} >Campionamento</Text>
          <div className={classes.sampled}>
            <div className={classes.label}>Netto (kg)</div>
            <div>{item.weight.toLocaleString()}</div>
            <div className={classes.label}>Campionato (kg)</div>
            <div>{item.sampled.toLocaleString()}</div>
            <ConditionalRender className={classes.label} label="Qualità" value={item.sampledQuality} />
            <ConditionalRender className={classes.label} label="Indice di lavabilità" value={item.washability} />
            <ConditionalRender className={classes.label} label="Difetti" value={item.sampledDefects} />
          </div>
        </Stack.Item>
        <Stack.Item grow>
          <Pie data={data4Pie} options={{
            legend: {
              labels: {
                fontColor: theme.semanticColors.bodyText
              }
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem: any, data: any) => {
                  const dsIndex = tooltipItem.datasetIndex;
                  const i = tooltipItem.index;
                  let label = data.labels[i];
                  if (label) {
                    label += ': ';
                  }
                  const value = data.datasets[dsIndex].data[i] as number;
                  label += value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 0 }) + "%";
                  return label;
                }
              }
            }
          }} />
        </Stack.Item>
      </Stack>
      <div>
        <DetailsList selectionMode={SelectionMode.none}
          items={[...item.development].sort((a, b) => {
            if (a.quality < b.quality) {
              return -1;
            } else if (a.quality > b.quality) {
              return 1;
            }
            if (a.weight > b.weight) {
              return -1;
            } else if (a.weight < b.weight) {
              return 1;
            }
            return 0;
          })}
          constrainMode={ConstrainMode.horizontalConstrained}
          columns={[
            { key: "calibre", minWidth: 50, maxWidth: 100, name: "Calibro", isCollapsible: false, fieldName: "calibre" },
            { key: "quality", minWidth: 50, maxWidth: 100, name: "Qualità", isCollapsible: false, fieldName: "quality" },
            { key: "weight", minWidth: 50, maxWidth: 100, name: "Peso (kg)", isCollapsible: false, onRender: weightRenderHandler },
            { key: "percent", minWidth: 50, maxWidth: 100, name: "%", isCollapsible: false, onRender: percentRenderHanlder }
          ]}
        ></DetailsList>
      </div>
    </div>
  );
}
