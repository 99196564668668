import React, { useEffect, useState } from 'react'
import { ShimmeredDetailsList, Panel, IColumn } from '@fluentui/react'
import Page from '../../hoc/Page/Page';
import EasyForm from '../../components/Form/Form';
import withApiWrapper from '../../hoc/HttpClientWrapper/HttpClientWrapper'
import { IHttpClient } from '../../shared/easyAxios'
import { IODataList } from '../../models/odata';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { FormDefinitions } from '../../types/Form';

const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme
})

const connector = connect(mapStateToProps);
type PropsFromStore = ConnectedProps<typeof connector>;

interface IUserProps extends RouteComponentProps<IUserRouteProps>, PropsFromStore {
  easyClient: IHttpClient;
}

interface IUserRouteProps {
  viewname: string;
}

interface IUser {
  id?: string;
  username?: string;
  name?: string;
  partners?: string[];
}

const createFormDefinition: (user: IUser, easyClient: IHttpClient) => FormDefinitions = (user) => {
  return {
    "username": {
      elementType: "input",
      elementConfig: {
        label: 'Username',
        readOnly: true
      },
      value: user.id ?? "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    },
    "name": {
      elementType: "input",
      elementConfig: {
        label: 'Nome',
        readOnly: true
      },
      value: user.name ?? "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    },
    "partners": {
      elementType: "input",
      elementConfig: {
        label: 'Codici socio'
      },
      value: user.partners?.join(",") ?? "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
    }
  }
}

const columns: IColumn[] = [
  {
    key: "username",
    minWidth: 100,
    maxWidth: 100,
    name: "Username",
    fieldName: "username"
  },
  {
    key: "name",
    minWidth: 100,
    name: "Nome",
    fieldName: "name"
  },
  {
    key: "partners",
    minWidth: 100,
    name: "Codici socio",
    fieldName: "partners"
  }
]

enum EditMode {
  Edit
}

const UserPartners: React.FC<IUserProps> = (props) => {
  const easyClient = props.easyClient;
  const [items, setItems] = useState<IUser[]>([]);
  const [detailOpened, setDetailOpened] = useState(false);
  const [currentEditItem, setCurrentEditItem] = useState<IUser>({});
  const [currentFormDefinition, setCurrentFormDefinition] = useState<FormDefinitions>({});
  const [formIsValid, setFormIsValid] = useState(false);
  const [submit, setSubmit] = useState<any>(null);
  const [editMode, setEditMode] = useState(EditMode.Edit);
  useEffect(() => {
    let url = "/api/Users?$orderby=name";
    easyClient.get<IODataList<IUser>>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setItems(response.data.value as IUser[]);
      })
  }, [easyClient])

  const submitEnabled = true;
  const selectItemHandler = (item?: IUser) => {
    if (!item)
      return;
    setEditMode(EditMode.Edit);
    setCurrentEditItem(item);
    setCurrentFormDefinition(createFormDefinition(item, props.easyClient));
    setDetailOpened(true);
  }
  const closeDetailHandler = (): void => {
    setDetailOpened(false);
  }
  const formChangedHandler = (updatedForm: FormDefinitions, isValid: boolean): void => {
    setCurrentFormDefinition(updatedForm);
    setFormIsValid(isValid);
  }
  const formSubmitHandler = (): void => {
    setSubmit({});
  }
  const renderItemColumn = (item?: IUser, index?: number | undefined, column?: IColumn | undefined) => {
    if (!item || !column)
      return;
    const fieldContent = item[column.fieldName as keyof IUser];
    switch (column.key) {
      case 'partners':
        return <span>{(fieldContent as string[]).join(",") ?? ""}</span>
      // case 'enabled':
      //   return <span>{fieldContent ? 'Si' : 'No'}</span>
      default:
        return <span>{fieldContent?.toString()}</span>;
    }
  }

  useEffect(() => {
    if (submit && formIsValid) {
      setSubmit(null);

      currentEditItem.partners = [];
      if (currentFormDefinition.partners.value) {
        currentEditItem.partners = (currentFormDefinition.partners.value as string).split(",");
      }

      easyClient.post<IUser>(`/api/Users('${currentEditItem.id}')/SetPartners`,
        {
          "PartnerIds": currentEditItem.partners
        },
        { headers: { "X-version": "1.0" } })
        .then(response => {
          const localAllItems = [...items];
          const index = localAllItems.indexOf(currentEditItem);
          if (index !== -1) {
            localAllItems.splice(index, 1, response.data);
          }
          setItems(localAllItems);
          setDetailOpened(false);
        })

    }
  }, [submit, formIsValid, easyClient, currentFormDefinition, currentEditItem, items, editMode]);

  // const viewAllItemsHandler = () => {
  //   props.history.push(documentTypeRoute.pathForDocumentType())
  // }
  // const viewOnlyEnabledHandler = () => {
  //   props.history.push(documentTypeRoute.pathForDocumentType(ListView.OnlyEnabled))
  // }
  // const viewOnlyDisabledHandler = () => {
  //   props.history.push(documentTypeRoute.pathForDocumentType(ListView.OnlyDisabled))
  // }

  return (
    <Page title="Utenti soci" iconName="UserOptional" pageWidth="960px" theme={props.theme}>
      {/* <DocumentTypeCommandBar
        viewAllItemsClicked={viewAllItemsHandler}
        viewOnlyEnabledClicked={viewOnlyEnabledHandler}
        viewOnlyDisabledItemsClicked={viewOnlyDisabledHandler}
        currentView={listView}
      /> */}
      <ShimmeredDetailsList
        items={items}
        onItemInvoked={selectItemHandler}
        columns={columns}
        onRenderItemColumn={renderItemColumn}
      />
      <Panel
        headerText="Utenti - Dati socio"
        isOpen={detailOpened}
        onDismiss={closeDetailHandler}
        closeButtonAriaLabel="Close"
      >
        <EasyForm
          theme={props.theme}
          formDefinition={currentFormDefinition}
          onChange={formChangedHandler}
          onSubmit={formSubmitHandler}
          submitEnabled={submitEnabled}
        />
      </Panel>
    </Page>
  );
}

export default connector(withRouter(withApiWrapper(UserPartners)));
