// export interface IDocument {
//   id:                     string;
//   documentTypeId:         string;
//   fileNameOriginal:       string;
//   fileNameStorage:        string;
//   needMetadataCollection: boolean;
//   sequenceId:             number;
//   publisherGroupId:       string;
//   publishedTimestamp:     string;
//   eTag:                   string;
//   canceled:               boolean;
//   recipients:             Recipient[];
//   contentMetadata:        any[]; //todo
//   creationLog:            Log;
//   lastChangeLog:          Log;
// }


// export interface Log {
//   timestamp: string;
//   userId:    null;
// }

// // export enum DocumentTypeID {
// //   Docr = "DOCR",
// //   Doct = "DOCT",
// //   Pogr = "POGR",
// //   Posi = "POSI",
// //   SCP = "SCP",
// //   Sper = "SPER",
// //   Spet = "SPET",
// // }

// export interface Recipient {
//   groupId:     string;
//   description: string;
//   status:      ReadStatus;
//   acquired:    null;
// }

// export enum Description {
//   FruitModenaGroup = "FruitModenaGroup",
//   GruppoSistema = "Gruppo Sistema",
// }

// export enum ReadStatus {
//   New = "New",
//   Acquired = "Acquired",
//   AutoAcquired = "AutoAcquired",
// }


// Generated by https://quicktype.io

export interface IDocument {
  id: string;
  documentTypeId: string;
  documentTypeDesc: string;
  fileNameOriginal: string;
  fileNameStorage: string;
  fileSize?: number;
  needMetadataCollection: boolean;
  sequenceId: number;
  publisherGroupId: string;
  publisherGroupDesc: string;
  publishedTimestamp: Date;
  eTag: string;
  canceled: boolean;
  recipients: IRecipient[];
  contentMetadata: Record<string, any>[];
  creationLog: ILogData;
  lastChangeLog: ILogData;
}



export interface IRecipient {
  groupId: string;
  description: string;
  status: Status;
  acquired: ILogData;
}

export interface ILogData {
  timestamp: Date;
  userId: string;
}

export enum Status {
  New = "New",
  Acquired = "Acquired",
  AutoAcquired = "AutoAcquired",
}
