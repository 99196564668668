import React, { FormEvent } from 'react';
import { DetailsList, IColumn, SelectionMode, mergeStyleSets, IStyle, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { IProvisionListItemSummary } from '../../../containers/ProvisionReport/types';
import { desktop } from '../../../shared/mediaQuery';

export const groupValueToString: (value: string) => string = (value) => {
  switch (value) {
    case "**EMPTY**":
      return "(vuoto)"
    case "**ALL**":
      return "(tutti)";
    default:
      return value;
  }
}

interface IProvisionListSummaryProp {
  items: IProvisionListItemSummary[];
  onSelected: (item?: any, index?: number | undefined, ev?: Event | undefined) => void;

  groupOptions: IChoiceGroupOption[];
  group: string;
  onChangeSummary: (groupKey: string) => void;

}

const classes = mergeStyleSets({
  "ProvisionListSummary": {} as IStyle,
  "Group": {
    display: "block",
    selectors: {
      [desktop]: {
        display: "flex",
        selectors: {
          ">div": {
            margin: "0 0.5rem"
          }
        }
      }
    }
  } as IStyle
})

const columns: IColumn[] = [
  { key: 'weight', name: 'Netto(Kg)', fieldName: 'weight', minWidth: 100, isResizable: true, isCollapsible: false },
  { key: 'sampled', name: 'Campionato(Kg)', fieldName: 'sampled', minWidth: 100, isResizable: true, isCollapsible: false }
]

const renderItemColumn = (item?: IProvisionListItemSummary, index?: number | undefined, column?: IColumn | undefined) => {
  if (!item || !column)
    return;
  const fieldContent = item[column.fieldName as keyof IProvisionListItemSummary];

  switch (column.key) {
    case "groupField":
      return <span>{groupValueToString(fieldContent?.toString())}</span>
    default:
      return <span>{(fieldContent ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>;
  }
}

export const ProvisionListSummary: React.FC<IProvisionListSummaryProp> = ({ items, onSelected, onChangeSummary, groupOptions, group }) => {

  const changeSummaryView = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
    if (!option)
      return
    onChangeSummary(option.key);
  }

  const groupSelected = groupOptions.find(g => g.key === group);
  if (!groupSelected) {
    throw new Error("Gruppo non valido");
  }

  const summaryColumn = { key: "groupField", name: groupSelected.text, fieldName: "groupField", minWidth: 50, isCollapsible: false } as IColumn;
  return (
    <div className={classes.ProvisionListSummary}>
      <ChoiceGroup styles={{ flexContainer: classes.Group }} defaultSelectedKey={group} value={group} options={groupOptions} onChange={changeSummaryView} label="Riepilogo per" />
      <DetailsList
        items={items}
        columns={[summaryColumn, ...columns]}
        onRenderItemColumn={renderItemColumn}
        selectionMode={SelectionMode.none}
        onItemInvoked={onSelected}
      />
    </div>

  )
}
