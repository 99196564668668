import { IStyle, mergeStyleSets, Stack, Link } from '@fluentui/react';
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EasySection from '../../components/EasySection/EasySection';
import Page from '../../hoc/Page/Page';
import { desktop } from '../../shared/mediaQuery';
import * as areas from '../Area/route';
import * as documentTypes from '../DocumentType/DocumentType.route';
import * as documentGroup from '../DocumentGroup/DocumentGroup.route';
import * as user from '../User/route';
import * as users from '../User/Users.route'
import { IEasyStore } from '../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import{canViewDeliveryNotice, canViewProvisionReport} from '../../store/reducers/context.functions';

const mapStateToProps = (store: IEasyStore) => ({
  theme: store.context.fullTheme,
  modules: store.context.modules,
  partners: store.context.partners,
  roles: store.context.roles
})

const connector = connect(mapStateToProps);
type PropsFromStore = ConnectedProps<typeof connector>;

const styles = mergeStyleSets({
  "Box": {
    boxSizing: "border-box",
    width: "100%",
    selectors: {
      [desktop]: {
        width: "auto"
      }
    }
  },
  "BoxItems": {
    padding: "0.25em"
  } as IStyle
})

const boxes = [{
  key: "company",
  title: "Azienda",
  items: [{
    key: "areas",
    url: areas.path,
    text: "Configura le aree per i partners"
  }, {
    key: "users",
    url: users.path,
    text: "Gestisci gli utenti"
  }]
}, {
  key: "documents",
  title: "Documenti",
  items: [{
    key: "documentType",
    url: documentTypes.pathForDocumentType(),
    text: "Gestisci i tipi documento"
  }
    , {
    key: "groups",
    url: documentGroup.path,
    text: "Gestisci i gruppi utente"
  }
  ]
}
];

const provisionBox ={
  key: "provision",
  title: "Conferimento",
  items: [
    {
      key: "partner",
      url: user.userPartnersPath,
      text: "Configura i dati dei soci"
    }
  ]
}

const settings: React.FC<RouteComponentProps & PropsFromStore> = (props) => {

if (canViewDeliveryNotice(props.modules, props.partners, props.roles)
|| canViewProvisionReport(props.modules, props.partners, props.roles))
{
  boxes.push(provisionBox);
}

  const boxesMapped = boxes.map(b => (<Stack.Item key={b.key} className={styles.Box}>
    <EasySection title={b.title}>
      {b.items.map(u => (
        <Stack.Item key={u.key} className={styles.BoxItems} >
          <Link onClick={() => {
            props.history.push(u.url);
          }}>{u.text}</Link>
        </Stack.Item>
      ))}
    </EasySection>
  </Stack.Item>))

  return (
    <Page title="Impostazioni" iconName="Settings" pageWidth="960px" theme={props.theme}>
      <Stack horizontal wrap tokens={{ childrenGap: "1rem" }} horizontalAlign="center">
        {boxesMapped}
      </Stack>
    </Page>
  )
}

export default connector(withRouter(settings));
