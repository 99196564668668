import React from 'react';
import { Stack, DefaultButton, ITheme } from '@fluentui/react';

import { ISpecieCardProps, ProvisionCard } from '../Card/Card';


export interface IListVarietyProps {
    items: Array<ISpecieCardProps & { key: string }>;
    showProvisionsDetail: (items: ISpecieCardProps & { key: string }) => void;
    showProvisionSummary: (items: ISpecieCardProps & { key: string }) => void;
    theme: ITheme;
}
const ListVariety: React.FC<IListVarietyProps> = (props) => {
    return (
        <Stack horizontal wrap horizontalAlign="center" tokens={{ childrenGap: "2rem" }} styles={{ inner: { margin: 0 } }}>
            {props.items.map(c => {
                return (
                    <Stack.Item key={'variety_' + c.key} grow>
                        <ProvisionCard theme={props.theme} title={c.title} delivered={c.delivered} sampled={c.sampled}>
                            <DefaultButton text="Dettaglio" onClick={() => props.showProvisionsDetail(c)} />
                            <DefaultButton text="Riepilogo" onClick={() => props.showProvisionSummary(c)} />
                        </ProvisionCard>
                    </Stack.Item>)
            })}
        </Stack>
    )
}

export default ListVariety;
