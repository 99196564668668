import React from 'react'
import { IProvisionListItem, IReportData } from '../types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ProvisionList } from '../../../components/ProvisionReport/ProvisionList/ProvisionList';
import { pathForProvisionReport } from '../../../shared/route';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../../store/store';


const mapStateToProps = (state: IEasyStore) => {
  return {
    theme: state.context.fullTheme
  }
}
const connector = connect(mapStateToProps);
type PropsFromStore = ConnectedProps<typeof connector>;

interface IListProvisionContainer extends RouteComponentProps<{
  specie: string,
  variety: string
}>, PropsFromStore {
  // items: IProvisionListItem[];
  reportData: IReportData;
}

const ListProvisionContainer: React.FC<IListProvisionContainer> = (props) => {
  const reportData = props.reportData;
  const { specie, variety } = props.match.params;
  const history = props.history;

  const onSelectedHanlder = (item: IProvisionListItem) => {
    const path = pathForProvisionReport(reportData.partnerCode, reportData.yearCode, item.id);
    history.push(path)
  }
  const items = reportData.provisions.filter(p => p.specie === specie && p.variety === variety)
    .sort((a, b) => -1 * a.bem.localeCompare(b.bem))
    .map<IProvisionListItem>(p => {
      const sampledWeight = reportData.sampling.filter(s => s.receiptId === p.provisionId).reduce((total, next) => total + next.developedWeight, 0)
      return {
        bem: p.bem,
        date: p.bemDate,
        ddt: p.transportDocNo,
        differential: p.differential,
        id: p.provisionId,
        parcel: p.parcels,
        partnerCode: reportData.partnerCode,
        specie: p.specie,
        status: sampledWeight > 0 ? "sampled" : "provisioned",
        type: p.type,
        variety: p.variety,
        year: reportData.yearCode,
        work: p.work,
        weight: p.weight,
        sampled: sampledWeight
      }
    });

  return (
    <ProvisionList theme={props.theme} items={items} onSelected={onSelectedHanlder} />
  );
}

export default connector(withRouter(ListProvisionContainer));
