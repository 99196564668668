import React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';


export interface IAreaCommandBarProp {
  newItemClicked: () => void,
  deleteItemClicked: () => void,
  disabled?: boolean,
  canDelete?: boolean,
};

const AreaCommandBar: React.FC<IAreaCommandBarProp> = (props) => {

  const _items: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      text: 'Nuovo',
      iconProps: { iconName: 'Add' },
      disabled: props.disabled || false,
      onClick: props.newItemClicked
    },
    {
      key: 'deleteItem',
      text: 'Elimina',
      iconProps: { iconName: 'Delete' },
      disabled: props.disabled || !props.canDelete || false,
      onClick: props.deleteItemClicked
    },
  ];

  return (
    <CommandBar
      items={_items}
    />
  );

}

export default AreaCommandBar;
