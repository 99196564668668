import React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

type BtnType = "default" | "primary";

interface IEasyButtonProps {
    btnType: BtnType;
    disabled: boolean;
    clicked?: () => void;
}

const EasyButton: React.FC<IEasyButtonProps> = (props) => {

    let button;
    if (props.btnType === "default")
        button = (
            <DefaultButton disabled={props.disabled} onClick={props.clicked} type="submit">
                {props.children}
            </DefaultButton>);
    else {
        button = (
            <PrimaryButton disabled={props.disabled} onClick={props.clicked} type="submit">
                {props.children}
            </PrimaryButton>
        )
    }

    return (button);

}

export default EasyButton;