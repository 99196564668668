import React, { useCallback, useRef } from 'react';
import { useBoolean } from '@uifabric/react-hooks';

import Logo from '../../Logo/Logo';
import { SearchBox, Stack } from '@fluentui/react';
import Hamburger from '../Hamburger/Hamburger';
import PersonaWithMenu from './PersonaWithMenu/PersonaWithMenu';
import Settings from './Settings/Settings';

import headerStyles from './Header.styles';
import { IHeaderProps } from "./types";

const Header: React.FC<IHeaderProps> = (props) => {

  const [isSettingsPanelOpen, { setTrue: openSettingsPanel, setFalse: dismissSettingsPanel }] = useBoolean(false);

  const styles = headerStyles(props.theme);
  const { setSearchFilter } = props;
  let timerIdRef = useRef(0);
  let searchHanlder = useCallback((event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    const timerId = timerIdRef.current;
    if (timerId) {
      window.clearTimeout(timerId);
    }
    timerIdRef.current = window.setTimeout(() => {
      setSearchFilter(newValue ?? "");
    }, 400)
  }, [setSearchFilter])


  return (
    <>
      <Stack horizontal styles={styles.header} verticalAlign="center">
        <Stack.Item grow={false} styles={styles.hamburger}>
          <Hamburger click={props.openMobileMenu} theme={props.theme} />
        </Stack.Item>
        <Stack.Item grow={false} styles={styles.logo}>
          <Logo onClick={props.logoClicked} />
        </Stack.Item>
        <Stack.Item grow styles={styles.search}>
          <SearchBox placeholder="Cerca"
            value={props.searchFilter}
            onChange={searchHanlder} />
        </Stack.Item>
        <Stack.Item grow={false} styles={styles.settings} >
          <Settings
            allSettingClicked={props.allSettingClicked}
            isOpen={isSettingsPanelOpen}
            openPanelClicked={openSettingsPanel}
            dismissPanel={dismissSettingsPanel}
          />
        </Stack.Item>
        <Stack.Item grow={false}>
          <PersonaWithMenu
            personaData={props.personaData}
            signOut={props.signOut}
            openSettingsPanelClicked={openSettingsPanel}
            theme={props.theme} />
        </Stack.Item>
      </Stack>
    </>
  );
}

export default Header;
