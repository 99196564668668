import React, { useEffect, useRef, useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, elementContains, Icon, IconButton, IDropdownOption, IRenderFunction, Link, PrimaryButton, TextField, TooltipHost } from '@fluentui/react';
import ReactQuill, { Quill } from 'react-quill';
import { IRichTextProps } from './RichText.types';
import { get_uuid } from '../../../shared/utility';
import richTextStyle from './RichText.style';
import 'react-quill/dist/quill.snow.css';

const TOOLBARPADDING: number = 28;


/**
 * Creates a rich text editing control that mimics the out-of-the-box
 * SharePoint Rich Text control.
 * NOTE:
 * Quill.js has a few quirks that we can't work around
 * - Block quotes only work on single lines. This is a frequently-requested feature with Quill that isn't available yet.
 * - Tables aren't supported yet. I'll gladly add table formatting support if users request it.
 */



// export interface IRichTextProps {
//   value?: string;
//   onChange: (value?: string) => void;
//   disabled?: boolean;
// }

const RichText: React.FC<IRichTextProps> = (props) => {
  const [text, setText] = useState<string>(props.value || "");
  const [editing, setEditing] = useState<boolean>(false);
  const [formats, setFormats] = useState<any>({});
  const [hideDialog, setHideDialog] = useState<boolean>(true);
  const [insertUrl, setInsertUrl] = useState<string>();
  const [insertUrlText, setInsertUrlText] = useState<string>();
  const [selectedRange, setSelectedRange] = useState<any>();
  const [selectedText, setSelectedText] = useState<string>();
  const [selectedUrl, setSelectedUrl] = useState<string>();
  const [wrapperTop, setWrapperTop] = useState<number>(0);

  const _toolbarId = useRef("toolbar_" + get_uuid());
  const _quillElem = useRef<ReactQuill>();
  const _wrapperRef = useRef<HTMLDivElement>();

  const prevIsEdiMode = useRef<boolean>();

  const styles = richTextStyle(props.theme);

  const ddStyleOpts = [{
    key: 0,
    text: "Testo normale",
    data: {}
  },
  {
    key: 1,
    text: "Titolo 1",
    data:
      { className: styles.toolbarButtonH1 }
  },
  {
    key: 2,
    text: "Titolo 2",
    data:
      { className: styles.toolbarButtonH2 }
  },
  {
    key: 3,
    text: "Titolo 3",
    data:
      { className: styles.toolbarButtonH3 }
  },
  {
    key: 4,
    text: "Titolo 4",
    data:
      { className: styles.toolbarButtonH4 }
  },
  {
    key: 5,
    text: "Titolo 5",
    data:
      { className: styles.toolbarButtonH5 }
  },
  {
    key: 6,
    text: "Blocco citazione",
    data:
      { className: styles.toolbarButtonBlockQuote }
  }];

  const ddAlignOpts = [{
    key: 'left',
    text: "Allinea a sinistra",
    data: { icon: 'AlignLeft' }
  }, {
    key: 'center',
    text: "Allinea al centro",
    data: { icon: 'AlignCenter' }
  }, {
    key: 'right',
    text: "Allinea a destra",
    data: { icon: 'AlignRight' }
  }, {
    key: 'justify',
    text: "Giustifica",
    data: { icon: 'AlignJustify' }
  }];

  const ddListOpts = [{
    key: 'bullet',
    text: "Elenco puntato",
    data: { icon: 'BulletedList' }
  }, {
    key: 'ordered',
    text: "Elenco numerato",
    data: { icon: 'NumberedList' }
  }];

  const ddFontSizeOpts = [
    { key: 'small', text: '12' },
    { key: 'medium', text: '14' },
    { key: 'mediumplus', text: '15' },
    { key: 'large', text: '17' },
    { key: 'xlarge', text: '21' },
    { key: 'xlargeplus', text: '24' },
    { key: 'xxlarge', text: '28' },
    { key: 'xxxlarge', text: '32' },
    { key: 'xxlargeplus', text: '36' },
    { key: 'super', text: '42' },
  ];


  /**
   * Displays the insert link dialog
   */
  const showInsertLinkDialog = () => {
    const quill = getEditor()!;
    let range: any = quill.getSelection() || {};

    let linkText = selectedText;
    if (selectedUrl !== undefined && selectedText === "") {
      const urlStartIndex = text.indexOf(selectedUrl);
      const startTextIndex = text.indexOf(">", urlStartIndex) + 1;
      const endTextIndex = text.indexOf("<", startTextIndex);
      const realLength = endTextIndex - startTextIndex;
      linkText = text.substr(startTextIndex, realLength);

      //Find where the link text starts and select that
      const editorText = quill!.getText();
      const linkStart = editorText.indexOf(linkText);
      range.index = linkStart;
      range.length = linkText.length;
    }

    setHideDialog(false);
    setInsertUrlText(linkText);
    setInsertUrl(selectedUrl);
    setSelectedRange(range);
  }

  /**
   * Hides the insert link dialog
   */
  const closeDialog = () => {
    setHideDialog(true);
  }

  /**
   * When user enters the richtext editor, displays the border
   */
  const handleOnFocus = (range: any, source: any, editor: any) => {
    if (!editing) {
      setEditing(true);
    }
  }

  /**
   * Called when user removes the link
   */
  const handleRemoveLink = () => {
    const quill = getEditor()!;
    quill.format('link', false);
    closeDialog();
  }

  /**
   * Called when user creates a new link
   */
  const handleCreateLink = () => {
    const quill = getEditor()!;
    const range = selectedRange;
    const cursorPosition: number = range!.index;
    if (range.length > 0) {
      quill.deleteText(range.index, range.length);
    }

    if (cursorPosition > -1) {
      const textToInsert: string = ((insertUrlText !== undefined && insertUrlText !== "") ? insertUrlText : insertUrl) || "";
      const urlToInsert: string = insertUrl || "";
      quill.insertText(cursorPosition, textToInsert);
      quill.setSelection(cursorPosition, textToInsert.length);
      quill.formatText(cursorPosition, textToInsert.length, 'link', urlToInsert);
    }

    setHideDialog(true);
    setInsertUrl(undefined);
    setInsertUrlText(undefined);
  }

  /**
   * Disable Save-button if hyperlink is undefined or empty
   * This prevents the user of adding an empty hyperlink
   */
  const checkLinkUrl = () => {
    if (insertUrl !== undefined && insertUrl !== "") {
      return false;
    }
    return true;
  }

  /**
   * Applies a format to the selection
   * @param name format name
   * @param value format value, or false to unset format
   */
  const applyFormat = (name: string, value: any) => {
    const quill = getEditor()!;
    quill.format(name, value);

    // We use a timeout to ensure that format has been applied and buttons are updated
    setTimeout(() => {
      handleChangeSelection(quill.getSelection(), undefined, undefined);
    }, 100);
  }

  /**
   * Called when richtext selection changes
   */
  const handleChangeSelection = (range: any, oldRange: any, source: any) => {
    const quill = getEditor();
    try {
      if (quill) {
        // Get the selected text
        setSelectedText(quill.getText(range));

        // Get the current format
        setFormats(quill.getFormat(range));

        // Get the currently selected url
        setSelectedUrl(formats.link ? formats.link : undefined);
      }
    } catch (error) {

    }
  }

  /**
   * Called when user changes the text of the editor
   */
  // const handleChange = (value: string) => {
  //   const { onChange } = props;
  //   // do we need to pass this to a handler?
  //   if (onChange) {
  //     // yes, get the changed text from the handler
  //     let newText: string = onChange(value);
  //     setText(newText);
  //   } else {
  //     // no, write the text to the state
  //     setText(value);
  //   }
  // }

  const handleChange = (value: string) => {
    setText(value);
    props.onChange && props.onChange(value);
  }

  /**
   * Links to the quill reference
   */
  const linkQuill = (e: any) => {
    _quillElem.current = e;
  }

  useEffect(() => {

    /**
     * Keeps track of whether we clicked outside the element
     */
    const handleClickOutside = (event: any) => {
      let outside: boolean = !_wrapperRef.current || !elementContains(_wrapperRef.current, event.target);

      // Did we click outside?
      if (outside) {
        // If we are currently editing, stop editing
        // -- unless we're using the property pane or the dialog
        if (editing) {
          //handleChange(text); // <-- spostato qui perché farlo ad ogni cambio di text impazziva
          setEditing(false);
        }
      } else {
        // We clicked inside
        if (!editing) {
          // if we aren't currently editing, start editing
          setEditing(true);
        }
      }
    }

    // If we're in edit mode, attach the mouse down event
    if ((!(prevIsEdiMode.current || false)) && props.isEditMode) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('focus', handleClickOutside);
    }
    if ((prevIsEdiMode.current || false) && !props.isEditMode) {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('focus', handleClickOutside);
    }

    if (_wrapperRef.current) {
      const clientRect: ClientRect = _wrapperRef.current!.getBoundingClientRect();
      const parentClientRect: ClientRect = _wrapperRef.current!.parentElement!.getBoundingClientRect();
      const toolbarTop: number = clientRect.top - parentClientRect.top - TOOLBARPADDING;

      setWrapperTop(toolbarTop);
    }

    return () => {
      if (props.isEditMode) {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('focus', handleClickOutside);
      }
    }
  }, [editing, props.isEditMode])

  useEffect(() => {
    setText(props.value || "")
  }, [props.value])


  const getEditor = () => {
    try {
      return _quillElem.current!.getEditor();
    } catch (error) {
      return undefined;
    }
  };

  /**
   * Render style option
   *
   * @param option
   */
  const onRenderStyleOption: IRenderFunction<IDropdownOption> = (option) => {
    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-toolbarButton`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={`${option.data!.className ? option.data!.className : ""}`}
            aria-describedby={`${option.text}-toolbarButton`}>
            <span>{option.text}</span>
          </div>
        </TooltipHost>
      )
      : null
    );
  }

  /**
   * Render the title of the style dropdown
   *
   * @param options
   */
  const onRenderStyleTitle: IRenderFunction<IDropdownOption[]> = (options) => {
    const option = options ? options[0] : undefined;

    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-dropDownTitle`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={styles.toolbarSubmenuDisplayButton}
            aria-describedby={`${option.text}-dropDownTitle`}>
            <span>{option.text}</span>
          </div>
        </TooltipHost>
      )
      : null
    );
  }


  /**
   * Render align option
   *
   * @param option
   */
  const onRenderAlignOption: IRenderFunction<IDropdownOption> = (option) => {
    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-toolbarButton`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={`${option.data!.className ? option.data!.className : ""}`}
            aria-describedby={`${option.text}-toolbarButton`}>
            <Icon className={styles.toolbarDropDownIcon}
              iconName={option.data.icon}
              aria-hidden="true" />
          </div>
        </TooltipHost>
      )
      : null
    );
  }

  /**
   * Render the list dropdown title
   *
   * @param options
   */
  const onRenderListTitle: IRenderFunction<IDropdownOption[]> = (options) => {
    const option = options ? options[0] : undefined;

    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-dropDownTitle`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={styles.toolbarSubmenuDisplayButton}
            aria-describedby={`${option.text}-dropDownTitle`}>
            <Icon className={styles.toolbarDropDownTitleIcon}
              iconName={option.data.icon}
              aria-hidden="true" />
          </div>
        </TooltipHost>
      )
      : null
    );
  }

  /**
   * Render the title of the align dropdown
   *
   * @param options
   */
  const onRenderAlignTitle: IRenderFunction<IDropdownOption[]> = (options) => {
    const option = options ? options[0] : undefined;

    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-dropDownTitle`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={styles.toolbarSubmenuDisplayButton}
            aria-describedby={`${option.text}-dropDownTitle`}>
            <Icon className={styles.toolbarDropDownTitleIcon}
              iconName={option.data.icon}
              aria-hidden="true" />
          </div>
        </TooltipHost>
      )
      : null
    );
  }

  /**
   * Render list dropdown option
   *
   * @param option
   */
  const onRenderListOption: IRenderFunction<IDropdownOption> = (option) => {
    return (option
      ? (
        <TooltipHost
          content={option.text}
          id={`${option.text}-toolbarButton`}
          calloutProps={{ gapSpace: 0 }}>
          <div className={`${option.data!.className ? option.data!.className : ""}`}
            aria-describedby={`${option.text}-toolbarButton`}>
            <Icon className={styles.toolbarDropDownIcon}
              iconName={option.data.icon}
              aria-hidden="true" />
          </div>
        </TooltipHost>
      )
      : null
    );
  }

  /**
   * Render the list dropdown placeholder
   */
  const onRenderListPlaceholder = (): JSX.Element => {
    return (
      <TooltipHost
        content={"Elenco"}
        id={`Placeholder-dropDownTitle`}
        calloutProps={{ gapSpace: 0 }}>
        <div className={styles.toolbarSubmenuDisplayButton}
          aria-describedby={`Placeholder-dropDownTitle`}>
          <Icon className={styles.toolbarDropDownTitleIcon}
            iconName={'BulletedList'}
            aria-hidden="true" />
        </div>
      </TooltipHost>
    );
  }

  /**
   * Renders the "Insert Link" dialog
   */
  const renderLinkDialog = (): JSX.Element => {
    return (
      <Dialog
        hidden={hideDialog}
        onDismiss={closeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Inserisci collegamento",
        }}
        modalProps={{
          className: styles.insertLinkDialog,
          isBlocking: true,
          containerClassName: 'ms-dialogMainOverride'
        }}>
        <TextField
          label={"Url"}
          value={insertUrl !== undefined ? insertUrl : "https://"}
          onChange={(ev, newValue?: string) => { setInsertUrl(newValue) }} />

        <TextField label={"Testo da visualizzare"}
          value={insertUrlText}
          onChange={(ev, newValue?: string) => {
            if (newValue !== insertUrl) {
              setInsertUrlText(newValue);;
            }
          }} />

        <DialogFooter className={styles.actions}>
          <div className={`ms-Dialog-actionsRight ${styles.actionsRight}`}>
            {
              selectedUrl && (
                <Link className={`${styles.action} ${styles.unlinkButton}`} onClick={handleRemoveLink}>{"Rimuovi collegamento"}</Link>
              )
            }
            <PrimaryButton className={styles.action} onClick={handleCreateLink} text={"Salva"} disabled={checkLinkUrl()} />
            <DefaultButton className={styles.action} onClick={closeDialog} text={"Chiudi"} />
          </div>
        </DialogFooter>
      </Dialog>
    );
  }
  ////////////////////



  /**
   * Style trigger events
   */
  const onChangeBold = (): void => {
    const newBoldValue = !formats.bold;
    applyFormat("bold", newBoldValue);
  }

  const onChangeItalic = (): void => {
    const newValue = !formats.italic;
    applyFormat("italic", newValue);
  }

  const onChangeUnderline = (): void => {
    const newValue = !formats.underline;
    applyFormat("underline", newValue);
  }
  const onChangeHeading = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption | undefined): void => {
    const newHeadingValue = item?.key === 0 ? '' : item?.key.toString();
    applyFormat("header", newHeadingValue);
  }

  /**
   * On size change
   */
  const onChangeSize = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    const newSizeValue = item?.key === 0 ? '' : item?.key.toString();
    applyFormat("size", newSizeValue);
  }


  const onChangeAlign = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    const newAlignValue = item?.key === 'left' ? false : item?.key.toString();
    applyFormat("align", newAlignValue);
  }

  const onChangeList = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    // if we're already in list mode, toggle off
    const key = item?.key;
    const newAlignValue = (key === 'bullet' && formats.list === 'bullet') || (key === 'numbered' && formats.list === 'numbered') ? false : key;
    applyFormat("list", newAlignValue);
  }






  ////////////////////

  // If we're not in edit mode, display read-only version of the html
  if (!props.isEditMode) {
    return (
      <div className={styles.root}>
        <div className={` ql-editor ${styles.richtext} ${props.className || ''}`}
          dangerouslySetInnerHTML={{ __html: text }}>
        </div>
      </div>
    );
  }
  // Okay, we're in edit mode.
  const { placeholder, styleOptions } = props;

  // Get a unique id for the toolbar
  const modules = {
    toolbar: {
      container: "#" + _toolbarId.current,
      handlers: [
        "link" // disable the link handler so we can add our own
      ]
    },
    clipboard: {
      matchVisual: false // prevents weird bug that inserts blank lines when loading stored text
    }
  };

  // Remove fonts and set Segoe UI as the main font
  let font = Quill.import('formats/font');
  font.whitelist = ['Segoe UI'];
  Quill.register(font, true);

  // Set headers and add blockquote capability
  let header = Quill.import('formats/header');
  header.tagName = [
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'blockquote'];
  Quill.register(header, true);

  // Add the SharePoint font sizes
  let SizeClass = Quill.import('formats/size');
  SizeClass.whitelist = [
    'small',
    'medium',
    'mediumplus',
    'large',
    'xlarge',
    'xlargeplus',
    'xxlarge',
    'xxxlarge',
    'xxlargeplus',
    'super'];
  Quill.register(SizeClass, true);

  return (
    <div ref={(ref) => _wrapperRef.current = (ref !== null ? ref : undefined)} className={`${styles.richtext && editing ? 'ql-active' : ''} ${styles.root} edit-mode ${props.className}`}>
      <div id={_toolbarId.current} style={{ top: wrapperTop }}>
        {
          props.styleOptions?.showStyles && (
            <Dropdown
              id="DropDownStyles"
              className={`${styles.headerDropDown} ${styles.toolbarDropDown}`}
              onRenderCaretDown={() => <Icon className={styles.toolbarSubmenuCaret} iconName="CaretDownSolid8" />}
              selectedKey={formats.header || 0}
              options={ddStyleOpts}
              onChange={onChangeHeading}
              onRenderOption={onRenderStyleOption}
              onRenderTitle={onRenderStyleTitle}
            />
          )
        }
        {
          props.styleOptions?.showStyles && (
            <Dropdown
              id="DropDownFontSize"
              className={`${styles.fontSizeDropDown} ${styles.toolbarDropDown}`}
              onRenderCaretDown={() => <Icon className={styles.toolbarSubmenuCaret} iconName="CaretDownSolid8" />}
              selectedKey={formats?.size || 'large'}
              options={ddFontSizeOpts}
              onChange={onChangeSize}
            />
          )
        }
        {
          styleOptions?.showBold && (
            <TooltipHost content={"Grassetto"}
              id="bold-richtextbutton"
              calloutProps={{ gapSpace: 0 }}>
              <IconButton iconProps={{ iconName: 'Bold' }}
                aria-describedby="bold-richtextbutton"
                checked={formats.bold}
                onClick={onChangeBold}
              />
            </TooltipHost>
          )
        }
        {
          styleOptions?.showItalic && (
            <TooltipHost content={"Corsivo"}
              id="italic-richtextbutton"
              calloutProps={{ gapSpace: 0 }}>
              <IconButton iconProps={{ iconName: 'Italic' }}
                aria-describedby="italic-richtextbutton"
                checked={formats.italic}
                onClick={onChangeItalic}
              />
            </TooltipHost>
          )
        }
        {
          styleOptions?.showUnderline && (
            <TooltipHost content={"Sottolineato"}
              id="underline-richtextbutton"
              calloutProps={{ gapSpace: 0 }}>
              <IconButton iconProps={{ iconName: 'Underline' }}
                aria-describedby="underline-richtextbutton"
                checked={formats.underline}
                onClick={onChangeUnderline}
              />
            </TooltipHost>
          )
        }
        {
          styleOptions?.showAlign && (
            <Dropdown className={`${styles.toolbarDropDown}`}
              id="DropDownAlign"
              onRenderCaretDown={() => <Icon className={styles.toolbarSubmenuCaret} iconName="CaretDownSolid8" />}
              selectedKey={formats.align || 'left'}
              options={ddAlignOpts}
              onChange={onChangeAlign}
              onRenderOption={onRenderAlignOption}
              onRenderTitle={onRenderAlignTitle}
            />
          )
        }
        {
          styleOptions?.showList && (
            <Dropdown className={`${styles.toolbarDropDown}`}
              id="DropDownLists"
              onRenderCaretDown={() => <Icon className={styles.toolbarSubmenuCaret} iconName="CaretDownSolid8" />}
              selectedKey={formats.list}
              options={ddListOpts}
              onChange={onChangeList}
              onRenderOption={onRenderListOption}
              onRenderTitle={onRenderListTitle}
              onRenderPlaceholder={onRenderListPlaceholder}
            />
          )
        }
        {
          styleOptions?.showLink && (
            <TooltipHost content={"Collemgamento"}
              id="link-richtextbutton"
              calloutProps={{ gapSpace: 0 }}>
              <IconButton checked={formats!.link !== undefined}
                onClick={showInsertLinkDialog}
                aria-describedby="link-richtextbutton"
                iconProps={{
                  iconName: 'Link'
                }}
              />
            </TooltipHost>
          )
        }

      </div>

      <ReactQuill ref={linkQuill}
        placeholder={placeholder}
        modules={modules}
        value={text || ''} //property value causes issues, defaultValue does not
        onChange={(value) => handleChange(value)}
        onChangeSelection={handleChangeSelection}
        onFocus={handleOnFocus}
      />

      {
        renderLinkDialog()
      }

    </div>
  );
}

//RichText.whyDidYouRender = true;

export default RichText;
