import React from 'react';
import Background from '../Background/Background';
import { IImageStyles, Image, ImageFit, IStackItemStyles, IStackStyles, ITextStyles, ITheme, Stack, Text } from '@fluentui/react';

const imageStyle: IImageStyles = {
  root: {
    height: "60vmin",
    width: "60vmin",
    maxHeight: 300,
    maxWidth: 300,
  },
  image: {
    backgroundColor: "#ffffff",
    borderRadius: "5%",
  }
}


const stackItemStyle: IStackItemStyles = {
  root: {
    width: '100vw',
    textAlign: "center",
  }
}
const imageStackStyle: IStackItemStyles = {
  root: {
    paddingTop: "8vh",
  }
}

interface ISplashScreenProps{
  theme: ITheme;
}

const SplashScreen: React.FC<ISplashScreenProps> = (props) => {

  const theme = props.theme;

  const stackStyle: IStackStyles = {
    root: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,

      color: theme.semanticColors.bodyText,

      textAlign: "center",

    }
  }

  const appNameStyle: ITextStyles = {
    root: {
      fontWeight: "bold",
      color: theme.palette.themePrimary,
    }
  }

  return (
    <>
      <Background theme={theme} />
      <Stack verticalAlign="start" horizontalAlign="center" styles={stackStyle}>
        <Stack.Item styles={imageStackStyle}>
          <Image styles={imageStyle} src={process.env.PUBLIC_URL + "/ec_300.png"} imageFit={ImageFit.contain} maximizeFrame={true} alt="Easy Collaboration LOGO" />
        </Stack.Item>
        <Stack.Item styles={stackItemStyle}>
          {/* <h1 style={{ margin: 0, padding: "0.67rem 0" }}>EASY COLLABORATION</h1> */}
          <Text variant="xLarge" styles={appNameStyle}  >EASY COLLABORATION</Text>
        </Stack.Item>
        <Stack.Item styles={stackItemStyle}>
          {props.children}
        </Stack.Item>
      </Stack>
    </>
  );
}

export default SplashScreen;
