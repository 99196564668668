import React, { useEffect, useState } from 'react';
import { Image, Text, mergeStyleSets, Stack, ITheme } from '@fluentui/react';
import { INewsArticle } from '../News.types';
import { IHttpClient } from '../../../shared/easyAxios';
import RichText from '../../../components/UI/RichText/RichText';
import { connect } from 'react-redux';
import { IEasyStore } from '../../../store/store';


const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    articleImage: {
      border: "1px solid " + theme.palette.neutralLighter,
      marginBottom: "1em",
      // maxWidth: 500,
      // maxHeight: 500,
      // [desktop]: {
      //   width: 100,
      //   minHeight: 100,
      // }
    },
    articleDate: {
      color: theme.palette.neutralTertiary,
      marginBottom: "1em",
    },
    summary: {
      fontStyle: "italic",
      marginBottom: "0.4em",
    }
  })
};

interface INewsViewerPropsFromStore {
  theme: ITheme;
}

export interface INewsViewerProps extends INewsViewerPropsFromStore {
  articleId: string,
  easyClient: IHttpClient,
  onArticleLoading?: () => void,
  onArticleLoaded?: () => void,
  onArticleError?: () => void,
  refreshView: any,
}

export const NewsViewer: React.FC<INewsViewerProps> = ({ theme, articleId, easyClient, onArticleLoaded, onArticleLoading, onArticleError, refreshView }) => {
  const [article, setArticle] = useState<INewsArticle>();

  const classNames = getClassNames(theme);
  //const [items, setItems] = React.useState(originalItems);

  //const { articles } = props;

  // const resultCountText =
  // articles.length === originalItems.length ? '' : ` (${items.length} of ${originalItems.length} shown)`;

  // const onFilterChanged = (_: any, text: string): void => {
  //   setItems(originalItems.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) >= 0));
  // };


  useEffect(() => {
    if (articleId) {
      onArticleLoading && onArticleLoading();
      let url = `/api/Articles('${articleId}')?getThumbImage=false`;

      easyClient.get<INewsArticle>(url, { headers: { "X-version": "1.0" } })
        .then(response => {
          setArticle(response.data);
        })
        .catch(err => {
          console.log(err);
          setArticle(undefined);//Si è verificato un errore con il recupero dell'articolo: chiudo il viewer e torno alla lista
          onArticleError && onArticleError();
        })
        .finally(() => {
          onArticleLoaded && onArticleLoaded();
        });
    }
  }, [articleId, easyClient, onArticleError, onArticleLoaded, onArticleLoading, refreshView]);


  return (
    !article
      ? (<></>)
      : (
        <Stack styles={{ root: { padding: "0.5rem 1.3rem", "& *": { lineHeight: "130%" } } }} >
          {article.imageFileContent && (
            <div className={classNames.articleImage}>
              <Image src={article.imageFileContent} styles={{ image: { maxHeight: 400, minHeight: 60, maxWidth: "100%", height: "auto", margin: "auto" } }} />
            </div>
          )}
          <Text variant="xxLarge">{article.title}</Text>
          {article.summary && (<Text variant="mediumPlus" className={classNames.summary} >{article.summary}</Text>)}
          {article.articleDate && (<Text variant="small" className={classNames.articleDate} >{article.articleDate ? new Date(article.articleDate).toLocaleDateString() : ""}</Text>)}
          {article.body && (<RichText theme={theme} isEditMode={false} value={article.body} />)}
        </Stack >
      ));
};

const mapStateToProps = (state: IEasyStore) : INewsViewerPropsFromStore => {
  return {
    theme : state.context.fullTheme
  }
}

export default connect(mapStateToProps)(NewsViewer);
