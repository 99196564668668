import React from 'react';
import { IPersonaWithMenuProps } from "../types";
import personaWithMenuStyles from './PersonaWithMenu.styles';

import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from '@fluentui/react';

const PersonaWithMenu: React.FC<IPersonaWithMenuProps> = (props) => {

  const styles = personaWithMenuStyles(props.theme);

  const linkRef = React.useRef(null);
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault(); // don't navigate
    setShowContextualMenu(true);
  }, []);
  const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);
  const menuItems: IContextualMenuItem[] = [
    {
      key: "setings",
      text: "Impostazioni",
      onClick: () => props.openSettingsPanelClicked(),
      iconProps: {
        iconName: "Settings",
      },
    },
    {
      key: 'separ',
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: 'logoff',
      text: 'Esci',
      onClick: () => props.signOut(),
      iconProps: {
        iconName: "SignOut",
      }
    }
  ]

  return (
    <div ref={linkRef} onClick={onShowContextualMenu}>
      <Persona
        imageUrl={props.personaData.imageUrl ?? undefined}
        text={props.personaData.name}
        size={PersonaSize.size32}
        initialsColor={styles.customs.initials.personaBackgroundColor} hidePersonaDetails imageAlt="?" />
      <ContextualMenu
        items={menuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
    </div>
  );
}

export default PersonaWithMenu;
