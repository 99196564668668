import React, { useState, useCallback, useMemo } from 'react';
import easyClient, { IHttpClient } from '../../shared/easyAxios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { acquireTokenPopup } from '../../shared/azureLogin';

import AuthorizationDialog from '../../components/AuthorizationDialog/AuthorizationDialog';
import { AuthenticationResult } from '@azure/msal-browser';

import { Spinner } from '@fluentui/react';

export interface IWithInteractiveLoginProps {
  easyClient: IHttpClient
}

interface IPendingOperation {
  resolve: (result: any) => void;
  reject: (error: any) => void;
  pendingOperation: (authResult?: AuthenticationResult) => Promise<AxiosResponse<any>>
}

const withInteractiveLogin = <P extends IWithInteractiveLoginProps>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof IWithInteractiveLoginProps>> => (props: Pick<P, Exclude<keyof P, keyof IWithInteractiveLoginProps>>) => {

  const [showSpinner, setShowSpinner] = useState(false);
  const [pendingOperations, setPendingOperations] = useState<IPendingOperation[]>([])

  const requestWrapper: <T>(initialAuth: AuthenticationResult | undefined, request: (tk?: AuthenticationResult) => Promise<AxiosResponse<T>>) => Promise<AxiosResponse<T>> =
    useCallback((initialAuth, request) =>
      new Promise<AxiosResponse>((resolve, reject) => request(initialAuth)
        .then(response => { return resolve(response) })
        .catch(error => {
          if (error && error.errorCode && error.errorCode.indexOf("interaction_required") !== -1) {
            const pendingOperation: IPendingOperation = {
              pendingOperation: (authResult?: AuthenticationResult) => request(authResult),
              reject: reject,
              resolve: resolve
            }
            setPendingOperations((p) => [...p, pendingOperation])
          } else {
            reject("Errore imprevisto durante una richiesta ajax")
          }
        })
      ), []);

  const easyClientWithInteractiveLoginInterceptor = useMemo<IHttpClient>(() => {
    return {
      get: (url: string, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => requestWrapper(authResult, (auth?) => easyClient.get(url, config, auth)),
      delete: (url: string, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => requestWrapper(authResult, (auth?) => easyClient.delete(url, config, auth)),
      post: (url: string, data?: any, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => requestWrapper(authResult, (auth?) => easyClient.post(url, data, config, auth)),
      patch: (url: string, data?: any, config?: AxiosRequestConfig, authResult?: AuthenticationResult) => requestWrapper(authResult, (auth?) => easyClient.patch(url, data, config, auth)),
      getInstance: easyClient.getInstance,
      getScopes: easyClient.getScopes,
      setBaseUrl: easyClient.setBaseUrl,
      setAppId: easyClient.setAppId
    }
  }, [requestWrapper]);

  const confirmHandler = (pendingOperation: IPendingOperation) => {
    setShowSpinner(true);
    acquireTokenPopup(easyClient.getScopes())
      .then(authResult => pendingOperation.pendingOperation(authResult))
      .then(response => {
        pendingOperation.resolve(response)
      })
      .catch(error => {
        pendingOperation.reject(error)
      })
      .finally(() => {
        setShowSpinner(false);
        setPendingOperations(p => p.filter(o => o !== pendingOperation));
      });
  }

  let dialog = null;
  if (pendingOperations.length) {
    if (showSpinner) {
      dialog = <Spinner styles={{ root: { width: "50%", left: "25%", position: "absolute" } }} label="Attendere l'autorizzazione..." />
    } else {
      const pendingOperation = pendingOperations[0];
      dialog = (
        <AuthorizationDialog
          cancel={() => {
            pendingOperation.reject("cancelled by user");
            setPendingOperations(p => p.filter(o => o !== pendingOperation))
          }}
          authorize={() => confirmHandler(pendingOperation)} />
      );
    }
  }

  return (
    <>
      {dialog}
      <Component {...props as P} easyClient={easyClientWithInteractiveLoginInterceptor} />
    </>
  )

}

export default withInteractiveLogin;
