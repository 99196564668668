import { IDocumentCardStyles, IDocumentCardPreviewStyles, IDocumentCardDetailsStyles, ITextStyles, IDocumentCardTitleStyles, IDocumentCardActivityStyles, IButtonStyles, IContextualMenuStyles, IContextualMenuItemStyles, IStyle, IProcessedStyleSet, mergeStyleSets, ITheme } from "@fluentui/react";
import { desktop } from '../../../shared/mediaQuery';


interface IEasyDocumentCardClasses {
  iconButtonWrapper: IStyle,
}

export const classFactory: () => IProcessedStyleSet<IEasyDocumentCardClasses> = () => {

  return mergeStyleSets<IEasyDocumentCardClasses>({

    iconButtonWrapper: {
      padding: 0,
      //height: 40,
      position: "absolute",
      bottom: 1,
      right: 1,
      zIndex: 10
    },
  })
}

interface IEasyDocumentCardStyles {
  documentCard: IDocumentCardStyles,
  documentCardPreview: IDocumentCardPreviewStyles,
  documentCardDetails: IDocumentCardDetailsStyles,
  documentType: ITextStyles,
  fileSize: ITextStyles,
  documentCardTitle: IDocumentCardTitleStyles,
  //stackMenuItem: IStackItemStyles,
  //stackActivityItem: IStackItemStyles,
  //stackStatus: IStackStyles,
  documentCardActivity: IDocumentCardActivityStyles,
  //iconButtonMultiReadStatus: IButtonStyles,
  iconButtonContextMenu: IButtonStyles,
  recipientsContextMenu: IContextualMenuStyles,
  recipientsContextMenuItem: IContextualMenuItemStyles,
  cardContextMenuItem: IContextualMenuItemStyles,
}
export const getStyles = (theme: ITheme): IEasyDocumentCardStyles => {

  return {
    documentCard: {
      root: {
        backgroundColor: theme.palette.neutralLighterAlt,
        maxWidth: "100%",
        minWidth: "75%",
        minHeight: 110,
        maxHeight: 180,
        height: "auto",
        boxSizing: "border-box",
        selectors: {
          [desktop]: {
            boxShadow: theme.effects.elevation4,
          },
          "&.unread": {
            borderColor: theme.palette.themeSecondary,
          }
        }
      },
    },

    documentCardPreview: {
      root: {
        height: "100%",
        maxHeight: "100%!important",
        borderStyle: "none!important",
        backgroundColor: theme.palette.neutralSecondary,
        selectors: {
          ".unread &": {
            backgroundColor: theme.palette.themeSecondary,
          }
        }
      },
      previewIcon: {
        cursor: "pointer",
      }, fileList: {}, fileListIcon: {}, fileListLink: {}, fileListOverflowText: {}, icon: {}
    },

    documentCardDetails: {
      root: {
        padding: "6px 12px",
        selectors: {
          [desktop]: {
            padding: "8px 16px",
          }
        },
      },
    },

    documentType: {
      root: {
        //padding: "6px 16px 0 16px",
        fontSize: 12,
        padding: 0,
        fontWeight: 400,
        color: theme.palette.themePrimary,
      }
    },

    fileSize: {
      root: {
        //padding: "6px 16px 0 16px",
        fontSize: 11,
        padding: 0,
        fontWeight: 400,
        color: theme.palette.neutralPrimary,
      }
    },

    documentCardTitle: {
      root: {
        flexGrow: 1,
        maxHeight: "2.1em",
        padding: 0,
        //height: "unset",
        //fontWeight: 300,
        fontSize: "14px!important",
        lineHeight: "15px!important",
        selectors: {
          ".unread &": {
            fontWeight: 500,
          }
        }
      }
    },

    // stackMenuItem: {
    //   root: {
    //     alignItems: 'center',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     overflow: 'hidden',
    //     width: "fit-content",
    //     maxWidth: "2em",
    //   },
    // },

    // stackActivityItem: {
    //   root: {
    //     alignItems: 'left',
    //     display: 'flex',
    //     justifyContent: 'start',
    //     overflow: 'hidden',
    //   },
    // },

    // stackStatus: {
    //   root: {
    //     overflow: 'hidden',
    //     width: "100%",
    //   },
    // },

    documentCardActivity: {
      root: {
        paddingLeft: "2px",
        paddingBottom: "0px!important",
        paddingTop: "3px!important",
      },
      details: {
        left: 36,
        top: 3,
        width: "auto",
      },
      avatar: {
        selectors: {
          "& :global(.ms-Persona-initials)": {
            color: "#ffffff",
          }
        }
      },
      avatars: {},
      activity: {},
      name: {},
    },

    // iconButtonMultiReadStatus: {
    //   root: {
    //     width: "100%",
    //     position: "absolute",
    //     bottom: 0,
    //     left: 0,
    //     zIndex: 10,
    //   },
    // },
    iconButtonContextMenu: {
      root: {
        padding: "0 8px",
        height: 40,
        // position: "absolute",
        // bottom: 1,
        // right: 1,
        // zIndex: 10
      },
    },
    cardContextMenuItem: {
      anchorLink: {},
      checkmarkIcon: {},
      divider: {},
      icon: {
        // selectors: {
        //   ".unread &": { color: theme.palette.redDark },
        //   ".partiallyread &": { color: theme.palette.orange },
        //   ".fullyread &": { color: theme.palette.greenDark },
        // }
      },
      iconColor: {},
      item: {},
      label: {
        // selectors: {
        //   ".unread &": { color: theme.palette.redDark },
        //   ".partiallyread &": { color: theme.palette.orange },
        //   ".fullyread &": { color: theme.palette.greenDark },
        // }
      },
      linkContent: {},
      linkContentMenu: {},
      root: {
        //fontSize: 13,
      },
      screenReaderText: {},
      secondaryText: {
        fontSize: 11,
        selectors: {
          [desktop]: {
            fontSize: 12,
          }
        }
      },
      splitContainer: {},
      splitMenu: {},
      splitPrimary: {},
      subMenuIcon: {},
    },
    recipientsContextMenu: {
      container: {},
      header: {},
      list: {},
      root: { maxWidth: "99vw", },
      subComponentStyles: { callout: {}, menuItem: {} },
      title: {}
    },
    recipientsContextMenuItem: {
      anchorLink: {},
      checkmarkIcon: {},
      divider: {},
      icon: {},
      iconColor: {},
      item: {},
      label: {

      },
      linkContent: {},
      linkContentMenu: {},
      root: { fontSize: 13, },
      screenReaderText: {},
      secondaryText: {
        fontSize: 11,
        paddingLeft: 0,
        marginLeft: "0.6em",
        minWidth: "10em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        selectors: {
          [desktop]: {
            fontSize: 12,
          }
        }
      },
      splitContainer: {},
      splitMenu: {},
      splitPrimary: {},
      subMenuIcon: {},
    },
  }
}

export default getStyles;
