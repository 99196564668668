import { IPartialTheme } from "@fluentui/react";
import { ICompanyData } from "./context";

export const APP_SET_CONFIG = "APP_SET_CONFIG";
export const APP_SET_CONFIG_START = "APP_SET_CONFIG_START";
export const APP_SET_CONFIG_DONE = "APP_SET_CONFIG_DONE";
export const APP_SET_CONFIG_DEFAULT = "APP_SET_CONFIG_DEFAULT";

export interface SetConfigAction {
  type: typeof APP_SET_CONFIG;
}

export interface SetConfigStartAction {
  type: typeof APP_SET_CONFIG_START;
}

export interface SetConfigDoneAction {
  type: typeof APP_SET_CONFIG_DONE;
  easyConfig: IEasyConfig;
}

export interface SetConfigDefaultAction {
  type: typeof APP_SET_CONFIG_DEFAULT;
}

export type ConfigActions = SetConfigAction | SetConfigStartAction |
  SetConfigDoneAction | SetConfigDefaultAction;

export interface IEasyConfig {
  easyApiUrl?: string;
  auth?: IAuthConfig;
  themes?: IEasyTheme;
  company?: ICompanyData
}

export interface IAuthConfig{
  clientId?: string;
  authority?: string;
  redirectUri?: string;
}

export interface IEasyTheme{
light?: IPartialTheme;
dark?: IPartialTheme;
}
