import { ITheme } from '@fluentui/react';
import { IHttpClient } from '../../shared/easyAxios';
import { ContextActionTypes, CONTEXT_ERROR, CONTEXT_FETCH, CONTEXT_LOADED, CONTEXT_LOADING, GLOBAL_SET_SEARCH_FILTER, IContextAction, IContextErrorAction, IContextLoadedAction, IContextLoadingAction, ISetFullThemeAction, ISetSearchExpressionAction, ISetThemeAction, SET_THEME, ThemeName } from '../types/context';

export const contextFetch = (easyClient: IHttpClient): ContextActionTypes => {
  return {
    type: CONTEXT_FETCH,
    easyClient: easyClient
  }
}

export const contextLoading = (domain: string): IContextLoadingAction => {
  return {
    type: CONTEXT_LOADING,
    domain: domain
  }
}

export const contextLoaded = (contextData: IContextAction): IContextLoadedAction => {
  return {
    type: CONTEXT_LOADED,
    contextData: contextData
  }
}

export const contextError = (): IContextErrorAction => {
  return {
    type: CONTEXT_ERROR
  }
}

export const changeThemeAction = (themeName: ThemeName): ISetThemeAction => {
  return {
    type: SET_THEME,
    theme: themeName
  }
}

export const setSearchFilter = (newSearchFilter: string): ISetSearchExpressionAction => {
  return {
    type: GLOBAL_SET_SEARCH_FILTER,
    searchExpression: newSearchFilter
  };
};

export const setFullThemeAction = (theme: ITheme): ISetFullThemeAction => {
  return {
    type: "SET_FULL_THEME",
    theme: theme
  }
}
