import { IArrayValidationOptions, INumberValidationOption, IStringValidationOption, IValidationResult } from "../types/Form";


export const keepInRange = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value));
}

export const getTodayDate = (): Date => {
  return getDateWithoutTime(new Date())!;
}

export const getDateWithoutTime = (dateTime?: Date): Date | undefined => {
  if (!dateTime) return undefined;
  let date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
}

export const getLocaleDateString = (date?: Date): string => {
  const d = date && new Date(date);
  return d ? d.toLocaleDateString() : "";
}

export const getLocaleTimeString = (date?: Date): string => {
  const d = date && new Date(date);
  return d ? d.toLocaleTimeString() : "";
}

export const getLocaleDateTimeString = (date?: Date): string => {
  const d = date && new Date(date);
  return d
    ? `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
    : "";
}

export const getFileExtension = (path: string) => {
  // eslint-disable-next-line no-useless-escape
  var regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var extension = path.match(regexp);
  return (extension && extension[1]) || undefined;
}

export const getFileNameWithoutExtension = (itemUrl: string) => {
  const fileNameWithExtension = getFileNameFromUrl(itemUrl);

  const fileNameExtension = getFileExtension(fileNameWithExtension) || "";
  if (fileNameExtension.length === 0) {
    return fileNameWithExtension;
  }

  const pos = fileNameWithExtension.lastIndexOf("." + fileNameExtension);
  if (pos <= 0) {
    return fileNameWithExtension;
  }

  return fileNameWithExtension.substring(0, pos);
}

export const getFileNameFromUrl = (itemUrl: string) => {
  const urlTokens = itemUrl.split("?");
  const url = urlTokens[0];
  const tokens = url.split("/");
  const fileNameWithExtension = tokens[tokens.length - 1];

  return fileNameWithExtension;
}

export const getFileContentAndMimeType = (fileDataUrlBase64: string | null | undefined): { fileContent?: string, mimeType?: string } => {
  const _mimeType = ((fileDataUrlBase64 !== undefined && fileDataUrlBase64 !== null) ? fileDataUrlBase64.substring(fileDataUrlBase64.indexOf(":") + 1, fileDataUrlBase64.indexOf(";")) : undefined);
  const _fileContent = ((fileDataUrlBase64 !== undefined && fileDataUrlBase64 !== null) ? fileDataUrlBase64.substring(fileDataUrlBase64.lastIndexOf(',') + 1) : undefined);
  return { fileContent: _fileContent, mimeType: _mimeType };
}

export const onlyUnique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index;
}

export const get_uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export const updateObject = <T, K extends keyof T>(oldObject: T, updatedProperties: Pick<T, K>): T => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkStringValidity = (value: string | undefined, rules: IStringValidationOption): IValidationResult => {
  if (rules.required) {
    let valueToValidate = value ?? "";
    if (rules.isHtml) {
      // Modo 1
      // const re = /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm;
      // requiredRulePass =!re.test(value.trim());

      // Modo 2
      valueToValidate = valueToValidate.replace(/<(.|\n)*?>/gm, '').replace('&nbsp;', '');
    }
    if (valueToValidate.trim().length === 0) {
      return { valid: false, errorMessage: "Il campo è obbligatorio" }
    }
  }

  if (rules.isEmail && value) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const isEMail = pattern.test(value);
    if (!isEMail) return { valid: false, errorMessage: 'Inserire una mail valida' };
  }

  if (rules.minLength && value) {
    const minLengthIsConfirmed = value.length >= rules.minLength;
    if (!minLengthIsConfirmed) return { valid: false, errorMessage: `Lunghezza minima: ${rules.minLength} caratteri` };
  }

  if (rules.maxLength && value) {
    const maxLengthIsConfirmed = value.length <= rules.maxLength;
    if (!maxLengthIsConfirmed) return { valid: false, errorMessage: `Lunghezza massima: ${rules.maxLength} caratteri` };
  }

  return { valid: true };
}

export const checkNumberValidity = (value: string | undefined, rules: INumberValidationOption): IValidationResult => {

  const number = defaultNumberParser.parse(value ?? "");
  if (rules.required && (!value || isNaN(number))) {
    return { valid: false, errorMessage: "Il campo è obbligatorio" }
  }
  if (!isNaN(number) && rules.min !== undefined && number < rules.min) {
    return { valid: false, errorMessage: `Il campo deve essere più grande di ${number.toLocaleString()}` }
  }
  if (!isNaN(number) && rules.max !== undefined && number > rules.max) {
    return { valid: false, errorMessage: `Il campo deve essere più piccolo di ${number.toLocaleString()}` }
  }

  return { valid: true }
}

export const checkObjectValidity = (value: object | undefined, required: boolean): IValidationResult => {
  if (required && !value) {
    return { valid: false, errorMessage: "Il campo è obbligatorio" }
  }
  return { valid: true }
}

export const checkArrayValidity = (value: Array<any>, rules: IArrayValidationOptions): IValidationResult => {
  if (rules.required && !value.length) {
    return { valid: false, errorMessage: "Il campo è obbligatorio" }
  }
  if (rules.minItems !== undefined && value.length < rules.minItems) {
    return { valid: false, errorMessage: `Numero elementi non valido, minimo ${rules.minItems}` }
  }
  if (rules.maxItems !== undefined && value.length > rules.maxItems) {
    return { valid: false, errorMessage: `Numero elementi non valido, massimo ${rules.maxItems}` }
  }
  return { valid: true }
}

export const checkValidity: (value: any, rules: any) => { isValid: boolean, errorMessage?: string } = (value, rules) => {
  const valueType = typeof value;
  if (rules.required) {
    let requiredRulePass: boolean = true;
    if (valueType === "number") {
      requiredRulePass = true;
    } else if (Array.isArray(value)) {
      requiredRulePass = value.length > 0;
    }
    if (!requiredRulePass) return { isValid: false, errorMessage: "Il campo è obbligatorio" };
  }

  return { isValid: true, errorMessage: "" };
}

// https://observablehq.com/@mbostock/localized-number-parsing
class NumberParser {
  private _group: RegExp;
  private _decimal: RegExp;
  private _numeral: RegExp;
  private _index: (d: any) => string;
  constructor(locale?: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = Array.from(new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)).reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find(d => d.type === "group")?.value}]`, "g");
    this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal")?.value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d)?.toString() ?? "";
  }
  parse(string: string) {
    const settingFixed = string.trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index);
    return settingFixed ? +settingFixed : NaN;
    // warning non si aspetta una assegnazione
    // return (string = string.trim()
    //   .replace(this._group, "")
    //   .replace(this._decimal, ".")
    //   .replace(this._numeral, this._index)) ? +string : NaN;
  }
}
export const defaultNumberParser = new NumberParser();
