import { Icon, ITheme, Link, mergeStyleSets, Spinner, Stack } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NoData from '../../../components/NoData/NoData';
import { IODataList } from '../../../models/odata';
import { IHttpClient } from '../../../shared/easyAxios';
import { INewsArticle } from '../News.types';
import * as newsArchiveRoute from '../NewsArchive/NewsArchive.route';
import NewsBoardItem from './NewsBoardItem';

const DEFAULT_MAXARTICLESTOSHOW = 5;

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    NewsItems: {
      width: "100%",
      boxSizing: "border-box",
      padding: "0.2rem 0.2rem 2.5rem 0.2",
    },
    // NewsItem: {
    //   boxSizing: "border-box",
    //   border: "1px solid " + theme.semanticColors.bodyText,
    //   padding: "0.5rem",
    //   margin: "0.3rem 0",
    //   textAlign: "center",
    //   width: "100%",
    //   selectors: {
    //     [desktop]: {
    //       width: "12.5rem",
    //       margin: "0.3rem",
    //       flexGrow: 1,
    //     }
    //   }
    // },
    Title: {
      fontFamily: "Segoe UI Light"
    }
  });
}

// interface INewsBoardReduxProps {
//   theme: ITheme;
// }

// const mapStateToProp = (state: IEasyStore): INewsBoardReduxProps => {
//   return {
//     theme: getCurrentTheme(state)
//   }
// }

// const connector = connect(mapStateToProp);

export interface INewsBoardProps {
  easyClient: IHttpClient;
  maxArticlesToShow?: number;
  theme: ITheme;
}

const NewsBoard: React.FC<INewsBoardProps> = ({ theme, easyClient, maxArticlesToShow }) => {
  let history = useHistory();
  const classNames = getClassNames(theme);

  const [articles, setArticles] = useState<INewsArticle[]>([]);
  const [itemsAreLoading, setItemsAreLoading] = useState<boolean>(true);

  const howMany = useMemo(() => {
    return Math.min(20, Math.max(1, maxArticlesToShow || DEFAULT_MAXARTICLESTOSHOW))
  }, [maxArticlesToShow]);

  const articleClickHandler = useCallback(
    (id: string) => {
      history.push(newsArchiveRoute.path.replace(":articleId?", id));
    },
    [history],
  )


  useEffect(() => {
    setItemsAreLoading(true);

    let url = `/api/Articles?getThumbImage=true&$top=${howMany}&$filter=${encodeURIComponent('visibleOnBoard eq true')}&$orderby=${encodeURIComponent('priorityOnBoard desc,articleDate desc')}`;

    easyClient.get<IODataList<INewsArticle>>(url, { headers: { "X-version": "1.0" } })
      .then(response => {
        setArticles(response.data.value);
      })
      .catch(err => {
        console.log(err);
        setArticles([]);
      })
      .finally(() => {
        setItemsAreLoading(false);
      });
  }, [easyClient, howMany]);

  const hasArticles = (articles?.length || 0) > 0;

  return (
    <section className={classNames.NewsItems}>
      <h2 className={classNames.Title}>Notizie per la rete</h2>
      { itemsAreLoading && <Spinner />}
      { !hasArticles && !itemsAreLoading && <NoData iconName="NewsSearch" title="Non ci sono notizie pubblicate in bacheca" />}
      {hasArticles && !itemsAreLoading && (
        <Stack tokens={{ childrenGap: 10 }}>
          {articles.map(a => {
            return (
              <Stack.Item key={`si_${a.id}`}>
                <NewsBoardItem key={a.id} theme={theme} article={a} onArticleClick={articleClickHandler} />
              </Stack.Item>
            )
          })}
        </Stack>
      )}
      {!itemsAreLoading && <Link key="linkNewsArchive" styles={{ root: { marginTop: "0.5em" } }} onClick={() => { history.push(newsArchiveRoute.basePath) }} >Vai all'archivio <Icon iconName="Forward" styles={{ root: { fontSize: 10 } }} /></Link>}
    </section>);
}

export default NewsBoard;


