import { IStyle, IProcessedStyleSet, mergeStyleSets } from "@fluentui/react";
import { desktop } from "../../shared/mediaQuery";

interface IHomeStyle {
  centerLayout: IStyle;
  centerItem: IStyle;
}

const styleFactory: () => IProcessedStyleSet<IHomeStyle> = () => {
  return mergeStyleSets<IHomeStyle>({
    centerLayout: {
      width: "100%",
      maxWidth: "960px",
      margin: "0 auto",
      padding: "0 0.4rem",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      selectors: {
        [desktop]: {
          flexDirection: "row",
        }
      }
    },
    centerItem: {
      selectors: {
        [desktop]: {
          width: "48%",
          margin: "0 auto",
        }
      }
    }
  });
}

export default styleFactory;
