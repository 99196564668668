import React from 'react';

import { IStyle, mergeStyleSets, DefaultEffects, AnimationStyles, Text } from '@fluentui/react';

const styles = mergeStyleSets({
  "Section": {
    padding: "1.5rem",
    boxShadow: DefaultEffects.elevation4,
    ...AnimationStyles.slideLeftIn10,
  } as IStyle,
  "SectionHeader": {
    marginBottom: "1.3rem"
  } as IStyle,
  "SectionContent": {

  } as IStyle
})

interface IEasySectionProp {
  title: string;
}

const EasySection: React.FC<IEasySectionProp> = (props) => {
  return (
    <div className={styles.Section}>
      <div className={styles.SectionHeader}><Text block variant="xLargePlus">{props.title}</Text></div>
      <div>{props.children}</div>
    </div>
  );
}

export default EasySection;
