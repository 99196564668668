import React from 'react';
import { Stack, DefaultButton, ITheme } from '@fluentui/react';

import { ISpecieCardProps, ProvisionCard } from '../Card/Card';

export interface IListSpecieProps {
    items: Array<ISpecieCardProps & { key: string }>;
    onSpecieSelected: (items: ISpecieCardProps & { key: string }) => void;
    theme: ITheme;
}

const ListSpecie: React.FC<IListSpecieProps> = (props) => {
    return (
        <Stack horizontal wrap tokens={{ childrenGap: "2rem" }} styles={{ inner: { margin: 0 } }}>
            {props.items.map(entry =>
                <Stack.Item key={entry.key} grow>
                    <ProvisionCard theme={props.theme} title={entry.title} delivered={entry.delivered} sampled={entry.sampled} >
                        <DefaultButton text="Varietà" onClick={() => props.onSpecieSelected(entry)} />
                    </ProvisionCard>
                </Stack.Item>)}
        </Stack>
    );
}

export default ListSpecie;
