import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import { deliveryNoticeRoute } from './containers/DeliveryNotice/route';
import { documentRoute } from './containers/Document/route';
import { newsArchiveRoute } from './containers/News/NewsArchive/NewsArchive.route';
import Menu from './containers/Menu/Menu';
import { provisionReportRoute } from './shared/route';
import { connect } from 'react-redux';
import { IEasyStore } from './store/store';
import * as settings from './containers/Settings/route';
import * as areas from './containers/Area/route';
import * as documentTypes from './containers/DocumentType/DocumentType.route';
import * as documentGroup from './containers/DocumentGroup/DocumentGroup.route';
import * as user from './containers/User/route';
import * as users from './containers/User/Users.route';
import { canViewDeliveryNotice, canViewProvisionReport } from './store/reducers/context.functions';
import { UserRole } from './store/types/context';

const mapStateToProp = (store: IEasyStore) => {
  return {
    roles: store.context.roles,
    modules: store.context.modules,
    partners: store.context.partners
  };
}

const connector = connect(mapStateToProp);

interface IAppProps {
  roles: UserRole[];
  modules: string[];
  partners: string[];
}

const App: React.FC<IAppProps> = (props) => {
  const roles = props.roles;
  let routes = (
    <Switch>
      {canViewProvisionReport(props.modules, props.partners, props.roles) && provisionReportRoute.map(r => <Route key={r.key} path={r.path} component={r.component} />)}
      {settings.roles.some(r => roles.includes(r)) && <Route path={settings.path} component={settings.Component} />}
      {areas.roles.some(r => roles.includes(r)) && <Route path={areas.path} component={areas.Component} />}
      {documentTypes.roles.some(r => roles.includes(r)) && <Route path={documentTypes.path} component={documentTypes.Component} />}
      {(canViewProvisionReport(props.modules, props.partners, props.roles) 
      || canViewDeliveryNotice(props.modules, props.partners, props.roles))
      && user.userPartnersRoles.some(r => roles.includes(r)) 
      && <Route path={user.userPartnersPath} component={user.userPartnersComponent} />}
      {canViewDeliveryNotice(props.modules, props.partners, props.roles) && <Route path={deliveryNoticeRoute.path} component={deliveryNoticeRoute.component} />}
      {documentGroup.roles.some(r => roles.includes(r)) && <Route path={documentGroup.path} component={documentGroup.Component} />}
      {users.roles.some(r => roles.includes(r)) && <Route path={users.path} component={users.component} />}
      <Route path={newsArchiveRoute.path} component={newsArchiveRoute.component} />
      <Route path={documentRoute.path} component={documentRoute.component} />
      <Route path="/area/:id" component={Menu} />
      <Route exact path="/" component={Home} />
      <Redirect to="/" />
    </Switch>
  );
  //<Route path="/delivery-notice/:deliveryNoticeId?" render={(props) => <DeliveryNotice deliveryNoticeId={...props.match.params.deliveryNoticeId} />} />
  return (
    <Layout>
      <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
    </Layout>
  );
  // return <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
}

export default connector(App);
