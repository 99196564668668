import React, { useCallback, useMemo } from 'react';
import { Image, FocusZone, FocusZoneDirection, getFocusStyle, Icon, List, mergeStyleSets, ImageFit, ITheme } from '@fluentui/react';
import { INewsArticle } from '../News.types';
import { desktop } from '../../../shared/mediaQuery';
import { connect, ConnectedProps } from 'react-redux';
import { IEasyStore } from '../../../store/store';


const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        cursor: "pointer",
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: theme.palette.neutralLight },
        },
      },
    ],
    itemImage: {
      border: "1px solid " + theme.palette.neutralLighter,
      flexShrink: 0,
      width: 75,
      minHeight: 75,
      position: "relative",
      [desktop]: {
        width: 100,
        minHeight: 100,
      }
    },
    itemSummary: {
      maxHeight: "4.9em",
      overflow: "hidden",
      textOverflow: "ellipses",
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    itemName: [
      theme.fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: "130%",
      },
    ],
    itemIndex: {
      fontSize: theme.fonts.small.fontSize,
      color: theme.palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: 'center',
      marginLeft: 10,
      color: theme.palette.neutralTertiary,
      fontSize: theme.fonts.large.fontSize,
      flexShrink: 0,
    },
  });
}

function isoDateToLocalDateString(s: string): string {
  if (s) {
    return new Date(s).toLocaleDateString();
  }
  return "";
}
export interface INewsList {
  articles: INewsArticle[],
  onArticleSelected: (articleId?: string) => void,
}


const mapStateToProps = (state: IEasyStore) => {
  return {
    theme: state.context.fullTheme
  }
}

const connector = connect(mapStateToProps);

type PropsFromStore = ConnectedProps<typeof connector>;

const NewsList: React.FC<INewsList & PropsFromStore> = (props) => {
  const theme = props.theme;
  const classNames = useMemo(() => getClassNames(theme), [theme]);

  const onRenderCell = useCallback((item?: INewsArticle, index?: number): JSX.Element => {
    if (!item) return <></>;

    const hasImage = (item.imageFileContent?.length || 0) > 0;

    return (
      <div className={classNames.itemCell} data-is-focusable={true} onClick={() => { props.onArticleSelected && props.onArticleSelected(item.id) }}>
        <div className={classNames.itemImage}>
          {hasImage
            ? < Image src={item.imageFileContent} width="100%" height="100%" imageFit={ImageFit.centerCover} />
            : <Icon iconName="News" styles={{ root: { fontSize: 64, position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" } }} />}
        </div>
        <div className={classNames.itemContent}>
          <div className={classNames.itemName}>{item.title}</div>
          <div className={classNames.itemIndex}>{isoDateToLocalDateString(item.articleDate)}</div>
          <div className={classNames.itemSummary}>{item.summary}</div>
        </div>
        <Icon
          className={classNames.chevron}
          iconName="ChevronRight"
        />
      </div>
    );
  }, [classNames.chevron, classNames.itemCell, classNames.itemContent, classNames.itemImage, classNames.itemIndex, classNames.itemName, classNames.itemSummary, props]);


  return (
    <FocusZone direction={FocusZoneDirection.vertical}>
      <List items={props.articles} onRenderCell={onRenderCell} />
    </FocusZone>
  );
};

export default connector(NewsList);
