import React from 'react';
import { IBasePickerSuggestionsProps, ITag, Stack, TagPicker, Text } from '@fluentui/react';
import { IODataList } from '../../models/odata';
import { IHttpClient } from '../../shared/easyAxios';

export interface IDocumentPickerProps {
  easyClient: IHttpClient;
  value: ITag[];
  valueChange: (newTag: IDocumentTypeTag[]) => void;
  maxItemsSelectable?: number;
}

export interface IDocumentType {
  id: string;
  description: string;
  areaName: string;
}

export interface IDocumentTypeTag extends ITag {
  areaName: string;
  description: string;
}

const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: 'Nessuna Tipo documento',
};

const DocumentTypePicker: React.FC<IDocumentPickerProps> = (props) => {

  const easyClient = props.easyClient;
  const maxItemsSelectable = props.maxItemsSelectable;
  const selectedTagChangeHandler = props.valueChange;
  const selectedItems = props.value;

  const filterSelectedTags = (filterText?: string): PromiseLike<ITag[]> => {
    let filter = "";
    if (selectedItems.length) {
      const ids = selectedItems.map(x => "'" + encodeURIComponent(x.key) + "'");
      filter = filter + "not(id in(" + ids.join(",") + "))";
    }
    return easyClient.get<IODataList<IDocumentType>>(`/api/DocumentTypes?${filter ? "$filter=" + filter + "&" : ""}$top=100&$select=id,description,areaName&$orderby=id&search=${filterText}`, { headers: { "X-version": "1.0" } }).then(response => {
      const newOptions = response.data.value.map<IDocumentTypeTag>(docType => ({
        key: docType.id,
        areaName: docType.areaName,
        name: docType.id,
        description: docType.description
      }));
      return newOptions
    }).catch(err => {
      console.error(err);
      return []
    });
  };

  const emptyResolveSuggestionHandler = () => {
    return filterSelectedTags("");
  }

  const renderSuggestionItemHandler = (tag: ITag) => {
    const docTypeTag = tag as IDocumentTypeTag;
    const text = [docTypeTag.description];
    if (docTypeTag.areaName) {
      text.push(docTypeTag.areaName);
    }else {
      text.push("partner")
    }
    return (<Stack styles={{ root: { textAlign: "left" } }}>
      <Stack.Item><Text variant="small">{docTypeTag.name}</Text></Stack.Item>
      <Stack.Item><Text variant="xSmall">{text.join(" - ")}</Text></Stack.Item>
    </Stack>)
  }

  const getTextFromItem = (item: ITag) => item.name;

  const changeHandler = (items?: ITag[]) => {
    if (!items)
      return;
    selectedTagChangeHandler(items as IDocumentTypeTag[]);
  }

  return (
    <div>
      <TagPicker
        onEmptyResolveSuggestions={emptyResolveSuggestionHandler}
        resolveDelay={300}
        onResolveSuggestions={filterSelectedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={maxItemsSelectable}
        selectedItems={selectedItems}
        onChange={changeHandler}
        onRenderSuggestionsItem={renderSuggestionItemHandler}
      />
    </div>
  )
}

export default DocumentTypePicker;
